import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  IoPlayCircle,
  IoPauseCircle,
  IoPlaySkipBack,
  IoPlaySkipForward,
  IoVolumeHigh,
  IoVolumeOff,
  IoClose,
  IoList,
  IoExpand,
  IoContract
} from 'react-icons/io5';
import { initializeAnalyzerNode, createRobustAnalyzer } from './GlobalAudioStateIntegration';

// Import safely with fallback
let VisualizerLoader;
try {
  VisualizerLoader = require('../audio/VisualizerLoader').default;
} catch (err) {
  console.warn('Failed to load VisualizerLoader:', err);
}

const FullScreenVisualizer = ({
  audioElement,
  isPlaying,
  onPlayPause = () => {},
  onToggleFullScreen = () => {},
  colorPrimary = '#7d12ff',
  colorSecondary = '#5636f3',
  backgroundColor = '#02073c',
  sensitivity = 1.2,
  externalAnalyzer = null,
  externalAudioContext = null,
  currentTime = 0,
  duration = 0,
  musicLibrary = [],
  selectedMusic = "",
  onMusicSelect = () => {},
  onSkip = () => {},
  onSeek = () => {},
  allowPlaylistAccess = true,
  onVolumeChange = null,
  onMuteToggle = null
}) => {
  // State
  const [showControls, setShowControls] = useState(true);
  const [audioContext, setAudioContext] = useState(externalAudioContext);
  const [analyzerNode, setAnalyzerNode] = useState(externalAnalyzer);
  const [audioConnected, setAudioConnected] = useState(!!externalAnalyzer);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [waveformData, setWaveformData] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [orientation, setOrientation] = useState('portrait');
  const [visualizerSize, setVisualizerSize] = useState('70vh');
  const [visualizerKey, setVisualizerKey] = useState(Date.now());
  const [lastAnalyzerInitAttempt, setLastAnalyzerInitAttempt] = useState(0);

  // Refs
  const controlsTimeoutRef = useRef(null);
  const sourceNodeRef = useRef(null);
  const mountedRef = useRef(true);
  const setupAttemptedRef = useRef(false);
  const waveformContainerRef = useRef(null);
  const animationFrameRef = useRef(null);
  const visualizerWrapperRef = useRef(null);
  const analyzerRef = useRef(externalAnalyzer);

  // Determine if we're on mobile
  const isMobile = dimensions.width <= 768;
  const isPortrait = orientation === 'portrait';

  // Effect: Measure container and set dimensions
  useEffect(() => {
    const updateDimensions = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });

      // Detect orientation
      setOrientation(window.innerWidth > window.innerHeight ? 'landscape' : 'portrait');

      // Calculate visualizer size based on screen dimensions
      let size;
      if (window.innerWidth <= 768) {
        // Mobile
        if (window.innerWidth > window.innerHeight) {
          // Landscape: use height as constraint
          size = `${Math.min(70, (window.innerHeight * 0.7))}px`;
        } else {
          // Portrait: use width as constraint
          size = `${Math.min(70, (window.innerWidth * 0.7))}vw`;
        }
      } else {
        // Desktop: use either but cap at reasonable size
        const minDimension = Math.min(window.innerWidth, window.innerHeight);
        size = `${Math.min(70, (minDimension * 0.7))}px`;
      }
      setVisualizerSize(size);

      // Force re-render of visualizer to accommodate new size
      setVisualizerKey(Date.now());
    };

    // Initial update
    updateDimensions();

    // Add event listeners
    window.addEventListener('resize', updateDimensions);
    window.addEventListener('orientationchange', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions);
      window.removeEventListener('orientationchange', updateDimensions);
    };
  }, []);

  // The enhanced audio analyzer initialization
  const initializeAudioAnalyzer = useCallback(() => {
    if (!audioElement || !mountedRef.current) {
      return;
    }

    // If already connected with a valid analyzer, don't recreate
    if (audioConnected && analyzerNode && typeof analyzerNode.getByteFrequencyData === 'function') {
      return;
    }

    // If we have external analyzer, use it
    if (externalAnalyzer && externalAudioContext) {
      setAudioContext(externalAudioContext);
      setAnalyzerNode(externalAnalyzer);
      analyzerRef.current = externalAnalyzer;
      setAudioConnected(true);
      setupAttemptedRef.current = true;
      return;
    }

    // Mark as attempted
    setupAttemptedRef.current = true;
    setLastAnalyzerInitAttempt(Date.now());

    // Use the new enhanced analyzer initialization
    initializeAnalyzerNode({
      analyzerRef: analyzerRef,
      setAnalyzerNode: (node) => {
        setAnalyzerNode(node);
        // Store in global references if possible
        if (window.globalAudioState) {
          window.globalAudioState._analyzer = node;
        }
      },
      setAudioConnected: setAudioConnected,
      audioElement: audioElement,
      onInitialized: (analyzer) => {
        console.log("FullScreenVisualizer: Analyzer initialized successfully");

        // Also ensure we have an audio context reference
        if (analyzer && analyzer.context) {
          setAudioContext(analyzer.context);

          // Store in global state if available
          if (window.globalAudioState) {
            window.globalAudioState._audioContext = analyzer.context;
          }

          // Ensure context is running
          if (analyzer.context.state === 'suspended') {
            analyzer.context.resume().catch(err => {
              console.warn("FullScreenVisualizer: Error resuming audio context:", err);
            });
          }
        }
      }
    });
  }, [audioElement, externalAnalyzer, externalAudioContext, audioConnected, analyzerNode]);

  // Set up audio analysis if no external analyzer is provided
  useEffect(() => {
    mountedRef.current = true;
    if (!audioElement || setupAttemptedRef.current) return;

    initializeAudioAnalyzer();

    return () => {
      // Cleanup
      mountedRef.current = false;
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [audioElement, initializeAudioAnalyzer]);

  // Try to reinitialize analyzer if needed
  useEffect(() => {
    if (!audioConnected && audioElement && lastAnalyzerInitAttempt > 0 && 
        Date.now() - lastAnalyzerInitAttempt > 3000) {
      // Retry after delay if previous attempt failed
      console.log("FullScreenVisualizer: Retrying analyzer initialization");
      initializeAudioAnalyzer();
    }
  }, [audioConnected, audioElement, initializeAudioAnalyzer, lastAnalyzerInitAttempt]);

  // Format time display
  const formatTime = useCallback((timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) return '0:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);

  // Handle volume changes
  const handleVolumeChange = useCallback((newVolume) => {
    setVolume(newVolume);

    if (audioElement) {
      audioElement.volume = newVolume;
    }

    if (onVolumeChange) {
      onVolumeChange(newVolume);
    }
  }, [audioElement, onVolumeChange]);

  // Toggle mute state
  const toggleMute = useCallback(() => {
    const newMuteState = !isMuted;
    setIsMuted(newMuteState);

    if (audioElement) {
      audioElement.muted = newMuteState;
    }

    if (onMuteToggle) {
      onMuteToggle(newMuteState);
    }
  }, [isMuted, audioElement, onMuteToggle]);

  // Handle seek position changes
  const handleSeek = useCallback((e) => {
    if (!audioElement || !duration) return;

    const progressBar = e.currentTarget;
    const rect = progressBar.getBoundingClientRect();
    const offsetX = e.clientX - rect.left;
    const newTime = (offsetX / rect.width) * duration;

    if (onSeek) {
      onSeek(newTime);
    } else if (audioElement) {
      audioElement.currentTime = newTime;
    }
  }, [audioElement, duration, onSeek]);

  // Show/hide controls on mouse movement
  useEffect(() => {
    const showControlsOnMove = () => {
      setShowControls(true);

      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }

      controlsTimeoutRef.current = setTimeout(() => {
        if (mountedRef.current) {
          setShowControls(false);
        }
      }, 3000);
    };

    // Add event listeners
    window.addEventListener('mousemove', showControlsOnMove);
    window.addEventListener('touchmove', showControlsOnMove);
    window.addEventListener('click', showControlsOnMove);
    window.addEventListener('touchstart', showControlsOnMove);

    // Initial show
    showControlsOnMove();

    return () => {
      // Clean up event listeners
      window.removeEventListener('mousemove', showControlsOnMove);
      window.removeEventListener('touchmove', showControlsOnMove);
      window.removeEventListener('click', showControlsOnMove);
      window.removeEventListener('touchstart', showControlsOnMove);

      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }
    };
  }, []);

  // Generate waveform data from analyzer
  useEffect(() => {
    if (!analyzerNode || !isPlaying) return;

    const generateWaveformData = () => {
      if (!mountedRef.current || !analyzerNode) return;

      // Get data from analyzer
      try {
        const bufferLength = analyzerNode.frequencyBinCount;

        // Create data array if missing
        if (!analyzerNode.timeDataArray) {
          analyzerNode.timeDataArray = new Uint8Array(bufferLength);
        }

        const dataArray = analyzerNode.timeDataArray;

        // Get time domain data (waveform)
        analyzerNode.getByteTimeDomainData(dataArray);

        // Simplify data for rendering (100 points is enough)
        const simplifiedData = [];
        const step = Math.floor(bufferLength / 100);

        for (let i = 0; i < 100; i++) {
          // Convert from 0-255 to -1 to 1
          const index = Math.min(i * step, bufferLength - 1);
          simplifiedData.push(dataArray[index] / 128.0 - 1);
        }

        setWaveformData(simplifiedData);
      } catch (err) {
        // Silently handle errors - not critical
        console.warn("Error getting analyzer data:", err);
      }

      // Queue next frame
      animationFrameRef.current = requestAnimationFrame(generateWaveformData);
    };

    // Start animation loop
    generateWaveformData();

    // Clean up animation frame on unmount or when playing stops
    return () => {
      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, [analyzerNode, isPlaying]);

  // Clean up on unmount
  useEffect(() => {
    return () => {
      mountedRef.current = false;

      if (controlsTimeoutRef.current) {
        clearTimeout(controlsTimeoutRef.current);
      }

      if (animationFrameRef.current) {
        cancelAnimationFrame(animationFrameRef.current);
      }
    };
  }, []);

  // Album art helper
  const getAlbumArt = useCallback((trackName = selectedMusic) => {
    const coverArtOptions = [
      'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=600&h=600&fit=crop&auto=format'
    ];

    if (!trackName) return coverArtOptions[0];

    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;

      for (let i = 0; i < cleanName.length; i++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(i);
        hash |= 0;
      }

      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, [selectedMusic]);

  // Get track information
  const getTrackInfo = useCallback(() => {
    try {
      if (!selectedMusic || !Array.isArray(musicLibrary) || musicLibrary.length === 0) {
        return { title: 'Your Meditation', artist: 'Pause' };
      }

      const track = musicLibrary.find(t => t && typeof t === 'object' && t.name === selectedMusic);

      if (track) {
        return {
          title: track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          artist: 'Pause Music'
        };
      } else {
        return {
          title: selectedMusic ? selectedMusic.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Your Meditation',
          artist: 'Pause'
        };
      }
    } catch (err) {
      console.error("Error in getTrackInfo:", err);
      return { title: 'Meditation', artist: 'Pause' };
    }
  }, [selectedMusic, musicLibrary]);

  // Get mood label for tracks
  const getMoodLabel = useCallback((trackName = selectedMusic) => {
    if (!trackName) return 'Meditation Music';

    try {
      const cleanTrackName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      const lowerName = cleanTrackName.toLowerCase();

      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];

      for (const mood of moodMap) {
        if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
          return mood.label;
        }
      }

      return cleanTrackName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, [selectedMusic]);

  // Decide visualizer size based on screen orientation and size
  const visualizerStyles = {
    width: isMobile ? (isPortrait ? '80vw' : '70vh') : '70vh',
    height: isMobile ? (isPortrait ? '80vw' : '70vh') : '70vh',
    maxWidth: isMobile ? (isPortrait ? '80vw' : '70vh') : '70vw',
    maxHeight: isMobile ? (isPortrait ? '80vw' : '70vh') : '70vh',
  };

  // Render dynamic import for ThirdEyeVisualizer using the improved VisualizerLoader
  const renderVisualizer = () => {
    if (!VisualizerLoader) {
      // Fallback if loader not available
      return (
        <div
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            background: `radial-gradient(circle, ${colorPrimary} 0%, ${colorSecondary} 70%, ${backgroundColor} 100%)`,
            opacity: isPlaying ? 0.8 : 0.5,
            transition: 'all 0.3s ease',
            animation: isPlaying ? 'pulse 3s infinite alternate' : 'none'
          }}
        />
      );
    }

    return (
      <VisualizerLoader
        analyzerNode={analyzerNode || analyzerRef.current}
        isPlaying={isPlaying}
        primaryColor={colorPrimary}
        secondaryColor={colorSecondary}
        tertiaryColor={`${colorPrimary}aa`}
        sensitivity={sensitivity}
        voiceFocus={true}
        id="fullscreen-viz"
        key={visualizerKey}
      />
    );
  };

  return (
    <div
      className="fullscreen-visualizer"
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: backgroundColor,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'hidden'
      }}
    >
      <div
        className="visualizer-area"
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'relative'
        }}
      >
        <div
          className="track-info"
          style={{
            position: 'absolute',
            top: '20px',
            left: 0,
            width: '100%',
            padding: '0 20px',
            textAlign: 'center',
            color: 'white',
            opacity: showControls ? 1 : 0,
            transition: 'opacity 0.3s ease',
            zIndex: 10
          }}
        >
          <h3 style={{ 
            fontSize: isMobile ? '16px' : '18px', 
            margin: '0 0 5px 0', 
            textShadow: '0 2px 4px rgba(0,0,0,0.5)'
          }}>{getTrackInfo().title}</h3>
          <p style={{ 
            fontSize: isMobile ? '12px' : '14px', 
            margin: 0, 
            opacity: 0.8,
            textShadow: '0 2px 4px rgba(0,0,0,0.5)'
          }}>{getTrackInfo().artist}</p>
        </div>
        
        <div
          className="visualizer-wrapper"
          ref={visualizerWrapperRef}
          style={{
            ...visualizerStyles,
            position: 'relative',
            borderRadius: '50%',
            backgroundColor: backgroundColor,
            boxShadow: `0 0 100px ${colorPrimary}33`,
            overflow: 'hidden'
          }}
        >
          {audioConnected && analyzerNode ? (
            renderVisualizer()
          ) : (
            <div
              style={{
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                background: `radial-gradient(circle, ${colorPrimary} 0%, ${colorSecondary} 70%, ${backgroundColor} 100%)`,
                opacity: isPlaying ? 0.8 : 0.5,
                transition: 'all 0.3s ease',
                animation: isPlaying ? 'pulse 3s infinite alternate' : 'none'
              }}
            />
          )}
        </div>
      </div>
      
      <div
        className="visualizer-controls"
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          width: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          transition: 'opacity 0.3s ease, transform 0.3s ease',
          opacity: showControls ? 1 : 0,
          transform: showControls ? 'translateY(0)' : 'translateY(100%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          padding: isMobile ? '10px' : '20px',
          zIndex: 20
        }}
      >
        <div
          className="waveform-container"
          ref={waveformContainerRef}
          style={{
            width: '90%',
            maxWidth: '800px',
            height: isMobile ? '40px' : '60px',
            backgroundColor: 'rgba(0, 0, 0, 0.3)',
            borderRadius: '12px',
            position: 'relative',
            marginBottom: '10px',
            overflow: 'hidden',
            cursor: 'pointer',
            touchAction: 'none'
          }}
          onClick={handleSeek}
        >
          <div
            className="progress-bar"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: duration ? `${(currentTime / duration) * 100}%` : '0%',
              backgroundColor: 'rgba(125, 18, 255, 0.3)',
              zIndex: 1
            }}
          />
          <div
            className="waveform"
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              zIndex: 2
            }}
          >
            {waveformData.length > 0 ? (
              waveformData.map((value, index) => (
                <div
                  key={index}
                  style={{
                    flex: 1,
                    height: `${Math.abs(value) * 60}%`,
                    backgroundColor:
                      (index / waveformData.length) * 100 < (currentTime / duration) * 100
                        ? colorPrimary
                        : 'rgba(255, 255, 255, 0.3)',
                    marginLeft: '1px',
                    transform: value < 0 ? 'scaleY(-1)' : 'none',
                    transformOrigin: 'center'
                  }}
                />
              ))
            ) : (
              // Fallback static bars if no waveform data
              <div style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                alignItems: 'center',
                padding: '0 10px'
              }}>
                {Array.from({ length: 50 }).map((_, i) => (
                  <div
                    key={i}
                    style={{
                      flex: 1,
                      height: `${20 + Math.sin(i * 0.2) * 15}%`,
                      backgroundColor: (i / 50) * 100 < (currentTime / duration) * 100
                        ? colorPrimary
                        : 'rgba(255, 255, 255, 0.3)',
                      marginLeft: '1px'
                    }}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        
        <div
          className="time-display"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '90%',
            maxWidth: '800px',
            color: 'rgba(255, 255, 255, 0.8)',
            fontSize: isMobile ? '12px' : '14px',
            marginBottom: '10px'
          }}
        >
          <span>{formatTime(currentTime)}</span>
          <span>{formatTime(duration)}</span>
        </div>
        
        <div
          className="playback-controls"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: isMobile ? '15px' : '25px',
            marginBottom: isMobile ? '10px' : '20px'
          }}
        >
          <button
            onClick={() => onSkip && onSkip(-10)}
            style={{
              backgroundColor: 'rgba(255,255,255,0.1)',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: isMobile ? '40px' : '50px',
              height: isMobile ? '40px' : '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            type="button"
          >
            <IoPlaySkipBack size={isMobile ? 18 : 24} />
          </button>
          
          <button
            onClick={onPlayPause}
            style={{
              backgroundColor: colorPrimary,
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: isMobile ? '55px' : '70px',
              height: isMobile ? '55px' : '70px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: `0 0 20px ${colorPrimary}80`
            }}
            type="button"
          >
            {isPlaying ? 
              <IoPauseCircle size={isMobile ? 30 : 40} /> : 
              <IoPlayCircle size={isMobile ? 30 : 40} />}
          </button>
          
          <button
            onClick={() => onSkip && onSkip(10)}
            style={{
              backgroundColor: 'rgba(255,255,255,0.1)',
              color: 'white',
              border: 'none',
              borderRadius: '50%',
              width: isMobile ? '40px' : '50px',
              height: isMobile ? '40px' : '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer'
            }}
            type="button"
          >
            <IoPlaySkipForward size={isMobile ? 18 : 24} />
          </button>
        </div>
        
        <div
          className="additional-controls"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '90%',
            maxWidth: '800px'
          }}
        >
          <div style={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: '10px', 
            width: '40%' 
          }}>
            <button
              onClick={toggleMute}
              style={{
                backgroundColor: 'transparent',
                color: 'white',
                border: 'none',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
              type="button"
            >
              {isMuted ? <IoVolumeOff size={isMobile ? 16 : 20} /> : <IoVolumeHigh size={isMobile ? 16 : 20} />}
            </button>
            
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
              style={{
                flex: 1,
                height: '4px',
                appearance: 'none',
                backgroundColor: 'rgba(255,255,255,0.2)',
                outline: 'none',
                borderRadius: '2px',
                background: `linear-gradient(to right, ${colorPrimary} 0%, ${colorPrimary} ${volume * 100}%, rgba(255,255,255,0.2) ${volume * 100}%, rgba(255,255,255,0.2) 100%)`
              }}
            />
          </div>
          
          <div style={{ display: 'flex', gap: '20px' }}>
            {allowPlaylistAccess && musicLibrary && musicLibrary.length > 0 && (
              <button
                onClick={() => setShowPlaylist(true)}
                style={{
                  backgroundColor: 'rgba(255,255,255,0.1)',
                  color: 'white',
                  border: 'none',
                  borderRadius: '50%',
                  width: isMobile ? '36px' : '44px',
                  height: isMobile ? '36px' : '44px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  cursor: 'pointer'
                }}
                type="button"
              >
                <IoList size={isMobile ? 18 : 22} />
              </button>
            )}
            
            <button
              onClick={onToggleFullScreen}
              style={{
                backgroundColor: 'rgba(255,255,255,0.1)',
                color: 'white',
                border: 'none',
                borderRadius: '50%',
                width: isMobile ? '36px' : '44px',
                height: isMobile ? '36px' : '44px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: 'pointer'
              }}
              type="button"
            >
              <IoContract size={isMobile ? 18 : 22} />
            </button>
          </div>
        </div>
      </div>
      
      {/* Playlist Modal */}
      {showPlaylist && (
        <div
          className="playlist-overlay"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: 'rgba(0,0,0,0.8)',
            zIndex: 2001,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '20px'
          }}
        >
          <div
            className="playlist-modal"
            style={{
              width: '100%',
              maxWidth: '500px',
              maxHeight: '80vh',
              backgroundColor: '#1C1C2E',
              borderRadius: '16px',
              boxShadow: '0 10px 30px rgba(0,0,0,0.4)',
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column'
            }}
          >
            <div
              className="playlist-header"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                padding: '16px 20px',
                borderBottom: '1px solid rgba(255,255,255,0.1)'
              }}
            >
              <h3 style={{ margin: 0, color: '#fff', fontSize: '18px' }}>Select Background Music</h3>
              <button
                onClick={() => setShowPlaylist(false)}
                style={{
                  background: 'transparent',
                  border: 'none',
                  color: 'white',
                  cursor: 'pointer',
                  padding: '4px'
                }}
                type="button"
              >
                <IoClose size={24} />
              </button>
            </div>
            
            <div
              className="playlist-content"
              style={{
                overflowY: 'auto',
                padding: '10px',
                flex: 1
              }}
            >
              {Array.isArray(musicLibrary) && musicLibrary.length > 0 ? (
                musicLibrary.map((track, index) => {
                  if (!track || !track.name) return null;
                  const displayName = track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                  const isActive = track.name === selectedMusic;
                  const trackMood = getMoodLabel(track.name);
                  const coverArt = getAlbumArt(track.name);
                  
                  return (
                    <div
                      key={track.name || index}
                      onClick={() => onMusicSelect(track.name)}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '12px',
                        borderRadius: '8px',
                        marginBottom: '8px',
                        cursor: 'pointer',
                        backgroundColor: isActive ? 'rgba(125,18,255,0.15)' : 'transparent',
                        transform: isActive ? 'translateY(-1px)' : 'none',
                        transition: 'all 0.2s ease'
                      }}
                    >
                      <div
                        style={{
                          width: '48px',
                          height: '48px',
                          borderRadius: '8px',
                          backgroundImage: `url(${coverArt})`,
                          backgroundSize: 'cover',
                          backgroundPosition: 'center',
                          marginRight: '12px'
                        }}
                      ></div>
                      <div style={{ flex: 1, overflow: 'hidden' }}>
                        <div
                          style={{
                            fontSize: '16px',
                            fontWeight: isActive ? '600' : '500',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            color: '#ffffff'
                          }}
                        >
                          {displayName}
                        </div>
                        <div style={{ fontSize: '13px', color: 'rgba(255,255,255,0.6)', marginTop: '2px' }}>
                          {trackMood}
                        </div>
                      </div>
                      {isActive && (
                        <div
                          style={{
                            width: '6px',
                            height: '24px',
                            backgroundColor: '#7d12ff',
                            borderRadius: '3px',
                            marginLeft: '12px'
                          }}
                        ></div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '40px 20px',
                    color: 'rgba(255,255,255,0.7)',
                    textAlign: 'center'
                  }}
                >
                  <div style={{ fontSize: '16px', marginBottom: '10px' }}>
                    No music tracks available
                  </div>
                  <button
                    onClick={() => setShowPlaylist(false)}
                    style={{
                      marginTop: '10px',
                      padding: '8px 16px',
                      borderRadius: '8px',
                      background: 'rgba(125,18,255,0.2)',
                      border: '1px solid rgba(125,18,255,0.4)',
                      color: 'white',
                      cursor: 'pointer'
                    }}
                    type="button"
                  >
                    Close
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      
      {/* Close button */}
      <button
        onClick={onToggleFullScreen}
        style={{
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          color: 'white',
          border: 'none',
          borderRadius: '50%',
          width: isMobile ? '36px' : '44px',
          height: isMobile ? '36px' : '44px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer',
          position: 'absolute',
          top: '20px',
          right: '20px',
          zIndex: 1002
        }}
        type="button"
      >
        <IoClose size={isMobile ? 20 : 24} />
      </button>
      
      {/* Styles */}
      <style>{`
        @keyframes pulse {
          0% { transform: scale(0.95); opacity: 0.7; }
          100% { transform: scale(1.05); opacity: 0.9; }
        }
        input[type="range"]::-webkit-slider-thumb {
          -webkit-appearance: none;
          width: ${isMobile ? '14px' : '16px'};
          height: ${isMobile ? '14px' : '16px'};
          border-radius: 50%;
          background: ${colorPrimary};
          cursor: pointer;
          box-shadow: 0 0 5px ${colorPrimary}80;
        }
        input[type="range"]::-moz-range-thumb {
          width: ${isMobile ? '14px' : '16px'};
          height: ${isMobile ? '14px' : '16px'};
          border-radius: 50%;
          background: ${colorPrimary};
          cursor: pointer;
          border: none;
          box-shadow: 0 0 5px ${colorPrimary}80;
        }
      `}</style>
    </div>
  );
};

export default React.memo(FullScreenVisualizer);
