// src/pages/MeditationApp/hooks/useToast.js
import { useCallback } from 'react';
import { showToast } from '../components/common/CustomToast';



/**
 * Custom hook for displaying toast notifications in the meditation app
 */
const useToast = () => {
  // Success toast

  // Warning toast
  const warning = useCallback((message, options = {}) => {
    return showToast.warning(message, {
      autoClose: 5000,
      ...options
    });
  }, []);

  // Error toast
  const error = useCallback((message, options = {}) => {
    return showToast.error(message, {
      autoClose: 7000,
      ...options
    });
  }, []);

  // Loading toast - returns a toast ID that can be updated later
  const loading = useCallback((message, options = {}) => {
    return showToast.loading(message, options);
  }, []);

  // Update an existing toast
  const update = useCallback((toastId, config) => {
    showToast.update(toastId, config);
  }, []);

  // Check if a toast with a specific id is active
  const isActive = useCallback((toastId) => {
    return showToast.isActive(toastId);
  }, []);

  // Dismiss a specific toast or all toasts
  const dismiss = useCallback((toastId) => {
    showToast.dismiss(toastId);
  }, []);

  // Dismiss all toasts
  const dismissAll = useCallback(() => {
    showToast.dismiss();
  }, []);

  const success = useCallback((message, options = {}) => {
    // Define a default toastId based on the message if none provided
    const toastId = options.toastId || `success-${message.replace(/\s+/g, '-').toLowerCase()}`;
    
    // Check if a toast with this ID is already active
    if (showToast.isActive(toastId)) {
      // Update the existing toast instead of creating a new one
      showToast.update(toastId, {
        render: message,
        type: "success",
        ...options
      });
      return toastId;
    }
    
    // Create a new toast with the specified ID
    return showToast.success(message, {
      autoClose: 3000,
      toastId,
      ...options
    });
  }, []);
  
  // Apply the same pattern to other toast types
  const info = useCallback((message, options = {}) => {
    const toastId = options.toastId || `info-${message.replace(/\s+/g, '-').toLowerCase()}`;
    
    if (showToast.isActive(toastId)) {
      showToast.update(toastId, {
        render: message,
        type: "info",
        ...options
      });
      return toastId;
    }
    
    return showToast.info(message, {
      autoClose: 5000,
      toastId,
      ...options
    });
  }, []);

  return {
    success,
    info,
    warning,
    error,
    loading,
    update,
    isActive,
    dismiss,
    dismissAll
  };
};

export default useToast;