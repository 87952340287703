// src/pages/MeditationApp/components/common/decisionTreeData.js
const decisionTree = {
    root: {
      prompt: 'What kind of meditation would you like to try today?',
      options: [
        {
          label: 'Visualization',
          nextNode: 'visualization',
          className: 'visualization-main',
          isMainCategory: true,
          pauseFactor: 1.3,
          icon: '😌' // Visualization icon
        },
        {
          label: 'Mindfulness',
          nextNode: 'mindfulness',
          className: 'mindfulness-main',
          isMainCategory: true,
          pauseFactor: 1.2,
          icon: '🧘' // Mindfulness icon
        },
        {
          label: 'Sleep',
          nextNode: 'sleep',
          className: 'sleep-main',
          isMainCategory: true,
          pauseFactor: 1.2,
          icon: '🧠' // Sleep icon (brain/mind)
        },
        {
          label: 'Manifestation',
          nextNode: 'manifestation',
          className: 'manifestation-main',
          isMainCategory: true,
          pauseFactor: 1.5,
          icon: '✨' // Manifestation icon (sparkles)
        },
        {
          label: 'Breath',
          nextNode: 'breath_awareness',
          className: 'breath-main',
          isMainCategory: true,
          pauseFactor: 1.5,
          icon: '🚶' // Breath icon
        },
        {
          label: 'Affirmations',
          nextNode: 'affirmations',
          className: 'affirmations-main',
          isMainCategory: true,
          pauseFactor: 1.3,
          icon: '☀️' // Affirmations icon (sun)
        },
        {
          label: 'Concentration',
          nextNode: 'concentration',
          className: 'concentration-main',
          isMainCategory: true,
          pauseFactor: 1.3,
          icon: '🔍' // Concentration icon (focus)
        },
        {
          label: 'Body Scan',
          nextNode: 'body_scan',
          className: 'body_scan-main',
          isMainCategory: true,
          pauseFactor: 1.2,
          icon: '🧎' // Body scan icon
        },
        {
          label: 'Unguided',
          nextNode: 'unguided',
          className: 'unguided-main',
          isMainCategory: true,
          pauseFactor: 1.2,
          icon: '🎵' // Unguided icon (music)
        },
      ],
    },
    visualization: {
      prompt: 'What kind of visualization would you like to focus on?',
      options: [
        { 
          label: 'Nature Scenes', 
          nextNode: 'visualizationDuration', 
          className: 'visualization-sub-1',
          icon: '🌳' // Nature scenes icon
        },
        { 
          label: 'Peaceful Places', 
          nextNode: 'visualizationDuration', 
          className: 'visualization-sub-1',
          icon: '🏞️' // Peaceful places icon
        },
        { 
          label: 'Specific Goals', 
          nextNode: 'visualizationDuration', 
          className: 'visualization-sub-1',
          icon: '🎯' // Goals icon
        },
      ],
    },
    visualizationDuration: {
      prompt: 'How long would you like your visualization meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'visualizationNotes', 
          className: 'visualization-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'visualizationNotes', 
          className: 'visualization-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'visualizationNotes', 
          className: 'visualization-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    visualizationNotes: {
      prompt: 'Would you like to add customization to your visualization meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'visualization-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'visualization-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    mindfulness: {
      prompt: 'What kind of mindfulness practice would you prefer?',
      options: [
        { 
          label: 'Body Scan', 
          nextNode: 'mindfulnessDuration', 
          className: 'mindfulness-sub-1',
          icon: '👁️' // Body scan icon
        },
        { 
          label: 'Breath Focus', 
          nextNode: 'mindfulnessDuration', 
          className: 'mindfulness-sub-1',
          icon: '🫁' // Breath focus icon
        },
        { 
          label: 'Open Awareness', 
          nextNode: 'mindfulnessDuration', 
          className: 'mindfulness-sub-1',
          icon: '🌟' // Open awareness icon
        },
      ],
    },
    mindfulnessDuration: {
      prompt: 'How long would you like your mindfulness meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'mindfulnessNotes', 
          className: 'mindfulness-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'mindfulnessNotes', 
          className: 'mindfulness-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'mindfulnessNotes', 
          className: 'mindfulness-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    mindfulnessNotes: {
      prompt: 'Would you like to add customization to your mindfulness meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'mindfulness-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'mindfulness-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    sleep: {
      prompt: 'What would you like to focus on for better sleep?',
      options: [
        { 
          label: 'Progressive Relaxation', 
          nextNode: 'sleepDuration', 
          className: 'sleep-sub-1',
          icon: '🌊' // Progressive relaxation icon
        },
        { 
          label: 'Soothing Bedtime Story', 
          nextNode: 'sleepDuration', 
          className: 'sleep-sub-1',
          icon: '📚' // Bedtime story icon
        },
        { 
          label: 'Calming Breathing', 
          nextNode: 'sleepDuration', 
          className: 'sleep-sub-1',
          icon: '💨' // Breathing icon
        },
      ],
    },
    sleepDuration: {
      prompt: 'How long would you like your sleep meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'sleepNotes', 
          className: 'sleep-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'sleepNotes', 
          className: 'sleep-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'sleepNotes', 
          className: 'sleep-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    sleepNotes: {
      prompt: 'Would you like to add customization to your sleep meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'sleep-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'sleep-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    manifestation: {
      prompt: 'What are you trying to manifest?',
      options: [
        { 
          label: 'Abundance', 
          nextNode: 'manifestationDuration', 
          className: 'manifestation-sub-1',
          icon: '💰' // Abundance icon
        },
        { 
          label: 'Love', 
          nextNode: 'manifestationDuration', 
          className: 'manifestation-sub-1',
          icon: '❤️' // Love icon
        },
        { 
          label: 'Health', 
          nextNode: 'manifestationDuration', 
          className: 'manifestation-sub-1',
          icon: '🌱' // Health icon
        },
      ],
    },
    manifestationDuration: {
      prompt: 'How long would you like your manifestation meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'manifestationNotes', 
          className: 'manifestation-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'manifestationNotes', 
          className: 'manifestation-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'manifestationNotes', 
          className: 'manifestation-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    manifestationNotes: {
      prompt: 'Would you like to add customization to your manifestation meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'manifestation-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'manifestation-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    breath_awareness: {
      prompt: 'What kind of breath awareness practice would you like?',
      options: [
        { 
          label: 'Breath Counting', 
          nextNode: 'breathAwarenessDuration', 
          className: 'breath-sub-1',
          icon: '🔢' // Counting icon
        },
        { 
          label: 'Breath Sensations', 
          nextNode: 'breathAwarenessDuration', 
          className: 'breath-sub-1',
          icon: '👃' // Sensation icon
        },
        { 
          label: 'Rhythmic Breathing', 
          nextNode: 'breathAwarenessDuration', 
          className: 'breath-sub-1',
          icon: '🎵' // Rhythm icon
        },
      ],
    },
    breathAwarenessDuration: {
      prompt: 'How long would you like your breath awareness meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'breathAwarenessNotes', 
          className: 'breath-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'breathAwarenessNotes', 
          className: 'breath-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'breathAwarenessNotes', 
          className: 'breath-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    breathAwarenessNotes: {
      prompt: 'Would you like to add customization to your breath awareness meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'breath-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'breath-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    affirmations: {
      prompt: 'What type of affirmations would you like to focus on?',
      options: [
        { 
          label: 'Self-Love', 
          nextNode: 'affirmationsDuration', 
          className: 'affirmations-sub-1',
          icon: '💖' // Self-love icon
        },
        { 
          label: 'Confidence', 
          nextNode: 'affirmationsDuration', 
          className: 'affirmations-sub-1',
          icon: '💪' // Confidence icon
        },
        { 
          label: 'Gratitude', 
          nextNode: 'affirmationsDuration', 
          className: 'affirmations-sub-1',
          icon: '🙏' // Gratitude icon
        },
      ],
    },
    affirmationsDuration: {
      prompt: 'How long would you like your affirmations meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'affirmationsNotes', 
          className: 'affirmations-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'affirmationsNotes', 
          className: 'affirmations-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'affirmationsNotes', 
          className: 'affirmations-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    affirmationsNotes: {
      prompt: 'Would you like to add customization to your affirmations meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'affirmations-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'affirmations-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    concentration: {
      prompt: 'What would you like to focus your concentration on?',
      options: [
        { 
          label: 'Candle Flame', 
          nextNode: 'concentrationDuration', 
          className: 'concentration-sub-1',
          icon: '🔥' // Candle flame icon
        },
        { 
          label: 'Visual Object', 
          nextNode: 'concentrationDuration', 
          className: 'concentration-sub-1',
          icon: '🔮' // Visual object icon
        },
        { 
          label: 'Mantra', 
          nextNode: 'concentrationDuration', 
          className: 'concentration-sub-1',
          icon: '🗣️' // Mantra icon
        },
      ],
    },
    concentrationDuration: {
      prompt: 'How long would you like your concentration meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'concentrationNotes', 
          className: 'concentration-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'concentrationNotes', 
          className: 'concentration-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'concentrationNotes', 
          className: 'concentration-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    concentrationNotes: {
      prompt: 'Would you like to add customization to your concentration meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'concentration-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'concentration-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    body_scan: {
      prompt: 'What kind of body scan would you like to practice?',
      options: [
        { 
          label: 'Detailed', 
          nextNode: 'bodyScanDuration', 
          className: 'body_scan-sub-1',
          icon: '🔍' // Detailed icon (magnifying glass)
        },
        { 
          label: 'General', 
          nextNode: 'bodyScanDuration', 
          className: 'body_scan-sub-1',
          icon: '👤' // General icon (silhouette)
        },
      ],
    },
    bodyScanDuration: {
      prompt: 'How long would you like your body scan meditation to be?',
      options: [
        { 
          label: '5 minutes', 
          nextNode: 'bodyScanNotes', 
          className: 'body_scan-sub-2', 
          wordCount: 750,
          icon: '⏱️' // 5 minutes icon
        },
        { 
          label: '10 minutes', 
          nextNode: 'bodyScanNotes', 
          className: 'body_scan-sub-2', 
          wordCount: 1500,
          icon: '⏲️' // 10 minutes icon
        },
        { 
          label: '20 minutes', 
          nextNode: 'bodyScanNotes', 
          className: 'body_scan-sub-2', 
          wordCount: 3000,
          icon: '🕰️' // 20 minutes icon
        },
      ],
    },
    bodyScanNotes: {
      prompt: 'Would you like to add customization to your body scan meditation?',
      options: [
        { 
          label: 'Personalized', 
          nextNode: null, 
          className: 'body_scan-result',
          icon: '🎨' // Personalized icon
        },
        { 
          label: 'Guided', 
          nextNode: null, 
          className: 'body_scan-result',
          icon: '📝' // Guided icon
        },
      ],
    },
    unguided: {
      prompt: 'Would you like to proceed with an unguided meditation session?',
      options: [
        { 
          label: 'Yes', 
          nextNode: null, 
          className: 'unguided-result',
          icon: '✅' // Yes icon
        },
        { 
          label: 'No', 
          nextNode: 'root', 
          className: 'unguided-main',
          icon: '❌' // No icon
        },
      ],
    },
  };
  
  export default decisionTree;