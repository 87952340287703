import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Bell, LogOut } from 'lucide-react';
import { useAuth } from '../../../../contexts/AuthContext';
import SlideMenu from './SlideMenu';
import NotificationsPanel from './NotificationsPanel';
import './AppHeader.css';

const AppHeader = ({
  onToggleSidebar,
  onToggleNotifications,
  notificationsCount = 0,
  isSidebarOpen,
  isNotificationsOpen,
  onCloseNotifications,
  notifications = [],
  onMarkAsRead,
  onClear,
  onClearAll,
}) => {
  const navigate = useNavigate();
  const { logout } = useAuth();

  const handleNotificationsToggle = () => {
    onToggleNotifications?.();
  };

  const handleLogout = async () => {
    try {
      console.log("Attempting logout...");
      await logout();
      localStorage.removeItem('authToken');
      navigate('/login');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  // Handle sidebar toggle with improved debug logging
  const handleSidebarToggle = () => {
    console.log('SlideMenu toggled in AppHeader, current state:', isSidebarOpen);
    if (onToggleSidebar) {
      onToggleSidebar();
    } else {
      console.error('onToggleSidebar function is not provided to AppHeader');
    }
  };

  return (
    <header className="app-header">
      <div className="header-left">
        <SlideMenu
          isOpen={isSidebarOpen}
          onToggle={handleSidebarToggle}
        />
        <div className="app-logo-text">Pause</div>
      </div>

      <div className="header-right">
        <button
          onClick={handleNotificationsToggle}
          className={`notifications-trigger ${isNotificationsOpen ? 'active' : ''}`}
          aria-label="Notifications"
        >
          <Bell size={24} />
          {notificationsCount > 0 && (
            <span className="notifications-badge">{notificationsCount}</span>
          )}
        </button>

        <button onClick={handleLogout} className="logout-button" aria-label="Logout">
          <LogOut size={24} />
        </button>
      </div>

      {isNotificationsOpen && (
        <NotificationsPanel
          notifications={notifications}
          onMarkAsRead={onMarkAsRead}
          onClear={onClear}
          onClearAll={onClearAll}
          onCloseNotifications={onCloseNotifications}
          isNotificationsOpen={isNotificationsOpen}
        />
      )}
    </header>
  );
};

export default AppHeader;