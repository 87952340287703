import React, { useState, useEffect, useRef } from 'react';

/**
 * AudioDiagnosticsTool - A component to help debug audio issues
 * Add this to your app temporarily to identify problems with audio loading and playback
 */
const AudioDiagnosticsTool = ({ 
  audioUrl, 
  analyzerNode, 
  audioElement,
  showDiagnostics = true 
}) => {
  // State for tracking diagnostics - always initialize hooks regardless of showDiagnostics
  const [results, setResults] = useState({
    audioExists: false,
    audioCanPlay: false,
    audioHasError: false,
    audioErrorCode: null,
    audioErrorMessage: null,
    analyzerExists: false,
    analyzerCanGetData: false,
    urlValid: false,
    browserSupport: false,
    mimeSupport: {},
    audioContext: null,
    globalAudioState: false,
  });
  
  // Refs - always initialize regardless of showDiagnostics
  const testAudioRef = useRef(null);
  const diagnosticTimerRef = useRef(null);
  
  // Run diagnostics - always initialize useEffect
  useEffect(() => {
    // Skip actual work if diagnostics are disabled
    if (!showDiagnostics) return;
    
    // Create a test audio element if none provided
    const testAudio = audioElement || new Audio();
    testAudioRef.current = testAudio;
    
    // Check browser support
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const analyzerSupport = typeof AnalyserNode !== 'undefined';
    
    // Check file type support
    const mimeSupport = {
      mp3: testAudio.canPlayType('audio/mpeg'),
      wav: testAudio.canPlayType('audio/wav'),
      aac: testAudio.canPlayType('audio/aac'),
      ogg: testAudio.canPlayType('audio/ogg')
    };
    
    // Update initial results
    setResults(prev => ({
      ...prev,
      audioExists: !!testAudio,
      analyzerExists: !!analyzerNode && typeof analyzerNode === 'object',
      urlValid: !!audioUrl && typeof audioUrl === 'string' && 
               (audioUrl.startsWith('http') || audioUrl.startsWith('blob:') || audioUrl.startsWith('data:')),
      browserSupport: !!AudioContext && analyzerSupport,
      mimeSupport,
      audioContext: !!AudioContext,
      globalAudioState: !!(window.globalAudioState && window.globalAudioState.state)
    }));
    
    // Test playability if URL is provided
    if (audioUrl && testAudio) {
      // Set up event listeners
      const handleCanPlay = () => {
        setResults(prev => ({
          ...prev,
          audioCanPlay: true,
          audioHasError: false
        }));
      };
      
      const handleError = () => {
        setResults(prev => ({
          ...prev,
          audioHasError: true,
          audioCanPlay: false,
          audioErrorCode: testAudio.error ? testAudio.error.code : null,
          audioErrorMessage: testAudio.error ? testAudio.error.message : 'Unknown error'
        }));
      };
      
      // Add listeners
      testAudio.addEventListener('canplaythrough', handleCanPlay);
      testAudio.addEventListener('error', handleError);
      
      // Only set src if not already set
      if (!audioElement || testAudio.src !== audioUrl) {
        testAudio.src = audioUrl;
        testAudio.load();
      }
      
      // Test analyzer if provided
      if (analyzerNode) {
        try {
          const dataArray = new Uint8Array(analyzerNode.frequencyBinCount || 0);
          analyzerNode.getByteFrequencyData(dataArray);
          
          const hasData = dataArray.some(val => val > 0);
          setResults(prev => ({
            ...prev,
            analyzerCanGetData: true,
            analyzerHasData: hasData
          }));
        } catch (e) {
          setResults(prev => ({
            ...prev,
            analyzerCanGetData: false,
            analyzerError: e.message
          }));
        }
      }
      
      // Set up cleanup
      return () => {
        if (!audioElement) {
          // Only cleanup our own test audio, not passed audio element
          testAudio.removeEventListener('canplaythrough', handleCanPlay);
          testAudio.removeEventListener('error', handleError);
          testAudio.src = '';
        }
        
        if (diagnosticTimerRef.current) {
          clearTimeout(diagnosticTimerRef.current);
        }
      };
    }
  }, [audioUrl, analyzerNode, audioElement, showDiagnostics]);
  
  // Return null if diagnostics are disabled - AFTER all hooks have been called
  if (!showDiagnostics) return null;
  
  // Render diagnostics UI
  return (
    <div
      style={{
        position: 'fixed',
        bottom: '10px',
        right: '10px',
        width: '300px',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        color: 'white',
        padding: '10px',
        borderRadius: '5px',
        fontSize: '12px',
        zIndex: 10000,
        fontFamily: 'monospace',
        maxHeight: '80vh',
        overflowY: 'auto'
      }}
    >
      <h3 style={{ margin: '0 0 10px 0', fontSize: '14px', borderBottom: '1px solid #444' }}>
        Audio Diagnostics
      </h3>
      
      <div style={{ marginBottom: '10px' }}>
        <div>URL: <span style={{ color: results.urlValid ? '#4caf50' : '#f44336' }}>
          {results.urlValid ? '✓ Valid' : '✗ Invalid'}
        </span></div>
        {audioUrl && (
          <div style={{ fontSize: '10px', wordBreak: 'break-all', opacity: 0.8 }}>
            {audioUrl.substring(0, 40)}...
          </div>
        )}
      </div>
      
      <div style={{ marginBottom: '10px' }}>
        <div>Audio Element: <span style={{ color: results.audioExists ? '#4caf50' : '#f44336' }}>
          {results.audioExists ? '✓ Exists' : '✗ Missing'}
        </span></div>
        <div>Can Play: <span style={{ color: results.audioCanPlay ? '#4caf50' : '#f44336' }}>
          {results.audioCanPlay ? '✓ Yes' : '✗ No'}
        </span></div>
        {results.audioHasError && (
          <div style={{ color: '#f44336' }}>
            Error: {results.audioErrorCode} - {results.audioErrorMessage || 'Unknown error'}
          </div>
        )}
      </div>
      
      <div style={{ marginBottom: '10px' }}>
        <div>Analyzer: <span style={{ color: results.analyzerExists ? '#4caf50' : '#f44336' }}>
          {results.analyzerExists ? '✓ Exists' : '✗ Missing'}
        </span></div>
        {results.analyzerExists && (
          <div>Can Get Data: <span style={{ color: results.analyzerCanGetData ? '#4caf50' : '#f44336' }}>
            {results.analyzerCanGetData ? '✓ Yes' : '✗ No'}
          </span></div>
        )}
        {results.analyzerError && (
          <div style={{ color: '#f44336' }}>
            Error: {results.analyzerError}
          </div>
        )}
      </div>
      
      <div style={{ marginBottom: '10px' }}>
        <div>Browser Support:</div>
        <div>AudioContext: <span style={{ color: results.audioContext ? '#4caf50' : '#f44336' }}>
          {results.audioContext ? '✓ Supported' : '✗ Not supported'}
        </span></div>
        <div>File Types:</div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px', fontSize: '10px' }}>
          {Object.entries(results.mimeSupport).map(([format, support]) => (
            <div 
              key={format}
              style={{ 
                backgroundColor: support === 'probably' ? '#4caf50' : 
                               support === 'maybe' ? '#ff9800' : '#f44336',
                padding: '2px 5px',
                borderRadius: '3px'
              }}
            >
              {format}: {support || 'no'}
            </div>
          ))}
        </div>
      </div>
      
      <div style={{ marginBottom: '10px' }}>
        <div>Global Audio State: <span style={{ color: results.globalAudioState ? '#4caf50' : '#f44336' }}>
          {results.globalAudioState ? '✓ Available' : '✗ Missing'}
        </span></div>
      </div>
      
      <button
        onClick={() => {
          if (testAudioRef.current) {
            // Force play with user interaction
            testAudioRef.current.volume = 0.1;
            testAudioRef.current.play().catch(e => {
              console.warn("Diagnostic play failed:", e);
            });
          }
        }}
        style={{
          background: '#4caf50',
          border: 'none',
          borderRadius: '3px',
          padding: '5px 10px',
          color: 'white',
          cursor: 'pointer',
          marginRight: '5px'
        }}
      >
        Test Play
      </button>
      
      <button
        onClick={() => {
          const elem = document.createElement('a');
          elem.href = audioUrl;
          elem.download = 'audio-test.mp3';
          document.body.appendChild(elem);
          elem.click();
          document.body.removeChild(elem);
        }}
        style={{
          background: '#2196f3',
          border: 'none',
          borderRadius: '3px',
          padding: '5px 10px',
          color: 'white',
          cursor: 'pointer'
        }}
      >
        Download
      </button>
    </div>
  );
};

export default AudioDiagnosticsTool;