import React from 'react';
import { NavLink } from 'react-router-dom';
import { Clock, Music, Bell, Settings } from 'lucide-react';
import { motion, AnimatePresence } from 'framer-motion';
import './Sidebar.css';
import { useTheme } from '../contexts/ThemeContext';

const menuItems = [
  { icon: Clock, label: 'Meditation', path: '/app/meditation' },
  { icon: Music, label: 'Mood Tracker', path: '/app/mood' },
  { icon: Bell, label: 'Notifications', path: '/app/notifications', hasBadge: true },
  { icon: Settings, label: 'Settings', path: '/app/settings' },
];

const sidebarVariants = {
  open: {
    x: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
      mass: 1,
      staggerChildren: 0.05,
    },
  },
  closed: {
    x: '-100%',
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
      mass: 1,
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const itemVariants = {
  open: {
    x: 0,
    opacity: 1,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
  closed: {
    x: -20,
    opacity: 0,
    transition: {
      type: 'spring',
      stiffness: 400,
      damping: 40,
    },
  },
};

const Sidebar = ({ isOpen, onClose, notificationCount = 0 }) => {
  const { themeConfig } = useTheme();

  // Always call onClose on navigation (remove window.innerWidth check if you always want to close)
  const handleNavigation = () => {
    onClose && onClose();
  };

  return (
    <AnimatePresence mode="wait">
      {isOpen && (
        <>
          <motion.div
            key="backdrop"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.2 }}
            className="sidebar-backdrop"
            onClick={onClose}
          />
          <motion.nav
            key="sidebar"
            className={`sidebar-nav ${isOpen ? 'open' : ''}`}
            initial="closed"
            animate="open"
            exit="closed"
            variants={sidebarVariants}
          >
            {menuItems.map(({ icon: Icon, label, path, hasBadge }) => (
              <motion.div key={path} variants={itemVariants}>
                <NavLink
                  to={path}
                  className={({ isActive }) =>
                    `sidebar-link ${isActive ? 'active' : ''}`
                  }
                  onClick={handleNavigation}
                >
                  <motion.div
                    className="sidebar-icon-wrapper"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                  >
                    <Icon size={20} />
                    {hasBadge && notificationCount > 0 && (
                      <motion.span
                        key={`badge-${path}`}
                        className="sidebar-badge"
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        exit={{ scale: 0 }}
                      >
                        {notificationCount}
                      </motion.span>
                    )}
                  </motion.div>
                  <span className="sidebar-link-text">{label}</span>
                </NavLink>
              </motion.div>
            ))}
          </motion.nav>
        </>
      )}
    </AnimatePresence>
  );
};

export default Sidebar;
