/**
 * Enhanced version of the audioService to better handle progress tracking,
 * network issues, and provide robust fallbacks without blob URL conversions.
 */

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';

/**
 * Returns a full URL for an audio file.
 */
export const createFullAudioUrl = (path) => {
  if (!path) return '';
  if (path.startsWith('http')) return path;
  const formattedPath = path.startsWith('/') ? path : `/${path}`;
  return `${API_BASE_URL}${formattedPath}`;
};

/**
 * Check if a URL is valid and usable.
 */
export const isValidUrl = (url) => {
  if (!url) return false;
  if (typeof url !== 'string') return false;
  if (url.includes('undefined')) return false;
  return url.startsWith('http') || url.startsWith('https');
};

/**
 * Simple fetch with timeout and retry logic.
 */
export const enhancedFetch = async (url, options = {}, maxRetries = 2) => {
  let attempts = 0;
  
  while (attempts < maxRetries) {
    try {
      // Add a timeout to the fetch request
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), options.timeout || 30000);
      
      const fetchOptions = {
        ...options,
        signal: controller.signal,
        headers: {
          'Accept': 'application/json',
          ...(options.headers || {})
        }
      };
      
      const response = await fetch(url, fetchOptions);
      clearTimeout(timeoutId);
      
      return response;
    } catch (error) {
      attempts++;
      console.error(`Fetch error (attempt ${attempts}/${maxRetries}):`, error);
      
      if (attempts >= maxRetries) {
        throw error;
      }
      
      // Add exponential backoff
      const delay = Math.min(1000 * Math.pow(2, attempts - 1), 10000);
      await new Promise(resolve => setTimeout(resolve, delay));
    }
  }
};

/**
 * Fetch the music library from the API.
 * Returns an array of track objects or a fallback library.
 */
export const fetchMusicLibrary = async () => {
  try {
    console.log('Fetching music library from:', `${API_BASE_URL}/load-music`);
    const timestamp = Date.now();
    
    const response = await enhancedFetch(`${API_BASE_URL}/load-music?t=${timestamp}`, {
      headers: {
        'Accept': 'application/json'
      },
      credentials: 'include',
      timeout: 15000
    });
    
    if (!response.ok) {
      console.error(`API error: ${response.status}`);
      return getFallbackMusicLibrary();
    }
    
    const data = await response.json();
    if (data && data.status === 'success' && Array.isArray(data.data?.musicFiles)) {
      return data.data.musicFiles.map((file) => {
        if (typeof file === 'string') {
          const name = file.replace(/^Background_Music\//i, '').replace(/\.mp3$/i, '').trim();
          return { 
            name, 
            displayName: name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
          };
        } else if (file && file.name) {
          const name = file.name.replace(/^Background_Music\//i, '').replace(/\.mp3$/i, '').trim();
          return {
            name,
            displayName: file.displayName || name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase())
          };
        }
        return null;
      }).filter(Boolean);
    }
    console.warn("Invalid response format from server, using fallback music library");
    return getFallbackMusicLibrary();
  } catch (error) {
    console.error('Error fetching music library:', error);
    return getFallbackMusicLibrary();
  }
};

/**
 * Default music library to use as fallback.
 */
const getFallbackMusicLibrary = () => [
  { name: "calm_meditation", displayName: "Beautiful Clarity" },
  { name: "ocean_waves", displayName: "Ocean Waves" },
  { name: "forest_ambience", displayName: "Forest Ambience" },
  { name: "gentle_rain", displayName: "Gentle Rain" },
  { name: "mountain_stream", displayName: "Mountain Stream" },
  { name: "relaxing_piano", displayName: "Relaxing Piano" },
  { name: "deep_meditation", displayName: "Deep Meditation" },
  { name: "cosmic_journey", displayName: "Cosmic Journey" },
  { name: "mindful_journey", displayName: "Mindful Journey" },
  { name: "journey_toward_the_unknown", displayName: "Journey Toward the Unknown" }
];

/**
 * Returns a preview URL for a music track.
 */
export const getMusicPreviewUrl = (trackName) => {
  if (!trackName) return '';
  try {
    const cleanedTrackName = trackName
      .replace(/^Background_Music\/?/i, '')
      .replace(/\.mp3$/i, '')
      .trim();
    const formattedKey = `Background_Music/${cleanedTrackName}.mp3`;
    return createFullAudioUrl(`/audio-previews/${encodeURIComponent(formattedKey)}`);
  } catch (error) {
    console.error('Error formatting music preview URL:', error);
    return createFullAudioUrl(`/audio-previews/${encodeURIComponent(trackName)}`);
  }
};

/**
 * Create TTS audio from a meditation script without blob conversions.
 * Returns an object with filePath, filename, and jobId.
 */
export const createTTS = async (ttsData) => {
  try {
    console.log("Starting TTS generation with params:", {
      voice: ttsData.voice,
      textLength: ttsData.text ? ttsData.text.length : 0,
      speakingRate: ttsData.speaking_rate
    });

    // Use a longer timeout (90 seconds) for the TTS generation
    const response = await enhancedFetch(`${API_BASE_URL}/synthesize-speech`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(ttsData),
      timeout: 90000
    });
    
    if (!response.ok) {
      let errorText = "Unknown server error";
      try {
        const errorData = await response.json();
        errorText = errorData.message || `Server error: ${response.status}`;
      } catch (e) {
        errorText = await response.text() || `Server error: ${response.status}`;
      }
      throw new Error(errorText);
    }
    
    const data = await response.json();
    if (data && data.status === 'success') {
      const jobId = data.data.job_id || data.data.jobId || null;
      
      // Create direct URL path
      const filePath = createFullAudioUrl(data.data.file_path);
      
      return { 
        success: true, 
        filePath, 
        filename: data.data.filename, 
        jobId 
      };
    } else {
      throw new Error(data.message || 'Failed to generate audio');
    }
  } catch (error) {
    console.error('TTS generation failed:', error);
    
    // Generate a timestamp and create fallback filename
    const timestamp = Date.now();
    const placeholderFilename = `TTS_fallback_${ttsData.voice || 'default'}_${timestamp}.mp3`;
    
    // Create direct URL path to fallback
    const placeholderPath = createFullAudioUrl(`/tts/${placeholderFilename}`);
    
    // Alternative fallback approach for timeout
    if (error.name === 'AbortError' || error.message.includes('timeout')) {
      try {
        console.log("Primary TTS generation timed out, trying fallback method with shorter text");
        
        // Try with a shorter version of the text if it's very long
        if (ttsData.text && ttsData.text.length > 2000) {
          const shortenedData = {
            ...ttsData,
            text: ttsData.text.substring(0, 2000) + "..." // Truncate long text
          };
          
          // Try fallback without awaiting to avoid blocking
          fetch(`${API_BASE_URL}/synthesize-speech`, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(shortenedData)
          }).then(res => {
            if (res.ok) return res.json();
            throw new Error('Fallback TTS generation failed');
          }).then(data => {
            console.log("Fallback TTS generation succeeded in background");
          }).catch(e => {
            console.warn("Background fallback TTS generation failed:", e);
          });
        }
      } catch (fallbackErr) {
        console.error("Error with fallback TTS generation:", fallbackErr);
      }
    }
    
    // Return fallback path directly
    return { 
      success: false,
      filePath: placeholderPath, 
      filename: placeholderFilename, 
      jobId: null, 
      placeholder: true, 
      error: error.message 
    };
  }
};

/**
 * Mix TTS audio with background music using direct URLs.
 * Returns an object with mixed audio information and jobId.
 */
export const mixAudio = async (mixData) => {
  try {
    console.log("Mix audio called with parameters:", JSON.stringify(mixData, null, 2));
    
    // Properly format audio paths
    if (mixData.bg_audio && !mixData.bg_audio.startsWith('http')) {
      let bgPath = mixData.bg_audio.replace(/\.mp3$/i, '').replace(/^Background_Music\//i, '');
      mixData.bg_audio = encodeURIComponent(`Background_Music/${bgPath}.mp3`);
    }
    
    if (mixData.tts_audio && !mixData.tts_audio.startsWith('http')) {
      mixData.tts_audio = encodeURIComponent(
        mixData.tts_audio.startsWith('TTS/') ? mixData.tts_audio : `TTS/${mixData.tts_audio}`
      );
    }
    
    // Multiple retries for reliability
    const maxRetries = 2;
    let retryCount = 0;
    let lastError = null;
    
    while (retryCount <= maxRetries) {
      try {
        if (retryCount > 0) {
          console.log(`Retry attempt ${retryCount} for audio mixing...`);
          await new Promise(resolve => setTimeout(resolve, 1000 * Math.pow(2, retryCount - 1)));
        }
        
        const response = await enhancedFetch(`${API_BASE_URL}/get-mixed-audio`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'Accept': 'application/json' },
          body: JSON.stringify(mixData),
          timeout: 45000
        });
        
        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`Server error: ${response.status} - ${errorText}`);
        }
        
        const data = await response.json();
        
        if (data && data.status === 'success') {
          const jobId = data.data.job_id || data.data.jobId || null;
          
          // Create a direct URL to the file
          const fullFilePath = createFullAudioUrl(data.data.file_path);
          
          return { 
            success: true, 
            ...data.data, 
            file_path: fullFilePath, 
            jobId 
          };
        } else {
          throw new Error(data.message || 'Failed to mix audio');
        }
      } catch (error) {
        console.error(`Error on attempt ${retryCount}:`, error);
        lastError = error;
        retryCount++;
        
        // Only retry on network/server errors
        if (!(error.name === 'TypeError' || error.message.includes('500') || error.message.includes('network')) || retryCount > maxRetries) {
          break;
        }
      }
    }
    
    throw lastError || new Error('Failed to mix audio after retries');
  } catch (error) {
    console.error('Error mixing audio after all retries:', error);
    
    // Create direct fallback path to TTS file
    const timestamp = Date.now();
    const ttsFilename = mixData.tts_audio 
      ? mixData.tts_audio.replace(/^TTS\//, '').replace(/^.*\//, '')
      : `TTS_fallback_${timestamp}.mp3`;
    
    const fallbackPath = createFullAudioUrl(`/tts/${ttsFilename}`);
    
    return {
      success: false,
      file_path: fallbackPath,
      filename: ttsFilename,
      original_filename: `mixed_fallback_${timestamp}.mp3`,
      jobId: null,
      placeholder: true,
      error: error.message
    };
  }
};

/**
 * Clean and format text for TTS.
 */
export const cleanText = (text, preserveSpaces = false) => {
  if (!text) return '';
  
  let cleaned = text
    .replace(/\(\s*(?:pause|medium pause|long pause|extra long pause|extended pause)\s*\)/gi, '')
    .replace(/\([^)]*pause[^)]*\)/gi, '')
    .replace(/\[pause=[0-9.]+\]/gi, '')
    .replace(/\s+([,.!?])/g, '$1')
    .replace(/([,.!?])(?!\s|$)/g, '$1 ');
  
  if (!preserveSpaces) {
    cleaned = cleaned.replace(/\s+/g, ' ');
  }
  
  cleaned = cleaned
    .replace(/(\w)\s*'\s*s\b/g, "$1's")
    .replace(/(\w)\s*'\s*re\b/g, "$1're")
    .replace(/(\w)\s*'\s*ve\b/g, "$1've")
    .replace(/(\w)\s*'\s*ll\b/g, "$1'll")
    .replace(/(\w)\s*'\s*t\b/g, "$1't")
    .replace(/(\w)\s*'\s*d\b/g, "$1'd")
    .replace(/(\w)\s*'\s*m\b/g, "$1'm")
    .replace(/\.\s*\.\s*\./g, '...')
    .trim();
  
  return cleaned;
};

/**
 * Get cover art URL for a music track.
 */
export const getTrackCoverArt = (trackName, coverArtOptions = []) => {
  const defaultImage = 'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=300&h=300&fit=crop&auto=format';
  
  if (!trackName || !Array.isArray(coverArtOptions) || coverArtOptions.length === 0) {
    return defaultImage;
  }
  
  try {
    const cleanTrackName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
    let hash = 0;
    
    for (let i = 0; i < cleanTrackName.length; i++) {
      hash = ((hash << 5) - hash) + cleanTrackName.charCodeAt(i);
      hash |= 0;
    }
    
    const index = Math.abs(hash) % coverArtOptions.length;
    return coverArtOptions[index];
  } catch (error) {
    console.error('Error getting track cover art:', error);
    return coverArtOptions[0] || defaultImage;
  }
};

/**
 * Generate a mood label based on track name.
 */
export const getMoodLabelFromTrackName = (trackName) => {
  if (!trackName) return 'Relaxing Music';
  
  try {
    const cleanTrackName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
    const lowerName = cleanTrackName.toLowerCase();
    
    const moodMap = [
      { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
      { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
      { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
      { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
      { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
      { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
      { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
      { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
      { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
      { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
      { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
    ];
    
    for (const mood of moodMap) {
      if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
        return mood.label;
      }
    }
    
    return cleanTrackName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
  } catch (error) {
    console.error('Error getting mood label:', error);
    return 'Meditation Music';
  }
};

/**
 * Create an SSE progress stream.
 */
export const createProgressStream = (jobId, onProgress, onComplete, onError) => {
  if (!jobId) {
    console.error('No job ID provided for progress stream');
    if (onError) onError(new Error('No job ID provided'));
    return null;
  }
  
  try {
    console.log(`Creating SSE connection for job ${jobId}`);
    const eventSource = new EventSource(`${API_BASE_URL}/progress-stream/${jobId}`);
    let errorCount = 0;
    let pingInterval = null;
    
    eventSource.onopen = () => {
      console.log(`SSE connection opened for job ${jobId}`);
      errorCount = 0;
      
      // Keep connection alive
      pingInterval = setInterval(() => {
        if (eventSource.readyState === EventSource.OPEN) {
          console.log('Keeping SSE connection alive');
        } else if (eventSource.readyState === EventSource.CLOSED) {
          clearInterval(pingInterval);
        }
      }, 15000);
    };
    
    eventSource.onmessage = (event) => {
      try {
        const data = JSON.parse(event.data);
        errorCount = 0;
        
        if (data.complete || data.percentage >= 100) {
          if (onComplete) onComplete(data);
          clearInterval(pingInterval);
          eventSource.close();
        } else if (onProgress) {
          onProgress(data);
        }
      } catch (error) {
        console.error('Error parsing SSE data:', error);
        errorCount++;
        
        if (errorCount > 3) {
          clearInterval(pingInterval);
          eventSource.close();
          if (onError) onError(new Error('Too many parsing errors'));
        }
      }
    };
    
    eventSource.onerror = (error) => {
      console.error('SSE connection error:', error);
      errorCount++;
      
      if (errorCount > 3) {
        clearInterval(pingInterval);
        eventSource.close();
        if (onError) onError(new Error('Connection failed after multiple attempts'));
      }
    };
    
    return { 
      eventSource, 
      close: () => { 
        clearInterval(pingInterval); 
        eventSource.close(); 
      } 
    };
  } catch (error) {
    console.error('Error creating SSE connection:', error);
    if (onError) onError(error);
    return null;
  }
};

/**
 * Check the progress of a running job.
 */
export const checkJobProgress = async (jobId) => {
  if (!jobId) return null;
  
  try {
    const response = await enhancedFetch(`${API_BASE_URL}/job-progress/${jobId}`, {
      timeout: 5000
    });
    
    if (!response.ok) return null;
    
    const data = await response.json();
    if (data && data.status === 'success') return data.data;
    
    return null;
  } catch (error) {
    console.error(`Error checking job progress for ${jobId}:`, error);
    return null;
  }
};

/**
 * List all files in the S3 bucket.
 */
export const listAllFiles = async () => {
  try {
    const response = await fetch(`${API_BASE_URL}/list-all-files`);
    
    if (!response.ok) return [];
    
    const data = await response.json();
    if (data && data.status === 'success' && Array.isArray(data.data?.files)) {
      return data.data.files;
    }
    
    return [];
  } catch (error) {
    console.error('Error listing all files:', error);
    return [];
  }
};

/**
 * Download a mixed audio file.
 */
export const downloadAudio = (audioUrl) => {
  if (!audioUrl) return;
  
  const fullUrl = audioUrl.startsWith('http') ? audioUrl : createFullAudioUrl(audioUrl);
  window.open(fullUrl, '_blank');
};

/**
 * Generate a meditation script.
 */
export const generateScript = async (scriptData) => {
  try {
    const response = await enhancedFetch(`${API_BASE_URL}/generate-ssml`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(scriptData),
      timeout: 120000 // 2-minute timeout
    });
    
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`Server error: ${response.status} - ${errorText}`);
    }
    
    const data = await response.json();
    
    if (data.status === 'success') {
      const jobId = data.data?.job_id || data.data?.jobId || null;
      const rawScript = data.data?.raw_script || data.raw_script;
      
      return { 
        success: true,
        script: rawScript, 
        jobId 
      };
    } else {
      throw new Error(data.message || 'Failed to generate script');
    }
  } catch (error) {
    console.error('Script generation failed:', error);
    
    return {
      success: false,
      error: error.message
    };
  }
};

/**
 * Verify if a TTS file exists and get its direct URL
 */
export const verifyTtsFile = async (ttsKey) => {
  if (!ttsKey) return { exists: false };
  
  try {
    // Clean the filename
    let cleanKey = ttsKey;
    if (cleanKey.startsWith('TTS/')) {
      cleanKey = cleanKey.substring(4);
    }
    
    // Try verification endpoint first (preferred approach)
    const verifyUrl = `${API_BASE_URL}/verify-tts/${cleanKey}`;
    
    const response = await enhancedFetch(verifyUrl, {
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      timeout: 5000
    });
    
    if (response.ok) {
      const data = await response.json();
      
      if (data.exists) {
        return {
          exists: true,
          url: data.presigned_url || createFullAudioUrl(`/tts/${cleanKey}`)
        };
      } else {
        return { exists: false };
      }
    }
    
    // Fallback to direct URL
    return { 
      exists: false, 
      fallbackUrl: createFullAudioUrl(`/tts/${cleanKey}`) 
    };
  } catch (error) {
    console.error("Error verifying TTS file:", error);
    
    return { 
      exists: false, 
      error: error.message,
      fallbackUrl: createFullAudioUrl(`/tts/${ttsKey.replace(/^TTS\//, '')}`) 
    };
  }
};

export default {
  fetchMusicLibrary,
  getMusicPreviewUrl,
  createTTS,
  mixAudio,
  generateScript,
  cleanText,
  checkJobProgress,
  createProgressStream,
  getTrackCoverArt,
  getMoodLabelFromTrackName,
  downloadAudio,
  listAllFiles,
  createFullAudioUrl,
  getFallbackMusicLibrary,
  isValidUrl,
  enhancedFetch,
  verifyTtsFile
};