/**
 * Audio URL Utility Functions
 * 
 * This module provides utilities for managing, validating, and extracting
 * audio URLs in the meditation app.
 */

/**
 * Extract mixed audio URL from various response formats.
 * 
 * @param {Object} mixResult - The response from the mixing API
 * @param {string} baseUrl - The base URL of the API
 * @returns {string|null} - The extracted audio URL or null
 */
export const extractMixedAudioUrl = (mixResult, baseUrl) => {
  if (!mixResult) {
    console.warn("extractMixedAudioUrl: No mix result provided");
    return null;
  }
  
  console.log("Extracting URL from mix result:", 
    typeof mixResult === 'object' ? JSON.stringify(mixResult, null, 2) : mixResult);
  
  // Create a list of potential paths to extract URLs
  const urlCandidates = [];
  
  // Case 1: Direct file_path at top level
  if (mixResult.file_path && typeof mixResult.file_path === 'string') {
    urlCandidates.push(mixResult.file_path);
  }
  
  // Case 2: data.file_path structure (most common)
  if (mixResult.data && mixResult.data.file_path && typeof mixResult.data.file_path === 'string') {
    urlCandidates.push(mixResult.data.file_path);
  }
  
  // Case 3: Check common field names in data object
  if (mixResult.data) {
    const urlFields = [
      'url', 'audioUrl', 'audio_url', 'mixed_url', 'mixedUrl', 
      'mp3_url', 'filepath', 'path', 'audio_path'
    ];
    
    for (const field of urlFields) {
      if (mixResult.data[field] && typeof mixResult.data[field] === 'string') {
        urlCandidates.push(mixResult.data[field]);
      }
    }
  }
  
  // Case 4: Look for URLs in other common places
  if (mixResult.url && typeof mixResult.url === 'string') {
    urlCandidates.push(mixResult.url);
  }
  
  if (mixResult.mixedUrl && typeof mixResult.mixedUrl === 'string') {
    urlCandidates.push(mixResult.mixedUrl);
  }
  
  // Find the first valid URL
  let finalUrl = null;
  
  for (const candidate of urlCandidates) {
    if (candidate && typeof candidate === 'string') {
      // Skip if it's not a URL format
      if (!candidate.includes('/')) continue;
      
      // Use as-is if it's already a full URL
      if (candidate.startsWith('http') || candidate.startsWith('blob:')) {
        finalUrl = candidate;
        break;
      }
      // Otherwise, construct a full URL with baseUrl
      else if (baseUrl) {
        finalUrl = `${baseUrl}${candidate.startsWith('/') ? '' : '/'}${candidate}`;
        break;
      }
    }
  }
  
  // Log the result for debugging
  if (finalUrl) {
    console.log("Successfully extracted audio URL:", finalUrl);
  } else {
    console.warn("Failed to extract a valid URL from mix result");
  }
  
  return finalUrl;
};

/**
 * Creates a priority-based URL resolver that consistently
 * returns the best available audio URL based on a hierarchy
 */
export const createUrlResolver = (baseUrl) => {
  const getSilentAudioUrl = () => {
    return `${baseUrl}/resources/silent.mp3`;
  };
  
  const getTtsUrl = (ttsKey) => {
    if (!ttsKey) return null;
    const cleanKey = ttsKey.replace(/^TTS\//, '');
    return `${baseUrl}/tts/${cleanKey}`;
  };
  
  // Get URL with fallbacks in priority order
  const getBestAudioUrl = (options = {}) => {
    const {
      mixedAudioUrl,
      ttsAudioUrl,
      ttsKey,
      currentAudioUrl,
      sessionMixedUrl,
      sessionTtsUrl,
      globalStateMixedUrl,
      globalStateTtsUrl,
      urlPriority = 'mixed'
    } = options;
    
    // First try mixed audio if that's the priority
    if (urlPriority === 'mixed') {
      // Check all possible sources for mixed URL
      if (mixedAudioUrl && typeof mixedAudioUrl === 'string' && 
          (mixedAudioUrl.startsWith('http') || mixedAudioUrl.startsWith('blob:'))) {
        return mixedAudioUrl;
      }
      
      if (sessionMixedUrl && typeof sessionMixedUrl === 'string' && 
          (sessionMixedUrl.startsWith('http') || sessionMixedUrl.startsWith('blob:'))) {
        return sessionMixedUrl;
      }
      
      if (globalStateMixedUrl && typeof globalStateMixedUrl === 'string' && 
          (globalStateMixedUrl.startsWith('http') || globalStateMixedUrl.startsWith('blob:'))) {
        return globalStateMixedUrl;
      }
    }
    
    // Next try TTS audio
    if (ttsAudioUrl && typeof ttsAudioUrl === 'string' && 
        (ttsAudioUrl.startsWith('http') || ttsAudioUrl.startsWith('blob:'))) {
      return ttsAudioUrl;
    }
    
    if (sessionTtsUrl && typeof sessionTtsUrl === 'string' && 
        (sessionTtsUrl.startsWith('http') || sessionTtsUrl.startsWith('blob:'))) {
      return sessionTtsUrl;
    }
    
    if (globalStateTtsUrl && typeof globalStateTtsUrl === 'string' && 
        (globalStateTtsUrl.startsWith('http') || globalStateTtsUrl.startsWith('blob:'))) {
      return globalStateTtsUrl;
    }
    
    // Generate TTS URL from key if needed
    if (ttsKey) {
      return getTtsUrl(ttsKey);
    }
    
    // Use current audio as fallback
    if (currentAudioUrl && typeof currentAudioUrl === 'string' && 
        (currentAudioUrl.startsWith('http') || currentAudioUrl.startsWith('blob:'))) {
      return currentAudioUrl;
    }
    
    // Last resort - silent audio
    return getSilentAudioUrl();
  };
  
  return {
    getBestAudioUrl,
    getSilentAudioUrl,
    getTtsUrl
  };
};

/**
 * Verify that an audio URL is accessible by fetching a small portion
 */
export const verifyAudioUrl = async (url) => {
  if (!url || typeof url !== 'string') {
    return { valid: false, error: 'Invalid URL' };
  }
  
  try {
    // For blob URLs, assume they're valid
    if (url.startsWith('blob:') || url.startsWith('data:')) {
      return { valid: true, status: 200 };
    }
    
    // Use GET instead of HEAD for S3 signed URLs
    // Also use range header to only fetch a small portion of the file
    const response = await fetch(url, {
      method: 'GET',
      headers: { 'Range': 'bytes=0-1' }, // Only get the first byte
      cache: 'no-cache'
    });
    
    return {
      valid: response.ok || response.status === 206, // 206 is Partial Content response
      status: response.status,
      contentType: response.headers.get('content-type')
    };
  } catch (err) {
    return { valid: false, error: err.message };
  }
};

/**
 * Reliably store audio URLs in all available storage mechanisms
 */
export const storeAudioUrl = (url, options = {}) => {
  const {
    key = 'currentAudioUrl',
    globalAudioState = null,
    priority = null
  } = options;
  
  if (!url || typeof url !== 'string') return false;
  
  try {
    // Store in sessionStorage
    sessionStorage.setItem(key, url);
    
    // Store in window global
    window[`__${key}`] = url;
    
    // Store in globalAudioState if available
    if (globalAudioState) {
      if (key === 'mixedAudioUrl' && globalAudioState.completeAudioMixing) {
        globalAudioState.completeAudioMixing(url);
      } else if (key === 'currentAudioUrl' && globalAudioState.setCurrentAudioUrl) {
        globalAudioState.setCurrentAudioUrl(url);
      } else if (globalAudioState.state) {
        globalAudioState.state[key] = url;
        if (priority && typeof priority === 'string') {
          globalAudioState.state.urlPriority = priority;
        }
        if (globalAudioState.saveToSessionStorage) {
          globalAudioState.saveToSessionStorage();
        }
      }
    }
    
    // Set priority if provided
    if (priority && typeof priority === 'string') {
      sessionStorage.setItem('urlPriority', priority);
      window.__urlPriority = priority;
    }
    
    return true;
  } catch (err) {
    console.warn(`Error storing audio URL (${key}):`, err);
    return false;
  }
};

export default {
  extractMixedAudioUrl,
  createUrlResolver,
  verifyAudioUrl,
  storeAudioUrl
};