import React, { useState, useEffect, useCallback, useRef } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';
import ResponsiveWaveSurfer from '../../components/audio/ResponsiveWaveSurfer';
import FullScreenVisualizer from '../../components/audio/FullScreenVisualizer';
import VisualizerLoader from './VisualizerLoader';
import {
  initializeAudio,
  initializeAudioAnalysis,
  syncPlayback,
  syncTimePosition
} from '../audio/audio-sync-fix';
import AudioDiagnosticsTool from '../audio/AudioDiagnosticsTool';
import {
  IoPlayCircle,
  IoPauseCircle,
  IoPlaySkipBack,
  IoPlaySkipForward,
  IoList,
  IoClose,
  IoDownload,
  IoRefresh,
  IoImage,
  IoExpand,
  IoContract,
  IoVolumeHigh,
  IoVolumeOff
} from 'react-icons/io5';

const ListenStep = ({
  audioUrl: initialAudioUrl,
  onExportAudio,
  onReset,
  onError,
  toast,
  selectedMusic = "",
  musicLibrary = [],
  setSelectedMusic = () => {},
  onApplyChanges = () => {},
  baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site',
  isMixingAudio = false,
  onAudioLoaded = null,
  ttsKey = "",
  windowDimensions,
  mixedAudioInfo = null,
  getBestAudioUrl = null,
  centralizeAudioUrl = null,
  setIsMixingAudio = () => {}
}) => {
  const [audioUrl, setAudioUrl] = useState('');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [isLoadingAudio, setIsLoadingAudio] = useState(true);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [showWaveSurferError, setShowWaveSurferError] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [useVisualizer, setUseVisualizer] = useState(true);
  const [visualizerSize, setVisualizerSize] = useState(300);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth || 0);
  const [windowHeight, setWindowHeight] = useState(window.innerHeight || 0);
  const [zoom, setZoom] = useState(50);
  const [volume, setVolume] = useState(1.0);
  const [isMuted, setIsMuted] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [initComplete, setInitComplete] = useState(false);
  const [failedLoadAttempts, setFailedLoadAttempts] = useState(0);
  const [loadingTimedOut, setLoadingTimedOut] = useState(false);
  const [hasMixedAudio, setHasMixedAudio] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState('Loading your meditation audio...');
  const [mixReady, setMixReady] = useState(false);
  const [isBackgroundTTSProcessing, setIsBackgroundTTSProcessing] = useState(false);
  const [backgroundTTSProgress, setBackgroundTTSProgress] = useState(0);
  const [globalStateInitialized, setGlobalStateInitialized] = useState(false);
  const [isUsingGlobalAudio, setIsUsingGlobalAudio] = useState(false);
  const [analyzerNode, setAnalyzerNode] = useState(null);
  const [componentError, setComponentError] = useState(null);
  const [componentReady, setComponentReady] = useState(false);
  const [wavesurferInitialized, setWavesurferInitialized] = useState(false);
  const [renderedWaveform, setRenderedWaveform] = useState(null);

  const mounted = useRef(true);
  const isMountedRef = useRef(true);
  const audioElementRef = useRef(null);
  const waveformContainerRef = useRef(null);
  const timelineContainerRef = useRef(null);
  const mobileWaveformContainerRef = useRef(null);
  const waveSurferRef = useRef(null);
  const audioContextRef = useRef(null);
  const analyzerRef = useRef(null);
  const audioInitTimeoutRef = useRef(null);
  const safetyTimeoutsRef = useRef([]);
  const audioLoadTimeoutRef = useRef(null);
  const audioUrlRef = useRef(initialAudioUrl || '');
  const blobUrlsRef = useRef([]);
  const globalAudioRef = useRef(window.globalAudioState || null);
  const initializeWaveSurferRef = useRef(null);
  const lastPlayingStateRef = useRef(false);
  const initializeAudioAnalysisRef = useRef(null);
  const setupAudioElementListenersRef = useRef(null);

  const isMobile = windowWidth <= 768;
  const isFullscreenMobile = isFullscreen && isMobile;

  const safeToast = useCallback((type, message, options = {}) => {
    if (!mounted.current) return null;
    try {
      if (globalAudioRef.current) {
        globalAudioRef.current.publishEvent('toast', { type, message, options, source: 'ListenStep', timestamp: Date.now() });
      }
      if (toast && typeof toast[type] === 'function') {
        return toast[type](message, options);
      } else if (typeof toast === 'function') {
        return toast(message, { ...options, type });
      }
    } catch (err) {
      console.log(`Error showing toast: ${err.message}`);
    }
    console.log(`Toast (${type}): ${message}`);
    return null;
  }, [toast]);

  const isValidUrl = useCallback((url) => {
    return !!(url && typeof url === 'string' &&
      (url.startsWith('http://') || url.startsWith('https://') || url.startsWith('blob:') || url.startsWith('data:')));
  }, []);

  const createFallbackAudioBlob = useCallback(() => {
    console.log("AUDIO PLAYER: Creating silent audio blob for fallback");
    try {
      const arrayBuffer = new ArrayBuffer(10000);
      const view = new Uint8Array(arrayBuffer);
      const silentHeader = [0xFF, 0xFB, 0x90, 0x44, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      for (let i = 0; i < arrayBuffer.byteLength; i += 16) {
        silentHeader.forEach((value, index) => {
          if (i + index < arrayBuffer.byteLength) view[i + index] = value;
        });
      }
      const blob = new Blob([arrayBuffer], { type: 'audio/mpeg' });
      return URL.createObjectURL(blob);
    } catch (err) {
      console.error("AUDIO PLAYER: Error creating silent audio blob:", err);
      return "data:audio/mpeg;base64,SUQzBAAAAAAAI1RTU0UAAAAP...";
    }
  }, []);

  const createFallbackAudioElement = useCallback((url) => {
    if (!url) {
      console.warn("AUDIO PLAYER: No URL provided for fallback audio");
      return null;
    }
    console.log("AUDIO PLAYER: Creating fallback audio element with URL:", url);
    try {
      let audioEl = audioElementRef.current;
      if (globalAudioRef.current && globalAudioRef.current._audioElement) {
        audioEl = globalAudioRef.current._audioElement;
        audioElementRef.current = audioEl;
        setIsUsingGlobalAudio(true);
      } else if (!audioEl) {
        audioEl = new Audio();
        audioElementRef.current = audioEl;
        if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
          globalAudioRef.current._audioElement = audioEl;
        }
      }
      audioEl.crossOrigin = "anonymous";
      audioEl.preload = "auto";
      audioEl.src = url;
      if (!audioEl._listenersInitialized && setupAudioElementListenersRef.current) {
        setupAudioElementListenersRef.current(audioEl);
        audioEl._listenersInitialized = true;
      }
      audioEl.load();
      audioEl.volume = volume;
      audioEl.muted = isMuted;
      if (url.startsWith('blob:')) {
        blobUrlsRef.current.push(url);
      }
      return audioEl;
    } catch (err) {
      console.error("AUDIO PLAYER: Error creating fallback audio element:", err);
      safeToast('error', 'Audio player initialization failed');
      return null;
    }
  }, [volume, isMuted, safeToast]);

  const setupAudioElementListeners = useCallback((audioEl) => {
    if (!audioEl) return;
    try {
      let metadataLoaded = false;
      let canPlayThrough = false;
      audioEl.onloadedmetadata = () => {
        if (mounted.current && !metadataLoaded) {
          console.log("AUDIO PLAYER: Audio loaded metadata successfully", audioEl.duration);
          metadataLoaded = true;
          setDuration(audioEl.duration || 0);
          if (canPlayThrough) {
            setIsLoadingAudio(false);
            setAudioLoaded(true);
            setIsInitialized(true);
            if (onAudioLoaded) {
              onAudioLoaded({ url: audioEl.src, duration: audioEl.duration });
            }
          }
          if (audioLoadTimeoutRef.current) {
            clearTimeout(audioLoadTimeoutRef.current);
            audioLoadTimeoutRef.current = null;
          }
        }
      };
      audioEl.oncanplaythrough = () => {
        if (mounted.current && !canPlayThrough) {
          console.log("AUDIO PLAYER: Audio can play through");
          canPlayThrough = true;
          setIsLoadingAudio(false);
          setAudioLoaded(true);
          if (metadataLoaded) {
            setIsInitialized(true);
            if (onAudioLoaded) {
              onAudioLoaded({ url: audioEl.src, duration: audioEl.duration });
            }
            if (initializeAudioAnalysisRef.current) {
              initializeAudioAnalysisRef.current();
            }
          }
          if (audioLoadTimeoutRef.current) {
            clearTimeout(audioLoadTimeoutRef.current);
            audioLoadTimeoutRef.current = null;
          }
        }
      };
      audioEl.addEventListener('timeupdate', () => {
        if (mounted.current) {
          const newTime = audioEl.currentTime || 0;
          setCurrentTime(newTime);
          if (waveSurferRef.current && Math.abs(waveSurferRef.current.getCurrentTime() - newTime) > 0.5) {
            try {
              const dur = waveSurferRef.current.getDuration() || 1;
              waveSurferRef.current.seekTo(newTime / dur);
            } catch (err) {
              console.warn("Error syncing WaveSurfer:", err);
            }
          }
        }
      });
      audioEl.addEventListener('play', () => {
        if (mounted.current) {
          console.log("AUDIO PLAYER: Audio element play event");
          setIsPlaying(true);
          lastPlayingStateRef.current = true;
          if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
            audioContextRef.current.resume().catch(err => {
              console.warn("Error resuming AudioContext:", err);
            });
          }
          if (globalAudioRef.current) {
            globalAudioRef.current.state.audioPlaying = true;
            try { sessionStorage.setItem('audioPlaying', 'true'); } catch (e) {}
          }
        }
      });
      audioEl.addEventListener('pause', () => {
        if (mounted.current) {
          console.log("AUDIO PLAYER: Audio element pause event");
          setIsPlaying(false);
          lastPlayingStateRef.current = false;
          if (globalAudioRef.current) {
            globalAudioRef.current.state.audioPlaying = false;
            try { sessionStorage.setItem('audioPlaying', 'false'); } catch (e) {}
          }
        }
      });
      audioEl.addEventListener('ended', () => {
        if (mounted.current) {
          console.log("AUDIO PLAYER: Audio element ended event");
          setIsPlaying(false);
          lastPlayingStateRef.current = false;
          setCurrentTime(0);
          audioEl.currentTime = 0;
          if (globalAudioRef.current) {
            globalAudioRef.current.state.audioPlaying = false;
            try { sessionStorage.setItem('audioPlaying', 'false'); } catch (e) {}
          }
        }
      });
      audioEl.addEventListener('error', (err) => {
        console.error("AUDIO PLAYER: Audio element error:", err, audioEl.error);
        if (mounted.current) {
          safeToast('error', 'Audio playback error. Trying alternative method.');
          setAudioLoaded(true);
          setIsLoadingAudio(false);
          if (globalAudioRef.current && typeof globalAudioRef.current.getBestAudioUrl === 'function') {
            const fallbackUrl = globalAudioRef.current.getBestAudioUrl();
            if (fallbackUrl && fallbackUrl !== audioEl.src) {
              console.log("AUDIO PLAYER: Trying fallback URL:", fallbackUrl);
              audioEl.src = fallbackUrl;
              audioEl.load();
            }
          }
        }
      });
      audioEl._listenersInitialized = true;
    } catch (err) {
      console.error('Error setting up audio element listeners:', err);
    }
  }, [onAudioLoaded, safeToast]);

  const getValidAudioUrl = useCallback(() => {
    const sources = [
      initialAudioUrl,
      audioUrl,
      globalAudioRef.current?.getBestAudioUrl(),
      audioUrlRef.current
    ];
    for (const url of sources) {
      if (url && isValidUrl(url)) {
        return url;
      }
    }
    console.warn("AUDIO PLAYER: No valid URL found, creating silent audio");
    return createFallbackAudioBlob();
  }, [initialAudioUrl, audioUrl, isValidUrl, createFallbackAudioBlob]);

  const safeLoadAudio = useCallback((url, wavesurfer) => {
    if (!url || !wavesurfer || !isValidUrl(url)) return;
    if (window.__prevAbortController) {
      try {
        window.__prevAbortController.abort();
      } catch (e) {
        console.warn("Error aborting previous load:", e);
      }
    }
    const controller = new AbortController();
    window.__prevAbortController = controller;
    wavesurfer._abortController = controller;
    console.log("AUDIO PLAYER: Loading URL safely:", url);
    try {
      wavesurfer.pause();
      wavesurfer.load(url);
      const timeout = setTimeout(() => {
        if (controller.signal && !controller.signal.aborted) {
          console.warn("AUDIO PLAYER: Load timed out, continuing");
          controller.abort();
        }
      }, 30000);
      wavesurfer._timeouts = wavesurfer._timeouts || [];
      wavesurfer._timeouts.push(timeout);
    } catch (err) {
      console.error("AUDIO PLAYER: Error during safe load:", err);
    }
  }, [isValidUrl]);

  const initializeWaveSurfer = useCallback(() => {
    // Check if we already have a valid instance
    if (waveSurferRef.current && typeof waveSurferRef.current.destroy === 'function') {
      return waveSurferRef.current;
    }

    // Make sure necessary dependencies are available
    if (!WaveSurfer) {
      console.error("WaveSurfer library is not available");
      setShowWaveSurferError(true);
      return null;
    }

    try {
      const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
      if (!container) {
        console.error("Container ref not available");
        return null;
      }

      // Force container to be properly sized and visible
      container.style.setProperty('display', 'block', 'important');
      container.style.setProperty('visibility', 'visible', 'important');
      container.style.setProperty('width', '100%', 'important');
      container.style.setProperty('min-width', '300px', 'important');
      container.style.setProperty('height', isMobile ? '60px' : '80px', 'important');
      container.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
      
      // Force layout recalculation
      void container.offsetHeight;
      void container.offsetWidth;
      
      // Get dimensions after forcing layout
      const rect = container.getBoundingClientRect();
      if (rect.width < 100 || rect.height < 30) {
        console.warn("Container dimensions too small:", rect.width, "x", rect.height);
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
        return null;
      }

      // Ensure container has ID for tracking
      if (!container.id) {
        container.id = `waveform-container-${Date.now()}`;
      }

      // Check if we already have a WaveSurfer instance for this container
      if (window._activeSurfers && window._activeSurfers[container.id]) {
        console.log("Found existing WaveSurfer instance for container, reusing");
        return window._activeSurfers[container.id];
      }

      // Set up plugins with error handling
      const plugins = [];
      
      try {
        if (!isFullscreenMobile && timelineContainerRef.current && TimelinePlugin) {
          const timelineContainer = timelineContainerRef.current;
          timelineContainer.style.setProperty('min-width', '100px', 'important');
          timelineContainer.style.setProperty('min-height', '30px', 'important');
          timelineContainer.style.setProperty('width', '100%', 'important');
          timelineContainer.style.setProperty('display', 'block', 'important');
          
          const timelinePlugin = TimelinePlugin.create({
            container: timelineContainer,
            primaryLabelInterval: 10,
            secondaryLabelInterval: 5,
            primaryColor: 'rgba(255,255,255,0.8)',
            secondaryColor: 'rgba(255,255,255,0.4)',
            primaryFontColor: 'rgba(255,255,255,0.8)',
            secondaryFontColor: 'rgba(255,255,255,0.4)',
            fontFamily: 'Arial',
            fontSize: 12
          });
          plugins.push(timelinePlugin);
        }
      } catch (timelineErr) {
        console.warn("Error creating timeline plugin:", timelineErr);
      }
      
      try {
        if (RegionsPlugin) {
          const regionsPlugin = RegionsPlugin.create({});
          plugins.push(regionsPlugin);
        }
      } catch (regionsErr) {
        console.warn("Error creating regions plugin:", regionsErr);
      }

      // Get shared audio element if available
      const sharedAudioElement = globalAudioRef.current && globalAudioRef.current._audioElement;

      // Create WaveSurfer with the proper configuration
      const wsOptions = {
        container: container,
        waveColor: 'rgba(125, 18, 255, 0.3)',
        progressColor: '#7d12ff',
        cursorColor: '#ffffff',
        barWidth: 2,
        barRadius: 3,
        cursorWidth: 1,
        height: isMobile ? 60 : 80,
        barGap: 2,
        responsive: true,
        normalize: true,
        pixelRatio: 1, // Stable value instead of devicePixelRatio
        scrollParent: true,
        minPxPerSec: zoom,
        hideScrollbar: false,
        forceDecode: true,
        plugins: plugins
      };

      // Use MediaElement backend with shared audio if available
      if (sharedAudioElement) {
        wsOptions.backend = 'MediaElement';
        wsOptions.media = sharedAudioElement;
      }

      // Create instance
      const wsInstance = WaveSurfer.create(wsOptions);
      
      // Store in tracking systems
      container._wavesurfer = wsInstance;
      if (!window._activeSurfers) window._activeSurfers = {};
      window._activeSurfers[container.id] = wsInstance;
      
      // Set up event listeners
      wsInstance.on('ready', () => {
        if (!isMountedRef.current) return;
        
        setAudioLoaded(true);
        setIsLoadingAudio(false);
        
        try {
          const duration = wsInstance.getDuration();
          if (duration && !isNaN(duration) && isFinite(duration)) {
            setDuration(duration);
            
            if (onAudioLoaded) {
              onAudioLoaded({ 
                url: audioUrl, 
                duration: duration, 
                wavesurferReady: true 
              });
            }
          }
        } catch (err) {
          console.warn("Error getting duration:", err);
        }
        
        // Ensure audio analysis is initialized
        if (!analyzerRef.current && initializeAudioAnalysisRef.current) {
          initializeAudioAnalysisRef.current();
        }
      });
      
      // Add error handling
      wsInstance.on('error', (err) => {
        console.error("WaveSurfer error:", err);
        
        if (!isMountedRef.current) return;
        
        // Skip AbortError - these are normal during cleanup
        const isAbortError = err && (
          err.name === 'AbortError' || 
          err.toString().includes('abort') ||
          err.message?.includes('abort')
        );
        
        if (isAbortError) {
          console.log("AbortError detected - normal during cleanup");
          return;
        }
        
        setIsLoadingAudio(false);
        setShowWaveSurferError(true);
        safeToast('error', 'Using simplified audio player for better compatibility');
        
        // Create fallback audio element
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
        }
      });

      // Sync events between WaveSurfer and audio element
      wsInstance.on('play', () => {
        if (!isMountedRef.current) return;
        console.log("AUDIO PLAYER: WaveSurfer play event");
        setIsPlaying(true);
        lastPlayingStateRef.current = true;
        
        if (audioElementRef.current && audioElementRef.current.paused) {
          try {
            audioElementRef.current.play().catch(err => {
              console.warn("Error starting audio element playback:", err);
            });
          } catch (err) {
            console.warn("Error starting audio element playback:", err);
          }
        }
        
        // Resume AudioContext if suspended (needed for visualizer)
        if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
          try {
            audioContextRef.current.resume().catch(err => {
              console.warn("Error resuming AudioContext:", err);
            });
          } catch (err) {
            console.warn("Error resuming AudioContext:", err);
          }
        }

        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = true;
          try { sessionStorage.setItem('audioPlaying', 'true'); } catch (e) {}
        }
      });
      
      wsInstance.on('pause', () => {
        if (!isMountedRef.current) return;
        console.log("AUDIO PLAYER: WaveSurfer pause event");
        setIsPlaying(false);
        lastPlayingStateRef.current = false;
        
        if (audioElementRef.current && !audioElementRef.current.paused) {
          try {
            audioElementRef.current.pause();
          } catch (err) {
            console.warn("Error pausing audio element:", err);
          }
        }

        if (globalAudioRef.current) {
          globalAudioRef.current.state.audioPlaying = false;
          try { sessionStorage.setItem('audioPlaying', 'false'); } catch (e) {}
        }
      });
      
      wsInstance.on('seeking', (progress) => {
        if (!isMountedRef.current) return;
        const newTime = progress * wsInstance.getDuration();
        setCurrentTime(newTime);
        
        if (audioElementRef.current) {
          try {
            audioElementRef.current.currentTime = newTime;
          } catch (err) {
            console.warn("Error syncing audio element time:", err);
          }
        }
      });
      
      return wsInstance;
    } catch (err) {
      console.error("Error initializing WaveSurfer:", err);
      
      if (isMountedRef.current) {
        setShowWaveSurferError(true);
        safeToast('error', 'Using simplified audio player for better compatibility');
        
        // Fallback
        if (audioUrl && isValidUrl(audioUrl)) {
          createFallbackAudioElement(audioUrl);
          setAudioLoaded(true);
          setIsLoadingAudio(false);
        }
      }
      
      return null;
    }
  }, [isMobile, isFullscreenMobile, zoom, audioUrl, isValidUrl, createFallbackAudioElement, 
      safeToast, isMountedRef, onAudioLoaded]);


      useEffect(() => {
        initializeWaveSurferRef.current = initializeWaveSurfer;
        // Use the imported function from audio-sync-fix.js
        initializeAudioAnalysisRef.current = () => initializeAudioAnalysis({
          audioElement: audioElementRef.current,
          audioContextRef,
          analyzerRef,
          mounted: isMountedRef,
          isUsingGlobalAudio,
          globalAudioRef,
          setAnalyzerNode
        });
      }, [initializeWaveSurfer, audioElementRef, audioContextRef, analyzerRef, 
          isMountedRef, isUsingGlobalAudio, globalAudioRef, setAnalyzerNode]);

  const togglePlayback = useCallback(() => {
    const success = syncPlayback({
      isPlaying: !isPlaying,
      audioElement: audioElementRef.current,
      waveSurfer: waveSurferRef.current,
      globalAudioState: globalAudioRef.current
    });
    if (!success) {
      safeToast('error', 'Audio player not ready. Please try again.');
    }
  }, [isPlaying, safeToast]);

  const handleSkip = useCallback((seconds) => {
    if (isUsingGlobalAudio && globalAudioRef.current && globalAudioRef.current._audioElement) {
      const sharedAudio = globalAudioRef.current._audioElement;
      try {
        const newTime = Math.max(0, Math.min(sharedAudio.duration, sharedAudio.currentTime + seconds));
        sharedAudio.currentTime = newTime;
      } catch (err) {
        console.error("AUDIO PLAYER: Error skipping with shared audio element:", err);
      }
      return;
    }
    if (waveSurferRef.current && audioLoaded) {
      try {
        const current = waveSurferRef.current.getCurrentTime() || 0;
        const total = waveSurferRef.current.getDuration() || 0;
        if (isNaN(current) || isNaN(total) || total <= 0) return;
        const newTime = Math.max(0, Math.min(total, current + seconds));
        waveSurferRef.current.seekTo(newTime / total);
        if (waveSurferRef.current.drawer && typeof waveSurferRef.current.drawer.recenter === 'function') {
          waveSurferRef.current.drawer.recenter(newTime / total);
        }
      } catch (error) {
        console.error("AUDIO PLAYER: Error during WaveSurfer skip:", error);
        if (audioElementRef.current) {
          try {
            const newTime = Math.max(0, Math.min(audioElementRef.current.duration, audioElementRef.current.currentTime + seconds));
            audioElementRef.current.currentTime = newTime;
          } catch (err) {
            console.error("AUDIO PLAYER: Error skipping with audio element:", err);
          }
        }
      }
    } else if (audioElementRef.current) {
      try {
        const newTime = Math.max(0, Math.min(audioElementRef.current.duration, audioElementRef.current.currentTime + seconds));
        audioElementRef.current.currentTime = newTime;
      } catch (err) {
        console.error("AUDIO PLAYER: Error skipping with audio element:", err);
      }
    }
  }, [audioLoaded, isUsingGlobalAudio, globalAudioRef]);

  const handleScrubberClick = useCallback((e) => {
    if (isUsingGlobalAudio && globalAudioRef.current && globalAudioRef.current._audioElement) {
      const sharedAudio = globalAudioRef.current._audioElement;
      try {
        const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
        if (!container) return;
        const rect = container.getBoundingClientRect();
        const pos = (e.clientX - rect.left) / rect.width;
        if (pos >= 0 && pos <= 1 && sharedAudio.duration) {
          const newTime = pos * sharedAudio.duration;
          sharedAudio.currentTime = newTime;
        }
      } catch (err) {
        console.error("AUDIO PLAYER: Error seeking with shared audio element:", err);
      }
      return;
    }
    if (waveSurferRef.current && audioLoaded) {
      try {
        const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
        if (!container) return;
        const rect = container.getBoundingClientRect();
        const pos = (e.clientX - rect.left) / rect.width;
        if (pos >= 0 && pos <= 1) {
          waveSurferRef.current.seekTo(pos);
          if (waveSurferRef.current.drawer && typeof waveSurferRef.current.drawer.recenter === 'function') {
            waveSurferRef.current.drawer.recenter(pos);
          }
        }
      } catch (error) {
        console.error("AUDIO PLAYER: Error during WaveSurfer seek:", error);
      }
    } else if (audioElementRef.current) {
      try {
        const container = isFullscreenMobile ? mobileWaveformContainerRef.current : waveformContainerRef.current;
        if (!container) return;
        const rect = container.getBoundingClientRect();
        const pos = (e.clientX - rect.left) / rect.width;
        if (pos >= 0 && pos <= 1) {
          const newTime = pos * audioElementRef.current.duration;
          audioElementRef.current.currentTime = newTime;
        }
      } catch (err) {
        console.error("AUDIO PLAYER: Error seeking with audio element:", err);
      }
    }
  }, [audioLoaded, isFullscreenMobile, isUsingGlobalAudio, globalAudioRef]);

  const handleZoomChange = useCallback((zoomLevel) => {
    setZoom(zoomLevel);
    if (waveSurferRef.current) {
      try {
        const currentTime = waveSurferRef.current.getCurrentTime();
        waveSurferRef.current.zoom(zoomLevel);
        if (isPlaying) {
          const container = waveSurferRef.current.container;
          if (container) {
            const width = container.clientWidth;
            const dur = waveSurferRef.current.getDuration() || 1;
            const pixelsPerSecond = width / dur;
            const scrollLeft = Math.max(0, (currentTime * pixelsPerSecond) - (width / 2));
            if (container.scrollLeft !== undefined) {
              container.scrollLeft = scrollLeft;
            }
          }
          if (waveSurferRef.current.drawer && typeof waveSurferRef.current.drawer.recenter === 'function') {
            const dur = waveSurferRef.current.getDuration() || 1;
            waveSurferRef.current.drawer.recenter(currentTime / dur);
          }
        }
      } catch (error) {
        console.error("AUDIO PLAYER: Error changing zoom level:", error);
      }
    }
  }, [isPlaying]);

  const handleVolumeChange = useCallback((newVolume) => {
    setVolume(newVolume);
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.setVolume(newVolume);
      } catch (error) {
        console.error("AUDIO PLAYER: Error changing WaveSurfer volume:", error);
      }
    }
    if (audioElementRef.current) {
      try {
        audioElementRef.current.volume = newVolume;
      } catch (error) {
        console.error("AUDIO PLAYER: Error changing audio element volume:", error);
      }
    }
  }, []);

  const toggleMute = useCallback(() => {
    const newMuteState = !isMuted;
    setIsMuted(newMuteState);
    if (waveSurferRef.current) {
      try {
        waveSurferRef.current.setMute(newMuteState);
      } catch (error) {
        console.error("AUDIO PLAYER: Error toggling WaveSurfer mute:", error);
      }
    }
    if (audioElementRef.current) {
      try {
        audioElementRef.current.muted = newMuteState;
      } catch (error) {
        console.error("AUDIO PLAYER: Error toggling audio element mute:", error);
      }
    }
  }, [isMuted]);

  const toggleFullscreen = useCallback(() => {
    setIsFullscreen(prev => !prev);
  }, []);

  const toggleVisualizer = useCallback(() => {
    setUseVisualizer(prev => !prev);
  }, []);

  const togglePlaylist = useCallback(() => {
    setShowPlaylist(prev => !prev);
  }, []);

  const handleMusicSelect = useCallback((trackName) => {
    setSelectedMusic(trackName);
    setShowPlaylist(false);
    if (globalAudioRef.current) {
      globalAudioRef.current.publishEvent('music-selected', { trackName, timestamp: Date.now() });
    }
    if (typeof onApplyChanges === 'function') {
      try {
        setIsLoadingAudio(true);
        onApplyChanges().catch(err => {
          console.error("AUDIO PLAYER: Error in onApplyChanges:", err);
          safeToast('error', 'Failed to change music track');
          setIsLoadingAudio(false);
        });
      } catch (err) {
        console.error("AUDIO PLAYER: Error in handleMusicSelect:", err);
        safeToast('error', 'Failed to select music track');
        setIsLoadingAudio(false);
      }
    }
  }, [setSelectedMusic, onApplyChanges, safeToast]);

  const formatTime = useCallback((timeInSeconds) => {
    if (isNaN(timeInSeconds) || timeInSeconds < 0) return '0:00';
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }, []);

  const getTrackInfo = useCallback(() => {
    try {
      if (!selectedMusic || !Array.isArray(musicLibrary) || musicLibrary.length === 0) {
        return { title: 'Your Meditation', artist: 'Pause' };
      }
      const track = musicLibrary.find(t => t && typeof t === 'object' && t.name === selectedMusic);
      if (track) {
        return {
          title: track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
          artist: 'Pause Music'
        };
      } else {
        return {
          title: selectedMusic ? selectedMusic.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()) : 'Your Meditation',
          artist: 'Pause'
        };
      }
    } catch (err) {
      console.error("AUDIO PLAYER: Error in getTrackInfo:", err);
      return { title: 'Meditation', artist: 'Pause' };
    }
  }, [selectedMusic, musicLibrary]);

  const getAlbumArt = useCallback((trackName = selectedMusic) => {
    const coverArtOptions = [
      'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=600&h=600&fit=crop&auto=format',
      'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=600&h=600&fit=crop&auto=format'
    ];
    if (!trackName) return coverArtOptions[0];
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;
      for (let i = 0; i < cleanName.length; i++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(i);
        hash |= 0;
      }
      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, [selectedMusic]);

  const getMoodLabel = useCallback((trackName = selectedMusic) => {
    if (!trackName) return 'Meditation Music';
    try {
      const cleanTrackName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      const lowerName = cleanTrackName.toLowerCase();
      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];
      for (const mood of moodMap) {
        if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
          return mood.label;
        }
      }
      return cleanTrackName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, [selectedMusic]);

  const renderVisualizer = useCallback(() => {
    if (!useVisualizer) {
      return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#02073c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '60%', height: '60%', borderRadius: '50%', background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', opacity: isPlaying ? 0.8 : 0.5, transition: 'all 0.3s ease', animation: isPlaying ? 'pulse 3s infinite alternate' : 'none' }}></div>
          <style jsx>{`
            @keyframes pulse {
              0% { transform: scale(0.95); opacity: 0.7; }
              100% { transform: scale(1.05); opacity: 0.9; }
            }
          `}</style>
        </div>
      );
    }
    try {
      const currentAnalyzer = analyzerNode || analyzerRef.current;
      if (typeof VisualizerLoader === 'function') {
        return (
          <VisualizerLoader
            analyzerNode={currentAnalyzer}
            isPlaying={isPlaying}
            size={visualizerSize}
            audioUrl={audioUrl}
            fallbackOnly={!currentAnalyzer}
          />
        );
      }
      return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#02073c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '70%', height: '70%', borderRadius: '50%', background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', opacity: isPlaying ? 0.8 : 0.5, transition: 'all 0.3s ease', animation: isPlaying ? 'pulse 3s infinite alternate' : 'none' }}></div>
          <style jsx>{`
            @keyframes pulse {
              0% { transform: scale(0.95); opacity: 0.7; }
              100% { transform: scale(1.05); opacity: 0.9; }
            }
          `}</style>
        </div>
      );
    } catch (error) {
      console.error("Error rendering visualizer:", error);
      return (
        <div style={{ width: '100%', height: '100%', backgroundColor: '#02073c', borderRadius: '50%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div style={{ width: '60%', height: '60%', borderRadius: '50%', background: 'radial-gradient(circle, #7d12ff 0%, #5636f3 70%, #02073c 100%)', opacity: 0.6, transition: 'all 0.3s ease' }}></div>
        </div>
      );
    }
  }, [analyzerNode, analyzerRef, isPlaying, useVisualizer, visualizerSize, audioUrl]);

// Enhanced waveformContainerRefCallback with better container validation
// and initialization sequence. This fixes common WaveSurfer initialization issues.

const waveformContainerRefCallback = useCallback((node) => {
  if (!node) {
    console.log("AUDIO PLAYER: Container node is null, skipping initialization");
    return;
  }
  
  if (wavesurferInitialized) {
    console.log("AUDIO PLAYER: WaveSurfer already initialized, skipping");
    return;
  }
  
  console.log("AUDIO PLAYER: Waveform container reference set:", node);
  
  // Store the reference in the appropriate container based on view mode
  if (isFullscreenMobile) {
    mobileWaveformContainerRef.current = node;
  } else {
    waveformContainerRef.current = node;
  }
  
  // Ensure node has an ID for tracking
  if (!node.id) {
    node.id = `waveform-container-${Date.now()}`;
  }
  
  // Force important styling to ensure visibility and dimensions
  node.style.setProperty('display', 'block', 'important');
  node.style.setProperty('visibility', 'visible', 'important');
  node.style.setProperty('width', '100%', 'important');
  node.style.setProperty('min-width', '300px', 'important');
  node.style.setProperty('height', isMobile ? '60px' : '80px', 'important');
  node.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
  
  // Force layout recalculation
  void node.offsetHeight;
  void node.offsetWidth;
  
  // Check if this node already has a WaveSurfer instance
  if (node._wavesurfer) {
    console.log("AUDIO PLAYER: Container already has a WaveSurfer instance");
    waveSurferRef.current = node._wavesurfer;
    setWavesurferInitialized(true);
    if (audioUrl) {
      safeLoadAudio(audioUrl, node._wavesurfer);
    }
    return;
  }
  
  // Use requestAnimationFrame to ensure DOM is fully rendered before measuring
  requestAnimationFrame(() => {
    if (!mounted.current) return;
    
    const rect = node.getBoundingClientRect();
    console.log(`AUDIO PLAYER: Container dimensions: ${rect.width}x${rect.height}`);
    
    // Check if dimensions are sufficient for initialization
    if (rect.width > 100 && rect.height > 30 && !wavesurferInitialized) {
      if (!waveSurferRef.current && audioUrl && initializeWaveSurferRef.current) {
        console.log("AUDIO PLAYER: Initializing WaveSurfer");
        
        try {
          // Initialize WaveSurfer
          const newWaveSurfer = initializeWaveSurferRef.current();
          
          if (newWaveSurfer) {
            // Store the instance
            waveSurferRef.current = newWaveSurfer;
            
            // Store reference on the node for future lookup
            node._wavesurfer = newWaveSurfer;
            
            // Load audio
            safeLoadAudio(audioUrl, newWaveSurfer);
            
            // Update state
            setWavesurferInitialized(true);
            
            console.log("AUDIO PLAYER: WaveSurfer successfully initialized and audio loaded");
          } else {
            console.warn("AUDIO PLAYER: WaveSurfer initialization returned null");
            
            // Try again after a short delay as a fallback
            setTimeout(() => {
              if (mounted.current && !wavesurferInitialized && initializeWaveSurferRef.current) {
                const retryWaveSurfer = initializeWaveSurferRef.current();
                if (retryWaveSurfer) {
                  waveSurferRef.current = retryWaveSurfer;
                  node._wavesurfer = retryWaveSurfer;
                  safeLoadAudio(audioUrl, retryWaveSurfer);
                  setWavesurferInitialized(true);
                  console.log("AUDIO PLAYER: WaveSurfer initialized on retry");
                } else {
                  console.error("AUDIO PLAYER: WaveSurfer retry initialization failed");
                  // Create fallback audio element
                  if (isValidUrl(audioUrl)) {
                    createFallbackAudioElement(audioUrl);
                    setAudioLoaded(true);
                    setIsLoadingAudio(false);
                  }
                }
              }
            }, 500);
          }
        } catch (error) {
          console.error("AUDIO PLAYER: Error during WaveSurfer initialization:", error);
          
          // Create fallback audio element
          if (isValidUrl(audioUrl)) {
            createFallbackAudioElement(audioUrl);
            setAudioLoaded(true);
            setIsLoadingAudio(false);
          }
        }
      } else if (waveSurferRef.current) {
        console.log("AUDIO PLAYER: WaveSurfer already exists, reusing");
        node._wavesurfer = waveSurferRef.current;
        setWavesurferInitialized(true);
      } else {
        console.warn("AUDIO PLAYER: Cannot initialize WaveSurfer - missing dependencies");
      }
    } else {
      console.warn(`AUDIO PLAYER: Container dimensions still insufficient (${rect.width}x${rect.height}), trying alternative approach`);
      
      // Apply even more aggressive styling
      node.style.setProperty('position', 'relative', 'important');
      node.style.setProperty('display', 'block', 'important');
      node.style.setProperty('visibility', 'visible', 'important');
      node.style.setProperty('opacity', '1', 'important');
      node.style.setProperty('width', '100%', 'important');
      node.style.setProperty('min-width', '300px', 'important');
      node.style.setProperty('height', isMobile ? '60px' : '80px', 'important'); 
      node.style.setProperty('min-height', isMobile ? '60px' : '80px', 'important');
      
      // Force layout recalculation again
      void node.offsetHeight;
      void node.offsetWidth;
      
      // Try one more time after a delay
      setTimeout(() => {
        if (!mounted.current) return;
        
        const newRect = node.getBoundingClientRect();
        console.log(`AUDIO PLAYER: Container dimensions after fix: ${newRect.width}x${newRect.height}`);
        
        if (newRect.width > 100 && newRect.height > 30 && !wavesurferInitialized && initializeWaveSurferRef.current) {
          console.log("AUDIO PLAYER: Trying WaveSurfer initialization one more time");
          const lastChanceWaveSurfer = initializeWaveSurferRef.current();
          
          if (lastChanceWaveSurfer) {
            waveSurferRef.current = lastChanceWaveSurfer;
            node._wavesurfer = lastChanceWaveSurfer;
            safeLoadAudio(audioUrl, lastChanceWaveSurfer);
            setWavesurferInitialized(true);
          } else {
            console.error("AUDIO PLAYER: Last chance WaveSurfer initialization failed");
            // Fallback
            if (isValidUrl(audioUrl)) {
              createFallbackAudioElement(audioUrl);
              setAudioLoaded(true);
              setIsLoadingAudio(false);
            }
          }
        } else if (!wavesurferInitialized) {
          console.error("AUDIO PLAYER: Container still not valid, using fallback audio");
          // Fallback to simple audio element
          if (isValidUrl(audioUrl)) {
            createFallbackAudioElement(audioUrl);
            setAudioLoaded(true);
            setIsLoadingAudio(false);
          }
        }
      }, 300);
    }
  });
}, [
  isFullscreenMobile, 
  audioUrl, 
  safeLoadAudio, 
  mounted, 
  wavesurferInitialized,
  isMobile,
  isValidUrl,
  createFallbackAudioElement
]);

  const extractMixedAudioUrl = useCallback((mixResult, baseUrl) => {
    if (!mixResult) return null;
    if (mixResult.data && mixResult.data.file_path) {
      let url = mixResult.data.file_path;
      if (!url.startsWith('http') && baseUrl) {
        url = `${baseUrl}${url.startsWith('/') ? '' : '/'}${url}`;
      }
      return url;
    }
    const locations = [
      mixResult.file_path,
      mixResult.data?.url,
      mixResult.data?.audio_url,
      mixResult.data?.mixedUrl,
      mixResult.url
    ];
    for (const loc of locations) {
      if (loc && typeof loc === 'string') {
        if (loc.startsWith('http')) {
          return loc;
        } else if (baseUrl) {
          return `${baseUrl}${loc.startsWith('/') ? '' : '/'}${loc}`;
        }
      }
    }
    return null;
  }, []);

  const handleAudioReady = useCallback((event) => {
    if (!mounted.current) return;
    console.log("AUDIO PLAYER: Audio ready event received");
    setAudioLoaded(true);
    setIsLoadingAudio(false);
    if (event && event.duration) {
      setDuration(event.duration);
    } else if (audioElementRef.current) {
      setDuration(audioElementRef.current.duration || 0);
    }
    if (!analyzerRef.current && initializeAudioAnalysisRef.current) {
      initializeAudioAnalysisRef.current();
    }
    if (onAudioLoaded) {
      onAudioLoaded({ url: audioUrl, duration: event?.duration || audioElementRef.current?.duration || 0 });
    }
  }, [audioUrl, onAudioLoaded]);

  const handleAudioError = useCallback((error) => {
    console.error("AUDIO PLAYER: Error event:", error);
    if (!mounted.current) return;
    safeToast('error', 'Audio playback error. Trying alternative method.');
    if (audioUrl && globalAudioRef.current) {
      const fallbackUrl = globalAudioRef.current.getBestAudioUrl();
      if (fallbackUrl && fallbackUrl !== audioUrl) {
        setAudioUrl(fallbackUrl);
      }
    }
    if (onError) {
      onError(error);
    }
  }, [audioUrl, safeToast, onError]);

  const handleTimeUpdate = useCallback((time) => {
    if (!mounted.current) return;
    setCurrentTime(time);
  }, []);

  const handlePositionChange = useCallback((position) => {
    if (!mounted.current) return;
    setCurrentTime(position);
  }, []);

  const handleExportAudioHandler = useCallback(async () => {
    if (typeof onExportAudio === 'function') {
      try {
        await onExportAudio();
        return;
      } catch (err) {
        console.warn("AUDIO PLAYER: Error with provided export handler:", err);
      }
    }
    let downloadUrl = '';
    if (globalAudioRef.current && typeof globalAudioRef.current.getBestAudioUrl === 'function') {
      downloadUrl = globalAudioRef.current.getBestAudioUrl();
    }
    if (!downloadUrl && audioUrlRef.current) {
      downloadUrl = audioUrlRef.current;
    }
    if (!downloadUrl) {
      downloadUrl = audioUrl;
    }
    const mixedUrl = sessionStorage.getItem('mixedAudioUrl') ||
      window.__mixedAudioUrl ||
      (globalAudioRef.current && globalAudioRef.current.state?.mixedAudioUrl);
    if (mixedUrl) {
      downloadUrl = mixedUrl;
    }
    if (!downloadUrl || !isValidUrl(downloadUrl)) {
      safeToast('error', 'No audio available to download');
      return;
    }
    console.log("AUDIO PLAYER: Downloading from URL:", downloadUrl);
    safeToast('info', 'Preparing download...');
    try {
      const response = await fetch(downloadUrl, {
        method: 'GET',
        headers: { 'Accept': 'audio/mpeg, audio/*', 'Cache-Control': 'no-cache' },
        cache: 'no-store'
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch audio: ${response.status} ${response.statusText}`);
      }
      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = blobUrl;
      a.download = 'meditation-' + Date.now() + '.mp3';
      a.style.display = 'none';
      document.body.appendChild(a);
      a.click();
      setTimeout(() => {
        document.body.removeChild(a);
        URL.revokeObjectURL(blobUrl);
      }, 200);
      safeToast('success', 'Downloading your meditation audio');
    } catch (err) {
      console.warn("AUDIO PLAYER: Error using fetch method, trying direct link:", err);
      try {
        const a = document.createElement('a');
        a.href = downloadUrl;
        a.download = 'meditation-' + Date.now() + '.mp3';
        a.target = '_blank';
        a.style.display = 'none';
        document.body.appendChild(a);
        a.click();
        setTimeout(() => {
          document.body.removeChild(a);
        }, 200);
        safeToast('success', 'Downloading your meditation audio');
      } catch (directErr) {
        console.error("AUDIO PLAYER: All download methods failed:", directErr);
        safeToast('error', 'Unable to download audio. Please try again.');
      }
    }
  }, [audioUrl, safeToast, onExportAudio, isValidUrl]);

  const handleResetSessionHandler = useCallback(() => {
    if (typeof onReset === 'function') {
      try {
        onReset();
        return;
      } catch (error) {
        console.error("AUDIO PLAYER: Error in provided reset handler:", error);
      }
    }
    try {
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.pause();
          waveSurferRef.current.destroy();
          waveSurferRef.current = null;
        } catch (e) {
          console.warn("AUDIO PLAYER: Error destroying WaveSurfer:", e);
        }
      }
      if (audioElementRef.current) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error cleaning up audio element:", e);
        }
        audioElementRef.current = null;
      }
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
        } catch (e) {
          console.warn("AUDIO PLAYER: Error closing AudioContext:", e);
        }
        audioContextRef.current = null;
      }
      analyzerRef.current = null;
      setAnalyzerNode(null);
      if (globalAudioRef.current && typeof globalAudioRef.current.resetAllState === 'function') {
        globalAudioRef.current.resetAllState();
      } else if (typeof window !== 'undefined') {
        window.__ttsKey = null;
        window.__ttsProgress = 0;
        window.__ttsProcessing = false;
        window.__mixedAudioUrl = null;
        window.__currentAudioUrl = null;
        window.__mixingInProgress = false;
        window.__mixingCompleted = false;
        window.__mixingFailed = false;
        try {
          sessionStorage.removeItem('ttsKey');
          sessionStorage.removeItem('ttsProgress');
          sessionStorage.removeItem('ttsProcessing');
          sessionStorage.removeItem('mixedAudioUrl');
          sessionStorage.removeItem('currentAudioUrl');
          sessionStorage.removeItem('fallbackAudioUrl');
          sessionStorage.removeItem('mixingInProgress');
          sessionStorage.removeItem('mixingCompleted');
          sessionStorage.removeItem('mixingFailed');
        } catch (e) {
          console.warn("AUDIO PLAYER: Error clearing session storage:", e);
        }
      }
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.warn("AUDIO PLAYER: Error revoking blob URL:", e);
        }
      });
      blobUrlsRef.current = [];
      setAudioUrl('');
      setIsPlaying(false);
      setAudioLoaded(false);
      setIsLoadingAudio(true);
      setCurrentTime(0);
      setDuration(0);
      setIsInitialized(false);
      setInitComplete(false);
      setHasMixedAudio(false);
      setMixReady(false);
      setIsBackgroundTTSProcessing(false);
      setBackgroundTTSProgress(0);
      safeToast('success', 'Session reset. Ready for a new meditation.');
    } catch (error) {
      console.error("AUDIO PLAYER: Error in reset handler:", error);
      safeToast('error', 'Failed to reset session');
    }
  }, [onReset, safeToast]);

  const getGlobalStyles = useCallback((isMobile) => `
    @keyframes spin { 0% { transform: rotate(0deg); } 100% { transform: rotate(360deg); } }
    @keyframes pulse { 0% { transform: scale(0.9); opacity: 0.7; } 100% { transform: scale(1.1); opacity: 0.9; } }
    input[type="range"]::-webkit-slider-thumb {
      -webkit-appearance: none;
      width: ${isMobile ? '20px' : '16px'};
      height: ${isMobile ? '20px' : '16px'};
      border-radius: 50%;
      background: #7d12ff;
      cursor: pointer;
      box-shadow: 0 0 6px rgba(125,18,255,0.4);
    }
    input[type="range"]::-moz-range-thumb {
      width: ${isMobile ? '20px' : '16px'};
      height: ${isMobile ? '20px' : '16px'};
      border-radius: 50%;
      background: #7d12ff;
      cursor: pointer;
      border: none;
      box-shadow: 0 0 6px rgba(125,18,255,0.4);
    }
    button:hover:not(:disabled) { transform: translateY(-2px); box-shadow: 0 6px 16px rgba(125,18,255,0.3); }
    button:active:not(:disabled) { transform: translateY(0); }
    @media (max-width: 768px) {
      input[type="range"] { height: 12px; padding: 8px 0; }
      input[type="checkbox"] { width: 20px; height: 20px; }
      select { height: 44px; }
    }
  `, []);

  const loadingAnimationStyles = useCallback(() => `
    .mixing-progress-bar {
      width: 100%;
      height: 4px;
      background-color: rgba(255,255,255,0.1);
      border-radius: 2px;
      overflow: hidden;
      margin-top: 10px;
    }
    .mixing-progress-bar-inner {
      height: 100%;
      width: 0;
      background-color: #7d12ff;
      border-radius: 2px;
      transition: width 0.5s;
    }
    .mixing-progress-bar-animated {
      position: relative;
      animation: indeterminate-progress 1.5s ease-in-out infinite;
      background-image: linear-gradient(45deg, rgba(255,255,255,0.15) 25%, transparent 25%, transparent 50%, rgba(255,255,255,0.15) 50%, rgba(255,255,255,0.15) 75%, transparent 75%, transparent);
      background-size: 40px 40px;
    }
    @keyframes indeterminate-progress {
      0% { left: -100%; width: 100%; }
      100% { left: 100%; width: 100%; }
    }
  `, []);

  const setupAudioPlayer = useCallback(() => {
    if (!mounted.current) return;
    const validUrl = initialAudioUrl && isValidUrl(initialAudioUrl)
      ? initialAudioUrl
      : (globalAudioRef.current?.getBestAudioUrl?.() || createFallbackAudioBlob());
    if (validUrl) {
      setAudioUrl(validUrl);
      audioUrlRef.current = validUrl;
      const { audioElement } = initializeAudio({
        audioUrl: validUrl,
        globalAudioState: globalAudioRef.current,
        audioElementRef: audioElementRef,
        waveSurferRef: waveSurferRef,
        setAudioElement: (element) => {
          audioElementRef.current = element;
          if (globalAudioRef.current && !globalAudioRef.current._audioElement) {
            globalAudioRef.current._audioElement = element;
          }
        },
        createFallbackElement: () => createFallbackAudioElement(validUrl),
        onAudioReady: handleAudioReady
      });
      if (audioElement && !audioElement._listenersInitialized && setupAudioElementListenersRef.current) {
        setupAudioElementListenersRef.current(audioElement);
        audioElement._listenersInitialized = true;
      }
      setTimeout(() => {
        if (mounted.current && audioElement && !analyzerRef.current && initializeAudioAnalysisRef.current) {
          initializeAudioAnalysisRef.current();
        }
      }, 500);
    }
  }, [mounted, initialAudioUrl, isValidUrl, createFallbackAudioBlob, createFallbackAudioElement, handleAudioReady, globalAudioRef]);

  useEffect(() => {
    mounted.current = true;
    isMountedRef.current = true;
    setComponentReady(true);
    const initTimeout = setTimeout(() => {
      if (mounted.current) {
        setupAudioPlayer();
      }
    }, 100);
    const handleResize = () => {
      setWindowWidth(window.innerWidth || 0);
      setWindowHeight(window.innerHeight || 0);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      clearTimeout(initTimeout);
      window.removeEventListener('resize', handleResize);
      mounted.current = false;
      isMountedRef.current = false;
      if (audioElementRef.current && !isUsingGlobalAudio) {
        try {
          audioElementRef.current.pause();
          audioElementRef.current.src = '';
          audioElementRef.current.load();
          audioElementRef.current = null;
        } catch (err) {
          console.warn("Error cleaning up audio element:", err);
        }
      }
      if (audioContextRef.current) {
        try {
          audioContextRef.current.close();
          audioContextRef.current = null;
          analyzerRef.current = null;
        } catch (err) {
          console.warn("Error closing AudioContext:", err);
        }
      }
      if (waveSurferRef.current) {
        try {
          waveSurferRef.current.pause();
          waveSurferRef.current.destroy();
          waveSurferRef.current = null;
        } catch (err) {
          console.warn("Error destroying WaveSurfer:", err);
        }
      }
      safetyTimeoutsRef.current.forEach(timeoutId => {
        if (timeoutId) clearTimeout(timeoutId);
      });
      if (audioInitTimeoutRef.current) {
        clearTimeout(audioInitTimeoutRef.current);
      }
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (err) {
          console.warn("Error revoking blob URL:", err);
        }
      });
    };
  }, [setupAudioPlayer, isUsingGlobalAudio]);

  useEffect(() => {
    if (audioUrl) {
      audioUrlRef.current = audioUrl;
    }
    if (audioElementRef.current && audioUrl && audioElementRef.current.src !== audioUrl) {
      try {
        audioElementRef.current.src = audioUrl;
        audioElementRef.current.load();
      } catch (err) {
        console.warn("Error updating audio element URL:", err);
      }
    }
    if (waveSurferRef.current && audioUrl) {
      safeLoadAudio(audioUrl, waveSurferRef.current);
    }
  }, [audioUrl, safeLoadAudio]);

  useEffect(() => {
    return () => {
      if (window._activeSurfers) {
        Object.keys(window._activeSurfers).forEach(id => {
          try {
            const surfer = window._activeSurfers[id];
            surfer.destroy();
            delete window._activeSurfers[id];
          } catch (err) {
            console.warn("Error cleaning up WaveSurfer instance:", err);
          }
        });
      }
      setWavesurferInitialized(false);
    };
  }, []);

  useEffect(() => {
    setupAudioElementListenersRef.current = setupAudioElementListeners;
  }, [setupAudioElementListeners]);

  useEffect(() => {
    initializeWaveSurferRef.current = initializeWaveSurfer;
  }, [initializeWaveSurfer]);

  const trackInfo = getTrackInfo();

  return (
    <div className="listen-step-container" style={{
      margin: 0,
      padding: 0,
      width: '100%',
      height: isFullscreenMobile ? '100vh' : 'auto',
      position: isFullscreenMobile ? 'fixed' : 'relative',
      top: isFullscreenMobile ? '0' : 'auto',
      left: isFullscreenMobile ? '0' : 'auto',
      zIndex: isFullscreenMobile ? 1000 : 'auto',
      backgroundColor: isFullscreenMobile ? '#161616' : 'transparent',
      transition: 'height 0.3s ease'
    }}>
      <style dangerouslySetInnerHTML={{ __html: getGlobalStyles(isMobile) }} />
      <style dangerouslySetInnerHTML={{ __html: loadingAnimationStyles() }} />
      {(!isFullscreen || !isMobile) && (
        <div className="step-header" style={{ textAlign: 'center', padding: '10px', color: '#fff' }}>
          <h2>Your Meditation is Ready</h2>
          <p>Listen, save, or create a new meditation</p>
        </div>
      )}
      {isFullscreen && (
        <FullScreenVisualizer
          audioElement={audioElementRef.current}
          isPlaying={isPlaying}
          onPlayPause={togglePlayback}
          onToggleFullScreen={toggleFullscreen}
          colorPrimary="#7d12ff"
          colorSecondary="#5636f3"
          backgroundColor="#02073c"
          externalAnalyzer={analyzerRef.current}
          externalAudioContext={audioContextRef.current}
          currentTime={currentTime}
          duration={duration}
          onSkip={handleSkip}
          onSeek={(time) => {
            syncTimePosition({
              currentTime: time,
              audioElement: audioElementRef.current,
              waveSurfer: waveSurferRef.current
            });
          }}
          musicLibrary={musicLibrary}
          selectedMusic={selectedMusic}
          onMusicSelect={handleMusicSelect}
        />
      )}
      <div className="music-player-mini" style={{
        width: '100%',
        maxWidth: '800px',
        margin: '24px auto',
        padding: '20px',
        backgroundColor: '#1C1C2E',
        backgroundImage: 'linear-gradient(135deg, #1E1C2E 0%, #0C0C14 100%)',
        borderRadius: '20px',
        boxShadow: '0 10px 30px rgba(0,0,0,0.3)',
        transition: 'all 0.3s ease'
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginBottom: '20px' }}>
          <div style={{ position: 'relative', width: '80px', height: '80px', borderRadius: '50%', flexShrink: 0, overflow: 'hidden', backgroundColor: '#02073c' }}>
            {renderVisualizer()}
            <button onClick={toggleVisualizer} style={{
              position: 'absolute',
              bottom: '4px',
              right: '4px',
              backgroundColor: 'rgba(0,0,0,0.5)',
              color: 'white',
              borderRadius: '50%',
              width: '20px',
              height: '20px',
              border: 'none',
              cursor: 'pointer',
              fontSize: '10px',
              zIndex: 10
            }}>
              <IoImage size={12} />
            </button>
          </div>
          <div style={{ flex: 1, minWidth: 0, color: '#fff' }}>
            <h3 style={{ fontSize: '18px', fontWeight: '600', marginBottom: '8px' }}>{trackInfo.title}</h3>
            <p style={{ fontSize: '14px', color: 'rgba(255,255,255,0.7)', margin: 0 }}>{trackInfo.artist}</p>
          </div>
          {isMobile && (
            <button onClick={toggleFullscreen} style={{
              background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
              border: 'none',
              borderRadius: '50%',
              width: '44px',
              height: '44px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              boxShadow: '0 4px 10px rgba(125,18,255,0.3)',
              color: '#fff'
            }}>
              {isFullscreen ? <IoContract size={24} /> : <IoExpand size={24} />}
            </button>
          )}
        </div>
        <div style={{
          marginBottom: '10px',
          position: 'relative',
          backgroundColor: 'rgba(20,20,30,0.3)',
          borderRadius: '8px',
          padding: '5px',
          overflow: 'hidden',
          minHeight: '100px'
        }}>
          <div ref={waveformContainerRefCallback} style={{
            marginBottom: '5px',
            minHeight: '80px',
            height: '80px',
            width: '100%',
            position: 'relative'
          }} />
          <div ref={timelineContainerRef} style={{ height: '30px', width: '100%' }} />
          {componentReady && !wavesurferInitialized && (
            <ResponsiveWaveSurfer
              audioUrl={audioUrl}
              isPlaying={isPlaying}
              onReady={handleAudioReady}
              onError={handleAudioError}
              onTimeUpdate={handleTimeUpdate}
              zoom={zoom}
              height={80}
              waveColor="rgba(125,18,255,0.3)"
              progressColor="#7d12ff"
              showTimeline={true}
              onPositionChange={handlePositionChange}
              containerRef={waveformContainerRef}
              timelineContainerRef={timelineContainerRef}
              volume={volume}
              muted={isMuted}
              globalAudioRef={globalAudioRef}
            />
          )}
          {!waveSurferRef.current && !isLoadingAudio && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: duration > 0 ? `${(currentTime / duration) * 100}%` : '0%',
              backgroundColor: '#7d12ff',
              borderRadius: '4px 0 0 4px',
              transition: 'width 0.1s linear',
              zIndex: 2
            }} />
          )}
          {isLoadingAudio && (
            <div style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              borderRadius: '4px',
              backgroundColor: 'rgba(0,0,0,0.1)',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              zIndex: 10
            }}>
              <div style={{
                width: '30px',
                height: '30px',
                borderRadius: '50%',
                border: '3px solid rgba(125,18,255,0.3)',
                borderTopColor: '#7d12ff',
                animation: 'spin 1s linear infinite'
              }} />
            </div>
          )}
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', marginBottom: '20px' }}>
          <button onClick={() => handleSkip(-10)} disabled={!audioLoaded} style={{
            backgroundColor: 'rgba(255,255,255,0.05)',
            border: 'none',
            borderRadius: '50%',
            width: '44px',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: '#fff',
            opacity: audioLoaded ? 1 : 0.5
          }}>
            <IoPlaySkipBack size={24} />
          </button>
          <button onClick={togglePlayback} disabled={!audioLoaded} style={{
            background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
            border: 'none',
            borderRadius: '50%',
            width: '60px',
            height: '60px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            boxShadow: '0 6px 15px rgba(125,18,255,0.3)',
            color: '#fff',
            opacity: audioLoaded ? 1 : 0.5
          }}>
            {isPlaying ? <IoPauseCircle size={32} /> : <IoPlayCircle size={32} />}
          </button>
          <button onClick={() => handleSkip(10)} disabled={!audioLoaded} style={{
            backgroundColor: 'rgba(255,255,255,0.05)',
            border: 'none',
            borderRadius: '50%',
            width: '44px',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: '#fff',
            opacity: audioLoaded ? 1 : 0.5
          }}>
            <IoPlaySkipForward size={24} />
          </button>
          <button onClick={toggleMute} disabled={!audioLoaded} style={{
            backgroundColor: 'rgba(255,255,255,0.05)',
            border: 'none',
            borderRadius: '50%',
            width: '44px',
            height: '44px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
            color: '#fff',
            opacity: audioLoaded ? 1 : 0.5
          }}>
            {isMuted ? <IoVolumeOff size={24} /> : <IoVolumeHigh size={24} />}
          </button>
        </div>
        {!isMobile && (
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '20px', padding: '0 16px' }}>
            <IoVolumeOff size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
            <input
              type="range"
              min="0"
              max="1"
              step="0.01"
              value={volume}
              onChange={(e) => handleVolumeChange(parseFloat(e.target.value))}
              style={{
                flex: 1,
                height: '4px',
                appearance: 'none',
                backgroundColor: 'rgba(255,255,255,0.1)',
                outline: 'none',
                borderRadius: '2px',
                background: `linear-gradient(to right, #7d12ff 0%, #7d12ff ${volume * 100}%, rgba(255,255,255,0.1) ${volume * 100}%, rgba(255,255,255,0.1) 100%)`
              }}
            />
            <IoVolumeHigh size={16} style={{ color: 'rgba(255,255,255,0.6)' }} />
          </div>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', gap: '12px' }}>
          <button onClick={togglePlaylist} style={{
            flex: 1,
            padding: '12px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: 'rgba(255,255,255,0.8)',
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer'
          }}>
            <IoList size={18} />
            <span>Playlist</span>
          </button>
          <button onClick={handleExportAudioHandler} style={{
            flex: 1,
            padding: '12px',
            backgroundColor: 'rgba(255,255,255,0.05)',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '12px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            color: 'rgba(255,255,255,0.8)',
            fontSize: '14px',
            fontWeight: '500',
            cursor: 'pointer'
          }}>
            <IoDownload size={18} />
            <span>Download</span>
          </button>
          {typeof onReset === 'function' && (
            <button onClick={handleResetSessionHandler} style={{
              flex: 1,
              padding: '12px',
              backgroundColor: 'rgba(255,255,255,0.05)',
              border: '1px solid rgba(255,255,255,0.1)',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px',
              color: 'rgba(255,255,255,0.8)',
              fontSize: '14px',
              fontWeight: '500',
              cursor: 'pointer'
            }}>
              <IoRefresh size={18} />
              <span>New</span>
            </button>
          )}
        </div>
      </div>
      {showPlaylist && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: 'rgba(0,0,0,0.8)',
          zIndex: 2000,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          padding: '20px'
        }}>
          <div style={{
            width: '100%',
            maxWidth: '500px',
            maxHeight: '80vh',
            backgroundColor: '#1C1C2E',
            borderRadius: '16px',
            boxShadow: '0 10px 30px rgba(0,0,0,0.4)',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <div style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              padding: '16px 20px',
              borderBottom: '1px solid rgba(255,255,255,0.1)'
            }}>
              <h3 style={{ margin: 0, color: '#fff', fontSize: '18px' }}>Select Background Music</h3>
              <button onClick={togglePlaylist} style={{
                background: 'transparent',
                border: 'none',
                color: 'white',
                cursor: 'pointer',
                padding: '4px'
              }}>
                <IoClose size={24} />
              </button>
            </div>
            <div style={{ overflowY: 'auto', padding: '10px', flex: 1 }}>
              {Array.isArray(musicLibrary) && musicLibrary.length > 0 ? (
                musicLibrary.map((track, index) => {
                  if (!track || !track.name) return null;
                  const displayName = track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
                  const isActive = track.name === selectedMusic;
                  const trackMood = getMoodLabel(track.name);
                  const coverArt = getAlbumArt(track.name);
                  return (
                    <div key={track.name || index} onClick={() => handleMusicSelect(track.name)} style={{
                      display: 'flex',
                      alignItems: 'center',
                      padding: '12px',
                      borderRadius: '8px',
                      marginBottom: '8px',
                      cursor: 'pointer',
                      backgroundColor: isActive ? 'rgba(125,18,255,0.15)' : 'transparent',
                      transform: isActive ? 'translateY(-1px)' : 'none',
                      transition: 'all 0.2s ease'
                    }}>
                      <div style={{
                        width: '48px',
                        height: '48px',
                        borderRadius: '8px',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        marginRight: '12px',
                        backgroundImage: `url(${coverArt})`
                      }}></div>
                      <div style={{ flex: 1, overflow: 'hidden' }}>
                        <div style={{
                          fontSize: '16px',
                          fontWeight: isActive ? '600' : '500',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          color: '#ffffff'
                        }}>{displayName}</div>
                        <div style={{
                          fontSize: '13px',
                          color: 'rgba(255,255,255,0.6)',
                          marginTop: '2px'
                        }}>{trackMood}</div>
                      </div>
                      {isActive && (
                        <div style={{
                          width: '6px',
                          height: '24px',
                          backgroundColor: '#7d12ff',
                          borderRadius: '3px',
                          marginLeft: '12px'
                        }}></div>
                      )}
                    </div>
                  );
                })
              ) : (
                <div style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  padding: '40px 20px',
                  color: 'rgba(255,255,255,0.7)',
                  textAlign: 'center'
                }}>
                  <div style={{ fontSize: '16px', marginBottom: '10px' }}>No music tracks available</div>
                  <button onClick={togglePlaylist} style={{
                    marginTop: '10px',
                    padding: '8px 16px',
                    borderRadius: '8px',
                    background: 'rgba(125,18,255,0.2)',
                    border: '1px solid rgba(125,18,255,0.4)',
                    color: 'white',
                    cursor: 'pointer'
                  }}>Close</button>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <AudioDiagnosticsTool 
        audioUrl={audioUrl} 
        analyzerNode={analyzerRef.current} 
        audioElement={audioElementRef.current}
      />
    </div>
  );
};

export default ListenStep;
