import React, { useState, useEffect, useCallback, useRef } from 'react';
import { ArrowLeft, X } from 'lucide-react';
import LoadingOverlay from '../common/LoadingOverlay';
import decisionTree from '../common/decisionTreeData';
import './DecisionTreeModal.css';
import UnifiedPromptToolbar from './UnifiedPromptToolbar';

// Import images
import angerReleaseImg from '../../../../assets/Images/anger.png';
import stressReliefImg from '../../../../assets/Images/relax.png';
import healthierMindImg from '../../../../assets/Images/healthy.png';
import sleepImg from '../../../../assets/Images/sleep.png';

const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return 'Good Morning';
  if (hour < 18) return 'Good Afternoon';
  return 'Good Evening';
};

// Preset meditation cards
const PRESET_MEDITATIONS = [
  {
    id: 'anger-release',
    name: 'Anger release',
    imageSrc: angerReleaseImg,
    prompt: 'Create a 10-minute meditation focused on anger release and returning to calm',
    selections: ['Mindfulness', 'Breath Focus', '10 minutes', 'Guided']
  },
  {
    id: 'stress-relief',
    name: 'Stress relief',
    imageSrc: stressReliefImg,
    prompt: 'Create a 10-minute meditation focused on relieving stress and tension',
    selections: ['Mindfulness', 'Body Scan', '10 minutes', 'Guided']
  },
  {
    id: 'healthier-mind',
    name: 'Healthier Mind',
    imageSrc: healthierMindImg,
    prompt: 'Create a 10-minute meditation for mental clarity and cognitive health',
    selections: ['Concentration', 'Visual Object', '10 minutes', 'Guided']
  },
  {
    id: 'sleep-better',
    name: 'Sleep Better',
    imageSrc: sleepImg,
    prompt: 'Create a 15-minute meditation for deep sleep and relaxation',
    selections: ['Sleep', 'Progressive Relaxation', '20 minutes', 'Guided']
  }
];

// Refined category pills with more elegant colors and gradients
const CATEGORIES = [
  { 
    id: 'mindfulness', 
    name: 'Mindfulness', 
    icon: '🧘', 
    color: '#12c2e9', 
    gradient: 'linear-gradient(45deg, #2193b0, #6dd5ed)',
    decisionTreeNode: 'mindfulness', 
    className: 'mindfulness-main' 
  },
  { 
    id: 'visualization', 
    name: 'Visualization', 
    icon: '😌', 
    color: '#ff4e50', 
    gradient: 'linear-gradient(45deg, #ff9966, #ff5e62)',
    decisionTreeNode: 'visualization', 
    className: 'visualization-main' 
  },
  { 
    id: 'breath', 
    name: 'Breath', 
    icon: '🚶', 
    color: '#a18cd1', 
    gradient: 'linear-gradient(45deg, #8e9eab, #a18cd1)',
    decisionTreeNode: 'breath_awareness', 
    className: 'breath-main' 
  },
  { 
    id: 'sleep', 
    name: 'Sleep', 
    icon: '🧠', 
    color: '#43cea2', 
    gradient: 'linear-gradient(45deg, #42b883, #43cea2)',
    decisionTreeNode: 'sleep', 
    className: 'sleep-main' 
  },
  { 
    id: 'body-scan', 
    name: 'Body Scan', 
    icon: '🧎', 
    color: '#48c6ef', 
    gradient: 'linear-gradient(45deg, #38a0d0, #55b3d9)',
    decisionTreeNode: 'body_scan', 
    className: 'body_scan-main' 
  },
  { 
    id: 'affirmations', 
    name: 'Affirmations', 
    icon: '☀️', 
    color: '#f5af19', 
    gradient: 'linear-gradient(45deg, #f5af19, #f7b733)',
    decisionTreeNode: 'affirmations', 
    className: 'affirmations-main' 
  },
  { 
    id: 'soundscapes', 
    name: 'Soundscapes', 
    icon: '🎵', 
    color: '#43b692', 
    gradient: 'linear-gradient(45deg, #38a0d0, #43b692)',
    decisionTreeNode: 'unguided', 
    className: 'unguided-main' 
  }
];

// Get gradient for option class name - refined color palette
const getGradientForClass = (className) => {
  if (className.includes('visualization')) return 'linear-gradient(45deg, #ff9966, #ff5e62)';
  if (className.includes('mindfulness')) return 'linear-gradient(45deg, #2193b0, #6dd5ed)';
  if (className.includes('sleep')) return 'linear-gradient(45deg, #42b883, #43cea2)';
  if (className.includes('breath')) return 'linear-gradient(45deg, #8e9eab, #a18cd1)';
  if (className.includes('affirmations')) return 'linear-gradient(45deg, #f5af19, #f7b733)';
  if (className.includes('concentration')) return 'linear-gradient(45deg, #38a0d0, #55b3d9)';
  if (className.includes('body_scan')) return 'linear-gradient(45deg, #38a0d0, #55b3d9)';
  if (className.includes('unguided')) return 'linear-gradient(45deg, #38a0d0, #43b692)';
  
  return 'linear-gradient(45deg, #2b5876, #4e4376)'; // Default gradient
};

const CreateStep = ({
  customPrompt,
  setCustomPrompt,
  onGenerateScript,
  selectedOptions,
  setSelectedOptions,
  LoadingController,
  setError,
  baseUrl,
  cleanText,
  selectedVoice,
  setMeditationScript,
  setEditorMode,
  goToNextStep,
  markStepCompleted,
  resetDecisionTree: parentResetDecisionTree,
  withSSELoading,
  mounted,
  setIsScriptLoading,
  OPERATION_PHASES,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [activeNode, setActiveNode] = useState(null);
  const [hasGeneratedScript, setHasGeneratedScript] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showDecisionTree, setShowDecisionTree] = useState(false);
  const [activeBackground, setActiveBackground] = useState(null);
  const [overlayActive, setOverlayActive] = useState(false);
  const [nodeHistory, setNodeHistory] = useState([]);
  
  // Use a single loading state that combines both transcribing and script loading
  const [isLoading, setIsLoading] = useState(false);
  const [loadingMessage, setLoadingMessage] = useState("");
  
  // Define an internal resetDecisionTree function to handle the component's state
  const internalResetDecisionTree = useCallback(() => {
    setSelectedCategory(null);
    setActiveNode(null);
    setNodeHistory([]);
    setShowDecisionTree(false);
    setOverlayActive(false);
    document.body.style.overflow = ''; // Restore scrolling
  }, []);

  // Use either the parent's function or our internal one
  const effectiveResetDecisionTree = useCallback(() => {
    // First call the parent reset if it exists
    if (parentResetDecisionTree) {
      parentResetDecisionTree();
    }
    // Then always call our internal one
    internalResetDecisionTree();
  }, [parentResetDecisionTree, internalResetDecisionTree]);
  
  // Track window size for responsive layouts
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  // Set loading state for transcribing
  const setIsTranscribing = (isActive) => {
    setIsLoading(isActive);
    setLoadingMessage(isActive ? "Transcribing..." : "");
  };
  
  // Handle preset selection
  const handlePresetSelect = async (preset) => {
    setCustomPrompt(preset.prompt);
    setIsLoading(true);
    setLoadingMessage("Creating your meditation...");
    
    try {
      const script = await onGenerateScript(preset.prompt);
      if (script) {
        markStepCompleted(1);
        goToNextStep();
      }
    } catch (err) {
      setError({
        message: `Failed to generate ${preset.name} meditation`,
        type: 'preset'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  // Handle category selection - show decision tree immediately with overlay
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setActiveBackground(category.gradient);
    
    // Set up the decision tree first without showing it
    if (category.decisionTreeNode && decisionTree[category.decisionTreeNode]) {
      const initialNode = decisionTree[category.decisionTreeNode];
      setActiveNode(initialNode);
      
      // Set this category as a selected option with its icon
      setSelectedOptions([{
        label: category.name,
        className: category.className || '',
        isMainCategory: true,
        pauseFactor: 1.2,
        gradient: category.gradient,
        icon: category.icon // Include the icon
      }]);
      
      // Show the overlay with slight delay to ensure state is set
      setTimeout(() => {
        setOverlayActive(true);
        document.body.style.overflow = 'hidden'; // Prevent scrolling
        
        // Now show the decision tree
        setShowDecisionTree(true);
      }, 10);
    }
  };
  
  const handleOptionSelect = (option) => {
    // Add to selected options with icon
    setSelectedOptions((prev) => {
      const newOptions = [...prev];
      if (!newOptions.some(opt => opt.label === option.label)) {
        newOptions.push({
          label: option.label,
          className: option.className || '',
          isMainCategory: option.isMainCategory || false,
          pauseFactor: option.pauseFactor || 1.2,
          icon: option.icon
        });
      }
      return newOptions;
    });
    
    // Navigate to next node or generate
    if (option.nextNode === null) {
      effectiveResetDecisionTree();
      setTimeout(() => handleGenerateScript(), 50);
    } else if (option.nextNode && decisionTree[option.nextNode]) {
      // Push the current node into history before moving on
      setNodeHistory(prev => [...prev, activeNode]);
      setTimeout(() => {
        setActiveNode(decisionTree[option.nextNode]);
      }, 50);
    }
  };

  const handleBack = () => {
    // If the user is at the first step (or no options are selected), exit the overlay
    if (selectedOptions.length <= 1) {
      effectiveResetDecisionTree();
    } else {
      // Otherwise, go back one step:
      setNodeHistory((prev) => {
        const historyCopy = [...prev];
        const previousNode = historyCopy.pop();
        setActiveNode(previousNode);
        return historyCopy;
      });
      setSelectedOptions((prev) => prev.slice(0, -1));
    }
  };
  
  // Generate script based on selected options
  const handleGenerateScript = useCallback(() => {
    const prompt = buildPromptFromOptions(selectedOptions);
    if (!prompt) return;
    
    // Show the loading overlay
    setIsLoading(true);
    setLoadingMessage("Creating your meditation...");
    
    console.log("Generating meditation from options:", selectedOptions);
    
    onGenerateScript(prompt)
      .then(script => {
        if (script) {
          console.log("Script generation successful, moving to next step");
          markStepCompleted(1);
          goToNextStep();
        } else {
          console.error("Script generation returned no script");
        }
      })
      .catch(err => {
        console.error("Script generation error:", err);
        setError({
          message: 'Failed to generate meditation',
          type: 'generation'
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedOptions, onGenerateScript, markStepCompleted, goToNextStep, setError]);

  // Helper function to build prompt from the selected options
  const buildPromptFromOptions = (options) => {
    if (!options || options.length === 0) return '';
    
    const mainCategory = options.find(opt => opt.isMainCategory);
    const focusOption = options.find(opt => 
      !opt.isMainCategory && (
        opt.className.includes('focus') || 
        opt.className.includes('breath') || 
        opt.className.includes('body') || 
        opt.className.includes('visual')
      )
    );
    
    const durationOption = options.find(opt => 
      opt.label.includes('minute') || 
      opt.label.includes('min')
    );
    
    const duration = durationOption 
      ? durationOption.label 
      : '10 minutes';
    
    const type = mainCategory 
      ? mainCategory.label 
      : 'meditation';
    
    const focus = focusOption 
      ? `with focus on ${focusOption.label}` 
      : '';
    
    return `Create a ${duration} ${type} meditation ${focus}`.trim();
  };
  
  // Handle recording completion
  const handleRecordingComplete = (transcriptionText) => {
    if (transcriptionText) {
      setCustomPrompt(transcriptionText);
    }
  };

// Handle start guide
const handleStartGuide = () => {
  // Pick the first category as a starting point
  handleCategorySelect(CATEGORIES[0]);
};

// Clean up on unmount
useEffect(() => {
  return () => {
    document.body.style.overflow = ''; // Restore scrolling if component unmounts
  };
}, []);

// Scroll to top on mount
useEffect(() => {
  window.scrollTo(0, 0);
}, []);

// Check screen size for responsive layouts
const isMobile = windowWidth <= 768;
const isSmallMobile = windowWidth <= 480;

// Helper function to get color based on option class name
const getOptionColor = (className) => {
  if (!className) return '#ffffff';
  
  if (className.includes('visualization')) return '#ff5e62';
  if (className.includes('mindfulness')) return '#2193b0';
  if (className.includes('sleep')) return '#42b883';
  if (className.includes('breath')) return '#8e9eab';
  if (className.includes('affirmations')) return '#f5af19';
  if (className.includes('concentration')) return '#38a0d0';
  if (className.includes('body_scan')) return '#38a0d0';
  if (className.includes('unguided')) return '#43b692';
  
  return '#ffffff';
};

// Helper function to get background color with opacity
const getOptionBgColor = (className) => {
  return `${getOptionColor(className)}15`; // 15% opacity
};

// Get the main category from selected options for styling
const mainCategory = selectedOptions.find(opt => opt.isMainCategory);
const mainCategoryGradient = mainCategory ? 
  mainCategory.gradient || getGradientForClass(mainCategory.className) : 
  'linear-gradient(45deg, #2b5876, #4e4376)';

// Featured meditations - these will appear in the "Quick start" section
const FEATURED_MEDITATIONS = [
  {
    id: 'morning-bliss',
    name: 'Morning Bliss',
    duration: '5 min',
    gradient: 'linear-gradient(45deg, #FF9966, #FF5E62)'
  },
  {
    id: 'focus-power',
    name: 'Focus Power',
    duration: '10 min',
    gradient: 'linear-gradient(45deg, #2193b0, #6dd5ed)'
  },
  {
    id: 'evening-calm',
    name: 'Evening Calm',
    duration: '15 min',
    gradient: 'linear-gradient(45deg, #8e9eab, #a18cd1)'
  },
  {
    id: 'deep-relaxation',
    name: 'Deep Relaxation',
    duration: '20 min',
    gradient: 'linear-gradient(45deg, #42b883, #43cea2)'
  }
];

return (
  <div className="step-container">

    {/* If the overlay is active, render the decision tree overlay */}
    {overlayActive && (
      <div
        className={`meditation-decision-tree-overlay ${showDecisionTree ? 'active' : ''}`}
        onClick={(e) => {
          // Close the overlay if user clicks outside the modal
          if (e.target === e.currentTarget) {
            effectiveResetDecisionTree();
          }
        }}
      >
        {/* Only show the modal if showDecisionTree is true and we have an activeNode */}
        {showDecisionTree && activeNode && (
          <div className="meditation-decision-tree-modal">
            {/* Modal Header with Back and Close buttons */}
            <div className="meditation-decision-tree-header">
              <button
                className="meditation-decision-tree-back"
                onClick={handleBack}
              >
                <ArrowLeft size={16} />
                Back
              </button>
              <button
                className="meditation-decision-tree-close"
                onClick={effectiveResetDecisionTree}
              >
                <X size={16} />
              </button>
            </div>

            {/* Modal Content: Prompt + Option Pills */}
            <div className="meditation-decision-tree-content">
              <h3 className="meditation-decision-tree-prompt">
                {activeNode.prompt}
              </h3>
              <div className="meditation-decision-tree-options">
              {activeNode.options.map((option, idx) => {
                const isSelected = selectedOptions.some(sel => sel.label === option.label);
                return (
                  <button
                    key={idx}
                    className={`
                      meditation-decision-tree-pill
                      ${option.className || ''}
                      ${isSelected ? 'selected' : ''}
                    `}
                    // Set the inline CSS variable for the background:
                    style={{ '--pill-bg': option.gradient || 'rgba(255,255,255,0.1)' }}
                    onClick={() => handleOptionSelect(option)}
                  >
                    {option.icon && (
                      <span className="meditation-decision-tree-pill-icon">
                        {option.icon}
                      </span>
                    )}
                    <span className="meditation-decision-tree-pill-text">
                      {option.label}
                    </span>
                  </button>
                );
              })}
              </div>
            </div>

            {/* Display currently selected options (category, sub-options, etc.) */}
            {selectedOptions.length > 0 && (
              <div className="meditation-decision-tree-selected">
                {selectedOptions.map((option, index) => (
                  <span
                    key={index}
                    className={`
                      meditation-decision-tree-selected-option
                      ${option.isMainCategory ? 'main-category' : ''}
                      ${option.className || ''}
                    `}
                    style={
                      option.isMainCategory
                        ? { background: option.gradient || getGradientForClass(option.className) }
                        : {}
                    }
                  >
                    {/* Optional icon */}
                    {option.icon && (
                      <span className="meditation-decision-tree-selected-icon">
                        {option.icon}
                      </span>
                    )}
                    <span>{option.label}</span>
                  </span>
                ))}
              </div>
            )}

            {/* Progress bar at the bottom */}
            <div className="meditation-decision-tree-progress">
              <div
                className="meditation-decision-tree-progress-bar"
                style={{
                  width: `${Math.min(100, selectedOptions.length * 25)}%`,
                  background: activeBackground || mainCategoryGradient
                }}
              ></div>
            </div>
          </div>
        )}
      </div>
    )}

    <div className="step-header">
      <h2 className="greeting-title">{getGreeting()}</h2>
      <p className="greeting-subtitle">
        Evan Lane
      </p>
    </div>

    {/* Prompt Toolbar - Centered in viewport with expansion capability */}
    <div className="toolbar-wrapper" style={{ 
      width: '100%', 
      maxWidth: '850px', 
      margin: '0 auto 24px auto',
      padding: '0 16px',
      position: 'sticky',
      top: '80px',  // Gives some space below the header
      zIndex: 5,
      transition: 'all 0.3s ease'
    }}>
      <UnifiedPromptToolbar 
        customPrompt={customPrompt}
        setCustomPrompt={setCustomPrompt}
        onStartGuide={handleStartGuide}
        // Pass setIsTranscribing function to the toolbar
        setParentTranscribing={setIsTranscribing}
        
        // Don't try to auto-generate in the onRecordingComplete callback
        // Let the countdown handle this automatically
        onRecordingComplete={(transcriptionText) => {
          if (transcriptionText) {
            setCustomPrompt(transcriptionText);
            // Don't do anything else here - the countdown will trigger generation
          }
        }}
        
        disabled={isLoading}
        
        // Direct generation when Enter is pressed or countdown completes
        // Modified to accept an optional text parameter
        onDirectGenerate={(text) => {
          // Use either the passed text or the current customPrompt
          const promptToUse = text || customPrompt;
          
          if (promptToUse.trim()) {
            // Show the loading overlay
            setIsLoading(true);
            setLoadingMessage("Creating your meditation...");
            
            console.log("Generating meditation from prompt:", promptToUse);
            
            onGenerateScript(promptToUse)
              .then(script => {
                if (script) {
                  console.log("Script generation successful, moving to next step");
                  markStepCompleted(1);
                  goToNextStep();
                } else {
                  console.error("Script generation returned no script");
                }
              })
              .catch(err => {
                console.error("Script generation error:", err);
                setError({
                  message: 'Failed to generate meditation',
                  type: 'generation'
                });
              })
              .finally(() => {
                setIsLoading(false);
              });
          } else {
            console.warn("Cannot generate with empty prompt");
          }
        }}
      />
    </div>

    {/* Quick start meditation cards */}
    <div className="meditation-presets" style={{
      width: '100%',
      marginBottom: '24px'
    }}>
      <div style={{
        display: 'grid',
        gridTemplateColumns: isSmallMobile ? '1fr' : 'repeat(2, 1fr)',
        gap: '16px',
        marginBottom: isSmallMobile ? '16px' : '0'
      }}>
        {PRESET_MEDITATIONS.map(preset => (
          <div 
            key={preset.id} 
            className="preset-card" 
            onClick={() => !isLoading && handlePresetSelect(preset)}
            style={{
              position: 'relative',
              borderRadius: '16px',
              overflow: 'hidden',
              height: isSmallMobile ? '180px' : '220px',
              cursor: isLoading ? 'default' : 'pointer',
              transition: 'transform 0.3s ease, box-shadow 0.3s ease',
              boxShadow: '0 8px 24px rgba(0, 0, 0, 0.2)'
            }}
          >
            {/* Card Content (Top Label) */}
            <div className="card-content" style={{
              position: 'absolute',
              top: '16px',
              left: '16px',
              right: '16px',
              zIndex: 3
            }}>
              <div className="card-label" style={{
                display: 'inline-block',
                padding: '8px 16px',
                borderRadius: '9999px',
                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                backdropFilter: 'blur(4px)',
                WebkitBackdropFilter: 'blur(4px)',
                color: 'white',
                fontSize: '14px',
                fontWeight: '500',
                textAlign: 'center'
              }}>{preset.name}</div>
            </div>
            
            {/* Gradient Overlay */}
            <div className="card-overlay" style={{
              position: 'absolute',
              inset: 0,
              background: 'linear-gradient(to bottom, rgba(0,0,0,0.2), rgba(0,0,0,0.7))',
              zIndex: 2
            }}></div>
            
            {/* Background Image */}
            <img 
              src={preset.imageSrc} 
              alt={preset.name} 
              className="card-background" 
              style={{
                position: 'absolute',
                inset: 0,
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                zIndex: 1
              }}
            />
          </div>
        ))}
      </div>
    </div>

    {/* Category pills */}
    <div className="category-rows" style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '10px',
      marginBottom: '24px'
    }}>
      <div className="category-row" style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        flexWrap: 'wrap'
      }}>
        {CATEGORIES.slice(0, isSmallMobile ? 3 : 4).map(category => (
          <button 
            key={category.id}
            className={`category-pill ${selectedCategory?.id === category.id ? 'active' : ''} ${category.className || ''}`}
            style={{
              background: category.gradient,
              color: 'white',
              border: 'none',
              padding: '8px 14px',
              borderRadius: '18px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              fontSize: '13px',
              fontWeight: '500',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
            }}
            onClick={() => handleCategorySelect(category)}
          >
            <span className="pill-icon">{category.icon}</span>
            <span className="pill-text">{category.name}</span>
          </button>
        ))}
      </div>
      
      <div className="category-row" style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '10px',
        flexWrap: 'wrap'
      }}>
        {CATEGORIES.slice(isSmallMobile ? 3 : 4).map(category => (
          <button 
            key={category.id}
            className={`category-pill ${selectedCategory?.id === category.id ? 'active' : ''} ${category.className || ''}`}
            style={{
              background: category.gradient,
              color: 'white',
              border: 'none',
              padding: '8px 14px',
              borderRadius: '18px',
              display: 'flex',
              alignItems: 'center',
              gap: '6px',
              fontSize: '13px',
              fontWeight: '500',
              cursor: 'pointer',
              transition: 'all 0.3s ease',
              boxShadow: '0 2px 8px rgba(0, 0, 0, 0.15)'
            }}
            onClick={() => handleCategorySelect(category)}
          >
            <span className="pill-icon">{category.icon}</span>
            <span className="pill-text">{category.name}</span>
          </button>
        ))}
      </div>
    </div>

    {/* Custom meditation button */}
    <div className="custom-meditation-container" style={{
      width: '100%',
      marginBottom: '40px',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <button 
        className="custom-meditation-button"
        onClick={() => {
          // Just pick the first category as a starting point
          handleCategorySelect(CATEGORIES[0]);
        }}
        style={{
          background: 'linear-gradient(45deg, #2193b0, #6dd5ed)',
          color: 'white',
          border: 'none',
          padding: '14px 24px',
          borderRadius: '12px',
          fontSize: '15px',
          fontWeight: '600',
          cursor: 'pointer',
          boxShadow: '0 4px 12px rgba(33, 147, 176, 0.3)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          gap: '8px',
          width: '100%',
          maxWidth: '320px',
          transition: 'transform 0.2s ease, box-shadow 0.2s ease'
        }}
      >
        <span>🧘</span>
        Create Custom Meditation
      </button>
    </div>
  </div>
);
};

export default CreateStep;