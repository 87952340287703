import React, { useRef, useEffect, useState, useCallback } from 'react';
import WaveSurfer from 'wavesurfer.js';
import RegionsPlugin from 'wavesurfer.js/dist/plugin/wavesurfer.regions.min.js';
import TimelinePlugin from 'wavesurfer.js/dist/plugin/wavesurfer.timeline.js';

/**
 * ResponsiveWaveSurfer - A robustly responsive WaveSurfer component with
 * improved initialization coordination to prevent duplicate instances
 */
const ResponsiveWaveSurfer = ({
  audioUrl,
  isPlaying,
  onReady,
  onError,
  onTimeUpdate,
  zoom = 50,
  height = 80,
  waveColor = 'rgba(125, 18, 255, 0.3)',
  progressColor = '#7d12ff',
  showTimeline = true,
  onPositionChange,
  containerRef,
  timelineContainerRef,
  volume = 1,
  muted = false,
  globalAudioRef
}) => {
  // State
  const [wavesurfer, setWavesurfer] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);
  const [hasFailed, setHasFailed] = useState(false);
  const [initAttempts, setInitAttempts] = useState(0);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);
  
  // Coordination to prevent duplicates
  const [instanceId] = useState(`wavesurfer-${Date.now()}-${Math.floor(Math.random() * 1000)}`);

  // Refs
  const internalContainerRef = useRef(null);
  const internalTimelineRef = useRef(null);
  const dimensionCheckIntervalRef = useRef(null);
  const loadTimerRef = useRef(null);
  const blobUrlsRef = useRef([]);
  const isMountedRef = useRef(true);
  const lastResizeDimensionsRef = useRef({ width: 0, height: 0 });
  const audioElementRef = useRef(null);
  const abortControllerRef = useRef(null);
  const isDestroyingRef = useRef(false);
  
  const activeContainerRef = containerRef || internalContainerRef;
  const activeTimelineRef = timelineContainerRef || internalTimelineRef;

  // Function to check if there's already an active wavesurfer instance for this container
  const checkExistingWavesurfer = useCallback(() => {
    if (!activeContainerRef.current) return null;
    
    // Check if the container already has a wavesurfer instance
    const container = activeContainerRef.current;
    
    if (container._wavesurfer) {
      console.log("WaveSurfer: Container already has a wavesurfer instance, using it");
      return container._wavesurfer;
    }
    
    if (window._activeSurfers && window._activeSurfers[container.id]) {
      console.log("WaveSurfer: Found existing instance for container, using it");
      return window._activeSurfers[container.id];
    }
    
    return null;
  }, [activeContainerRef]);

  // Function to create and validate container
  const validateContainer = useCallback(() => {
    const container = activeContainerRef.current;
    if (!container) return null;
    
    console.log("WaveSurfer: Validating container dimensions");
    const rect = container.getBoundingClientRect();
    console.log(`WaveSurfer: Container dimensions: ${rect.width}x${rect.height}`);
    setContainerWidth(rect.width);
    setContainerHeight(rect.height);
    
    if (rect.width < 100 || rect.height < 30) {
      console.log("WaveSurfer: Container dimensions too small, applying forced styling");
      container.style.setProperty('display', 'block', 'important');
      container.style.setProperty('visibility', 'visible', 'important');
      container.style.setProperty('width', '100%', 'important');
      container.style.setProperty('min-width', '300px', 'important');
      container.style.setProperty('height', `${height}px`, 'important');
      container.style.setProperty('min-height', `${height}px`, 'important');
      
      void container.offsetHeight;
      void container.offsetWidth;
      
      if (!container.id) {
        container.id = `waveform-container-${Date.now()}`;
      }
      
      const newRect = container.getBoundingClientRect();
      console.log(`WaveSurfer: Container dimensions after fix: ${newRect.width}x${newRect.height}`);
      setContainerWidth(newRect.width);
      setContainerHeight(newRect.height);
      return newRect.width >= 100 && newRect.height >= 30;
    }
    
    return true;
  }, [activeContainerRef, height]);

  // Initialize WaveSurfer
  const initializeWaveSurfer = useCallback(() => {
    setInitAttempts(prev => prev + 1);
    
    // Check for existing instance first
    const existingInstance = checkExistingWavesurfer();
    if (existingInstance) {
      setWavesurfer(existingInstance);
      setIsInitialized(true);
      return existingInstance;
    }
    
    if (wavesurfer) {
      console.log("WaveSurfer: Already initialized, skipping");
      return wavesurfer;
    }
    
    if (!validateContainer()) {
      console.warn("WaveSurfer: Container validation failed, deferring initialization");
      return null;
    }
    
    const container = activeContainerRef.current;
    if (!container) {
      console.error("WaveSurfer: No container reference available");
      return null;
    }
    
    try {
      console.log("WaveSurfer: Initializing with container dimensions:", 
                  container.offsetWidth, "x", container.offsetHeight);
      
      const plugins = [];
      
      if (showTimeline && activeTimelineRef.current) {
        plugins.push(
          TimelinePlugin.create({
            container: activeTimelineRef.current,
            primaryLabelInterval: 10,
            secondaryLabelInterval: 5,
            primaryColor: 'rgba(255, 255, 255, 0.8)',
            secondaryColor: 'rgba(255, 255, 255, 0.4)',
            primaryFontColor: 'rgba(255, 255, 255, 0.8)',
            secondaryFontColor: 'rgba(255, 255, 255, 0.4)',
            fontFamily: 'Arial',
            fontSize: 12,
            timeInterval: 1
          })
        );
      }
      
      plugins.push(RegionsPlugin.create({}));
      
      const sharedAudioElement = globalAudioRef?.current?._audioElement;
      
      const wsOptions = {
        container: container,
        waveColor: waveColor,
        progressColor: progressColor,
        cursorColor: '#ffffff',
        barWidth: 2,
        barRadius: 3,
        cursorWidth: 1,
        height: height,
        barGap: 2,
        responsive: true,
        normalize: true,
        pixelRatio: 1,
        scrollParent: true,
        minPxPerSec: zoom,
        hideScrollbar: false,
        forceDecode: true,
        mediaControls: false,
        autoCenter: true,
        plugins: plugins
      };
      
      let wsInstance;
      if (sharedAudioElement) {
        console.log("WaveSurfer: Using shared audio element");
        wsOptions.backend = 'MediaElement';
        wsOptions.media = sharedAudioElement;
        wsInstance = WaveSurfer.create(wsOptions);
        audioElementRef.current = sharedAudioElement;
      } else {
        console.log("WaveSurfer: Using standard initialization");
        wsInstance = WaveSurfer.create(wsOptions);
      }
      
      // Store instance id and register globally
      wsInstance._instanceId = instanceId;
      container._wavesurfer = wsInstance;
      
      // Store in global registry for coordination
      if (!window._activeSurfers) window._activeSurfers = {};
      window._activeSurfers[container.id] = wsInstance;
      
      wsInstance.on('ready', function() {
        if (!isMountedRef.current) return;
        console.log("WaveSurfer: Ready event fired");
        setIsReady(true);
        setIsInitialized(true);
        setHasFailed(false);
        
        if (loadTimerRef.current) {
          clearTimeout(loadTimerRef.current);
          loadTimerRef.current = null;
        }
        
        if (onReady) {
          try {
            onReady({
              duration: wsInstance.getDuration(),
              wavesurferReady: true
            });
          } catch (callbackError) {
            console.error("WaveSurfer: Error in onReady callback:", callbackError);
          }
        }
      });
      
      wsInstance.on('error', function(error) {
        if (!isMountedRef.current || isDestroyingRef.current) return;
        console.error("WaveSurfer: Error event:", error);
        
        if (error && (error.name === 'AbortError' || error.message?.includes('abort'))) {
          console.log("WaveSurfer: AbortError - normal during cleanup");
          return;
        }
        
        setHasFailed(true);
        
        if (onError) {
          try {
            onError(error);
          } catch (callbackError) {
            console.error("WaveSurfer: Error in onError callback:", callbackError);
          }
        }
      });
      
      wsInstance.on('audioprocess', function(time) {
        if (!isMountedRef.current) return;
        if (onTimeUpdate) {
          onTimeUpdate(time);
        }
      });
      
      wsInstance.on('seek', function(progress) {
        if (!isMountedRef.current) return;
        if (onPositionChange) {
          const position = progress * wsInstance.getDuration();
          onPositionChange(position);
        }
      });
      
      if (typeof volume === 'number') {
        wsInstance.setVolume(volume);
      }
      
      if (typeof muted === 'boolean') {
        wsInstance.setMute(muted);
      }
      
      setWavesurfer(wsInstance);
      setIsInitialized(true);
      
      loadTimerRef.current = setTimeout(() => {
        if (isMountedRef.current && !isReady) {
          console.warn("WaveSurfer: Load timeout reached, forcing ready state");
          setIsReady(true);
        }
      }, 8000);
      
      console.log("WaveSurfer: Initialization complete");
      return wsInstance;
    } catch (err) {
      console.error("WaveSurfer: Error during initialization:", err);
      setHasFailed(true);
      
      if (onError) {
        try {
          onError(err);
        } catch (callbackError) {
          console.error("WaveSurfer: Error in onError callback:", callbackError);
        }
      }
      
      return null;
    }
  }, [
    wavesurfer, 
    validateContainer, 
    activeContainerRef, 
    activeTimelineRef, 
    showTimeline, 
    waveColor, 
    progressColor, 
    height, 
    zoom, 
    onReady, 
    onError, 
    onTimeUpdate, 
    onPositionChange, 
    volume, 
    muted, 
    globalAudioRef,
    instanceId,
    isReady,
    checkExistingWavesurfer
  ]);
  
  const safeLoadAudio = useCallback((url, wsInstance) => {
    if (!url || !wsInstance) return;
    
    console.log("WaveSurfer: Loading URL:", url);
    
    // Cancel any previous load
    if (abortControllerRef.current) {
      try {
        abortControllerRef.current.abort();
      } catch (e) { 
        // Ignore abort errors
      }
    }
    
    // Create a new abort controller
    abortControllerRef.current = new AbortController();
    
    try {
      wsInstance.pause();
      wsInstance.load(url);
      
      const timeoutId = setTimeout(() => {
        if (isMountedRef.current && !isReady) {
          console.warn("WaveSurfer: Load timeout, forcing ready state");
          setIsReady(true);
        }
      }, 10000);
      
      return () => clearTimeout(timeoutId);
    } catch (err) {
      console.error("WaveSurfer: Error loading audio:", err);
      
      if (onError) {
        try {
          onError(err);
        } catch (callbackError) {
          console.error("WaveSurfer: Error in onError callback:", callbackError);
        }
      }
    }
  }, [isReady, onError]);
  
  const handleResize = useCallback(() => {
    if (!wavesurfer || !activeContainerRef.current) return;
    
    const container = activeContainerRef.current;
    const rect = container.getBoundingClientRect();
    
    if (Math.abs(rect.width - lastResizeDimensionsRef.current.width) < 5 &&
        Math.abs(rect.height - lastResizeDimensionsRef.current.height) < 5) {
      return;
    }
    
    console.log(`WaveSurfer: Resize detected: ${rect.width}x${rect.height}`);
    
    lastResizeDimensionsRef.current = {
      width: rect.width,
      height: rect.height
    };
    
    setContainerWidth(rect.width);
    setContainerHeight(rect.height);
    
    if (rect.width < 100 || rect.height < 30) {
      validateContainer();
    }
    
    try {
      wavesurfer.drawer.containerWidth = rect.width;
      wavesurfer.drawer.width = rect.width;
      wavesurfer.drawer.height = rect.height;
      wavesurfer.drawBuffer();
      console.log("WaveSurfer: Container resized and buffer redrawn");
    } catch (err) {
      console.warn("WaveSurfer: Error during resize handling:", err);
    }
  }, [wavesurfer, activeContainerRef, validateContainer]);
  
  // Initial setup and cleanup
  useEffect(() => {
    isMountedRef.current = true;
    validateContainer();
    
    dimensionCheckIntervalRef.current = setInterval(() => {
      if (!isMountedRef.current) return;
      
      const isValid = validateContainer();
      
      if (isValid && !isInitialized && initAttempts < 3) {
        initializeWaveSurfer();
      }
      
      if (isInitialized || initAttempts >= 3) {
        clearInterval(dimensionCheckIntervalRef.current);
        dimensionCheckIntervalRef.current = null;
      }
    }, 500);
    
    return () => {
      isMountedRef.current = false;
      isDestroyingRef.current = true;
      
      if (dimensionCheckIntervalRef.current) {
        clearInterval(dimensionCheckIntervalRef.current);
        dimensionCheckIntervalRef.current = null;
      }
      
      if (loadTimerRef.current) {
        clearTimeout(loadTimerRef.current);
        loadTimerRef.current = null;
      }
      
      if (abortControllerRef.current) {
        try {
          abortControllerRef.current.abort();
        } catch (e) {
          // Ignore abort errors
        }
      }
      
      blobUrlsRef.current.forEach(url => {
        try {
          URL.revokeObjectURL(url);
        } catch (e) {
          console.warn("WaveSurfer: Error revoking blob URL:", e);
        }
      });
      
      // Clean up container reference
      if (activeContainerRef.current) {
        delete activeContainerRef.current._wavesurfer;
      }
      
      // Remove from global registry
      if (window._activeSurfers && activeContainerRef.current) {
        delete window._activeSurfers[activeContainerRef.current.id];
      }
    };
  }, [validateContainer, isInitialized, initAttempts, initializeWaveSurfer]);
  
  // Set up resize observer
  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      handleResize();
    });
    
    if (activeContainerRef.current) {
      resizeObserver.observe(activeContainerRef.current);
    }
    
    window.addEventListener('resize', handleResize);
    
    return () => {
      resizeObserver.disconnect();
      window.removeEventListener('resize', handleResize);
    };
  }, [activeContainerRef, handleResize]);
  
  // Initialize WaveSurfer if container is valid
  useEffect(() => {
    if (!isInitialized && initAttempts < 3) {
      const isValid = validateContainer();
      
      if (isValid) {
        console.log("WaveSurfer: Container validated, initializing");
        initializeWaveSurfer();
      } else {
        console.log("WaveSurfer: Container not valid, deferring initialization");
      }
    }
  }, [isInitialized, initAttempts, validateContainer, initializeWaveSurfer]);
  
  // Load audio URL when available
  useEffect(() => {
    if (!audioUrl || !wavesurfer) return;
    safeLoadAudio(audioUrl, wavesurfer);
  }, [audioUrl, wavesurfer, safeLoadAudio]);
  
  // Handle play/pause state
  useEffect(() => {
    if (!wavesurfer || !isReady) return;
    
    if (isPlaying) {
      try {
        wavesurfer.play();
      } catch (err) {
        console.warn("WaveSurfer: Error playing:", err);
      }
    } else {
      try {
        wavesurfer.pause();
      } catch (err) {
        console.warn("WaveSurfer: Error pausing:", err);
      }
    }
  }, [isPlaying, wavesurfer, isReady]);
  
  // Handle zoom changes
  useEffect(() => {
    if (!wavesurfer) return;
    
    try {
      wavesurfer.zoom(zoom);
    } catch (err) {
      console.warn("WaveSurfer: Error setting zoom:", err);
    }
  }, [zoom, wavesurfer]);
  
  // Handle volume changes
  useEffect(() => {
    if (!wavesurfer) return;
    
    try {
      wavesurfer.setVolume(volume);
    } catch (err) {
      console.warn("WaveSurfer: Error setting volume:", err);
    }
  }, [volume, wavesurfer]);
  
  // Handle mute state changes
  useEffect(() => {
    if (!wavesurfer) return;
    
    try {
      wavesurfer.setMute(muted);
    } catch (err) {
      console.warn("WaveSurfer: Error setting mute state:", err);
    }
  }, [muted, wavesurfer]);
  
  // Destroy wavesurfer on unmount
  useEffect(() => {
    return () => {
      if (wavesurfer) {
        try {
          isDestroyingRef.current = true;
          wavesurfer.destroy();
        } catch (err) {
          console.warn("WaveSurfer: Error destroying instance:", err);
        }
      }
    };
  }, [wavesurfer]);
  
  // If this component shouldn't render the container itself, just return null
  if (containerRef) {
    return null;
  }
  
  // Otherwise, render the container and timeline
  return (
    <div className="responsive-wavesurfer-wrapper">
      <div 
        ref={internalContainerRef}
        className="waveform-container"
        style={{
          width: '100%',
          minWidth: '300px',
          height: `${height}px`,
          minHeight: `${height}px`,
          backgroundColor: 'rgba(0,0,0,0.2)',
          borderRadius: '4px',
          position: 'relative',
          marginBottom: '8px',
          display: 'block',
          visibility: 'visible'
        }}
      />
      
      {showTimeline && (
        <div
          ref={internalTimelineRef}
          className="waveform-timeline"
          style={{
            width: '100%',
            height: '30px',
            backgroundColor: 'rgba(0,0,0,0.1)',
            borderRadius: '4px',
            marginBottom: '16px',
            display: 'block',
            visibility: 'visible'
          }}
        />
      )}
    </div>
  );
};

export default ResponsiveWaveSurfer;