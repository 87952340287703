/**
 * GlobalAudioStateIntegration.js
 * 
 * This utility bridges the gap between the GlobalAudioState singleton and 
 * the audio visualization components, ensuring proper audio analyzer initialization
 * and error recovery.
 */

import globalAudioState from './globalAudioState';

// Store analyzer references globally to prevent recreation
if (typeof window !== 'undefined' && !window._audioAnalyzerRefs) {
  window._audioAnalyzerRefs = {
    mainAnalyzer: null,
    fallbackAnalyzer: null,
    creationTime: null,
    audioContext: null,
    sourceNodes: new WeakMap(),
    connectedElements: new WeakMap()
  };
}

/**
 * Creates a robust audio analyzer that integrates with the global audio state
 */
export const createRobustAnalyzer = (options = {}) => {
  const {
    audioElement = null,
    fallbackOnly = false,
    onSuccess = null,
    onError = null
  } = options;
  
  // Use specific audio element or get from global state
  const targetAudioElement = audioElement || 
                            (globalAudioState && globalAudioState._audioElement) || 
                            (window.globalAudioState && window.globalAudioState._audioElement);
  
  // Return existing analyzer if available
  if (!fallbackOnly && window._audioAnalyzerRefs && window._audioAnalyzerRefs.mainAnalyzer) {
    if (onSuccess) onSuccess(window._audioAnalyzerRefs.mainAnalyzer);
    return window._audioAnalyzerRefs.mainAnalyzer;
  }
  
  // Fallback if no audio element
  if (!targetAudioElement) {
    console.warn("No audio element available for analyzer");
    if (onError) onError(new Error("No audio element available"));
    return createFallbackAnalyzer();
  }
  
  try {
    // Create audio context
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    if (!AudioContext) {
      console.warn("AudioContext not supported in this browser");
      if (onError) onError(new Error("AudioContext not supported"));
      return createFallbackAnalyzer();
    }
    
    // Use existing or create new context
    let audioContext = window._audioAnalyzerRefs?.audioContext;
    
    if (!audioContext) {
      audioContext = new AudioContext();
      if (window._audioAnalyzerRefs) {
        window._audioAnalyzerRefs.audioContext = audioContext;
      }
    }
    
    // Create analyzer node
    const analyzer = audioContext.createAnalyser();
    analyzer.fftSize = 2048;
    analyzer.smoothingTimeConstant = 0.85;
    analyzer.minDecibels = -90;
    analyzer.maxDecibels = -10;
    
    // Create data arrays for visualization
    analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
    analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
    
    // Track and attempt connection
    let connected = false;
    let sourceNode = null;
    
    try {
      // Check if already connected
      if (window._audioAnalyzerRefs && window._audioAnalyzerRefs.connectedElements.has(targetAudioElement)) {
        sourceNode = window._audioAnalyzerRefs.connectedElements.get(targetAudioElement);
        sourceNode.connect(analyzer);
        analyzer.connect(audioContext.destination);
        connected = true;
      } else {
        // Create new connection
        sourceNode = audioContext.createMediaElementSource(targetAudioElement);
        sourceNode.connect(analyzer);
        analyzer.connect(audioContext.destination);
        connected = true;
        
        // Store connection
        if (window._audioAnalyzerRefs) {
          window._audioAnalyzerRefs.connectedElements.set(targetAudioElement, sourceNode);
          window._audioAnalyzerRefs.sourceNodes.set(analyzer, sourceNode);
        }
      }
    } catch (err) {
      // Handle "already connected" error
      if (err.message && err.message.includes('already connected')) {
        console.log("Audio element already connected, using oscillator");
        
        try {
          // Create silent oscillator for visualization
          const oscillator = audioContext.createOscillator();
          oscillator.frequency.value = 0; // Silent
          oscillator.connect(analyzer);
          analyzer.connect(audioContext.destination);
          oscillator.start();
          connected = true;
        } catch (oscError) {
          console.warn("Failed to create oscillator fallback:", oscError);
        }
      } else {
        console.error("Failed to connect audio element:", err);
      }
    }
    
    // Save reference globally for reuse
    if (connected && window._audioAnalyzerRefs) {
      window._audioAnalyzerRefs.mainAnalyzer = analyzer;
      window._audioAnalyzerRefs.creationTime = Date.now();
    }
    
    // Resume context if suspended
    if (audioContext.state === 'suspended') {
      audioContext.resume().catch(err => {
        console.warn("Error resuming audio context:", err);
      });
    }
    
    if (connected) {
      if (onSuccess) onSuccess(analyzer);
      return analyzer;
    } else {
      if (onError) onError(new Error("Failed to connect analyzer"));
      return createFallbackAnalyzer();
    }
  } catch (error) {
    console.error("Error creating audio analyzer:", error);
    if (onError) onError(error);
    return createFallbackAnalyzer();
  }
};

/**
 * Creates a fallback analyzer with simulated data for visualization
 */
function createFallbackAnalyzer() {
  if (window._audioAnalyzerRefs && window._audioAnalyzerRefs.fallbackAnalyzer) {
    return window._audioAnalyzerRefs.fallbackAnalyzer;
  }
  
  try {
    // Create context
    const AudioContext = window.AudioContext || window.webkitAudioContext;
    const context = new AudioContext();
    
    // Create analyzer node
    const analyzer = context.createAnalyser();
    analyzer.fftSize = 1024;
    analyzer.smoothingTimeConstant = 0.8;
    
    // Create data arrays
    analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
    analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
    
    // Create oscillator for data generation
    const oscillator = context.createOscillator();
    oscillator.frequency.value = 0;
    oscillator.connect(analyzer);
    analyzer.connect(context.destination);
    oscillator.start();
    
    // Override getByteFrequencyData to provide simulated data
    const originalGetByteFrequencyData = analyzer.getByteFrequencyData;
    analyzer.getByteFrequencyData = function(dataArray) {
      originalGetByteFrequencyData.call(this, dataArray);
      
      // Add simulated data for visualization
      const time = Date.now() / 1000;
      for (let i = 0; i < dataArray.length; i++) {
        const value = 30 + 15 * Math.sin(time + i * 0.01) + 10 * Math.sin(time * 0.5 + i * 0.02);
        dataArray[i] = Math.min(255, Math.max(0, value));
      }
    };
    
    // Override getByteTimeDomainData for waveform visualization
    const originalGetByteTimeDomainData = analyzer.getByteTimeDomainData;
    analyzer.getByteTimeDomainData = function(dataArray) {
      originalGetByteTimeDomainData.call(this, dataArray);
      
      // Add simulated waveform data
      const time = Date.now() / 1000;
      for (let i = 0; i < dataArray.length; i++) {
        const phase = (i / dataArray.length) * Math.PI * 2;
        const value = 128 + 30 * Math.sin(time * 3 + phase) + 10 * Math.sin(time * 5 + phase * 2);
        dataArray[i] = Math.min(255, Math.max(0, value));
      }
    };
    
    if (window._audioAnalyzerRefs) {
      window._audioAnalyzerRefs.fallbackAnalyzer = analyzer;
    }
    
    return analyzer;
  } catch (error) {
    console.error("Error creating fallback analyzer:", error);
    return null;
  }
}

/**
 * Initializes audio analyzer with integration to global audio state
 */
export const initializeAnalyzerNode = (options = {}) => {
  const {
    analyzerRef,
    setAnalyzerNode,
    setAudioConnected,
    audioElement = null,
    forceNew = false,
    onInitialized = null
  } = options;
  
  // Get appropriate audio element
  const targetAudioElement = audioElement || 
                            (globalAudioState && globalAudioState._audioElement) || 
                            (window.globalAudioState && window.globalAudioState._audioElement);
                            
  // Skip if no audio element and we need direct connection
  if (!targetAudioElement && !forceNew) {
    console.warn("No audio element available for analyzer initialization");
    return false;
  }
  
  // Use existing analyzer if available
  if (!forceNew && window._audioAnalyzerRefs && window._audioAnalyzerRefs.mainAnalyzer) {
    if (analyzerRef) {
      analyzerRef.current = window._audioAnalyzerRefs.mainAnalyzer;
    }
    if (setAnalyzerNode) {
      setAnalyzerNode(window._audioAnalyzerRefs.mainAnalyzer);
    }
    if (setAudioConnected) {
      setAudioConnected(true);
    }
    if (onInitialized) {
      onInitialized(window._audioAnalyzerRefs.mainAnalyzer);
    }
    return true;
  }
  
  // Create new robust analyzer
  const analyzer = createRobustAnalyzer({
    audioElement: targetAudioElement,
    onSuccess: (analyzer) => {
      if (analyzerRef) {
        analyzerRef.current = analyzer;
      }
      if (setAnalyzerNode) {
        setAnalyzerNode(analyzer);
      }
      if (setAudioConnected) {
        setAudioConnected(true);
      }
      if (onInitialized) {
        onInitialized(analyzer);
      }
    },
    onError: (error) => {
      console.error("Error initializing analyzer:", error);
      
      // Use fallback analyzer
      const fallbackAnalyzer = createFallbackAnalyzer();
      if (fallbackAnalyzer) {
        if (analyzerRef) {
          analyzerRef.current = fallbackAnalyzer;
        }
        if (setAnalyzerNode) {
          setAnalyzerNode(fallbackAnalyzer);
        }
        if (setAudioConnected) {
          setAudioConnected(true);
        }
        if (onInitialized) {
          onInitialized(fallbackAnalyzer);
        }
      }
    }
  });
  
  return !!analyzer;
};

/**
 * Creates a reliable audio element with proper integration to global state
 */
export const createReliableAudioElement = (url, options = {}) => {
  const { 
    useGlobalAudio = true,
    audioElementRef = null,
    onError = null
  } = options;
  
  // Try to use existing element from global state
  if (useGlobalAudio) {
    if (globalAudioState && globalAudioState._audioElement) {
      const sharedAudio = globalAudioState._audioElement;
      
      // Update URL if needed
      if (url && sharedAudio.src !== url) {
        try {
          sharedAudio.pause();
          sharedAudio.src = url;
          sharedAudio.load();
        } catch (e) {
          console.warn("Error updating shared audio element URL:", e);
        }
      }
      
      // Update ref if provided
      if (audioElementRef) {
        audioElementRef.current = sharedAudio;
      }
      
      return sharedAudio;
    }
    
    // Use global audio from window if available
    if (window.globalAudioState && window.globalAudioState._audioElement) {
      const sharedAudio = window.globalAudioState._audioElement;
      
      // Update URL if needed
      if (url && sharedAudio.src !== url) {
        try {
          sharedAudio.pause();
          sharedAudio.src = url;
          sharedAudio.load();
        } catch (e) {
          console.warn("Error updating shared audio element URL:", e);
        }
      }
      
      // Update ref if provided
      if (audioElementRef) {
        audioElementRef.current = sharedAudio;
      }
      
      return sharedAudio;
    }
  }
  
  // Create new audio element
  try {
    const audioEl = new Audio();
    audioEl.crossOrigin = "anonymous";
    audioEl.preload = "auto";
    
    // Set error handler
    audioEl.onerror = (err) => {
      console.warn("Audio element error:", err);
      if (onError) onError(err);
    };
    
    // Set source if available
    if (url) {
      audioEl.src = url;
      audioEl.load();
    }
    
    // Store in ref if provided
    if (audioElementRef) {
      audioElementRef.current = audioEl;
    }
    
    // Store in global state if needed
    if (useGlobalAudio) {
      if (globalAudioState) {
        globalAudioState._audioElement = audioEl;
      }
      if (window.globalAudioState) {
        window.globalAudioState._audioElement = audioEl;
      }
    }
    
    return audioEl;
  } catch (err) {
    console.error("Error creating audio element:", err);
    if (onError) onError(err);
    return null;
  }
};

/**
 * Enhances WaveSurfer with awareness of global audio state
 */
export const enhanceWaveSurfer = (wavesurfer, options = {}) => {
  if (!wavesurfer) return null;
  
  const {
    audioElement = null,
    container = null,
    useGlobalState = true
  } = options;
  
  try {
    // Store global references for future recovery
    if (container && container.id) {
      if (!window._wavesurferInstances) {
        window._wavesurferInstances = {};
      }
      window._wavesurferInstances[container.id] = wavesurfer;
    }
    
    // Set up shared audio element if available
    if (audioElement) {
      try {
        if (wavesurfer.backend && wavesurfer.backend.name === 'MediaElement') {
          wavesurfer.backend.setMediaElement(audioElement);
        }
      } catch (err) {
        console.warn("Error connecting WaveSurfer to audio element:", err);
      }
    } else if (useGlobalState) {
      // Try to get audio element from global state
      const globalAudioElement = 
        (globalAudioState && globalAudioState._audioElement) ||
        (window.globalAudioState && window.globalAudioState._audioElement);
        
      if (globalAudioElement) {
        try {
          if (wavesurfer.backend && wavesurfer.backend.name === 'MediaElement') {
            wavesurfer.backend.setMediaElement(globalAudioElement);
          }
        } catch (err) {
          console.warn("Error connecting WaveSurfer to global audio element:", err);
        }
      }
    }
    
    // Add resilience methods
    wavesurfer.safePlay = () => {
      try {
        wavesurfer.play();
        return true;
      } catch (err) {
        console.warn("Error playing WaveSurfer:", err);
        return false;
      }
    };
    
    wavesurfer.safePause = () => {
      try {
        wavesurfer.pause();
        return true;
      } catch (err) {
        console.warn("Error pausing WaveSurfer:", err);
        return false;
      }
    };
    
    wavesurfer.safeDestroy = () => {
      try {
        wavesurfer.pause();
        wavesurfer.destroy();
        return true;
      } catch (err) {
        console.warn("Error destroying WaveSurfer:", err);
        return false;
      }
    };
    
    return wavesurfer;
  } catch (err) {
    console.error("Error enhancing WaveSurfer:", err);
    return wavesurfer;
  }
};

export default {
  createRobustAnalyzer,
  initializeAnalyzerNode,
  createReliableAudioElement,
  enhanceWaveSurfer
};