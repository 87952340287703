// src/pages/MeditationApp/components/common/CustomToast.jsx
import React from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { 
  IoCheckmarkCircle,
  IoInformationCircle, 
  IoWarning, 
  IoClose, 
  IoAlertCircle 
} from 'react-icons/io5';

export const CustomToastContainer = () => (
    <ToastContainer
      position="bottom-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="dark"
      className="meditation-toast-container"
      toastClassName="meditation-toast"
      progressClassName="meditation-toast-progress"
      bodyClassName="meditation-toast-body"
      closeButton={CustomCloseButton}
      icon={false} // This disables the default icons
    />
  );

// Custom close button component
const CustomCloseButton = ({ closeToast }) => (
  <button 
    onClick={closeToast} 
    className="meditation-toast-close-button"
    aria-label="Close notification"
  >
    <IoClose size={18} />
  </button>
);

// Toast content component with icon
const ToastContent = ({ icon, message }) => (
    <div className="meditation-toast-content">
      <div className="meditation-toast-icon">{icon}</div>
      <div className="meditation-toast-message">{message}</div>
    </div>
);

// Custom toast functions
export const showToast = {
  success: (message, options = {}) => {
    return toast.success(
      <ToastContent 
        icon={<IoCheckmarkCircle size={22} />} 
        message={message} 
      />, 
      options
    );
  },
  
  info: (message, options = {}) => {
    return toast.info(
      <ToastContent 
        icon={<IoInformationCircle size={22} />} 
        message={message} 
      />, 
      options
    );
  },
  
  warning: (message, options = {}) => {
    return toast.warning(
      <ToastContent 
        icon={<IoWarning size={22} />} 
        message={message} 
      />, 
      options
    );
  },
  
  error: (message, options = {}) => {
    return toast.error(
      <ToastContent 
        icon={<IoAlertCircle size={22} />} 
        message={message} 
      />, 
      options
    );
  },
  
  loading: (message, options = {}) => {
    return toast.info(
      <ToastContent 
        icon={
          <div className="meditation-toast-loading-icon">
            <div className="meditation-toast-spinner"></div>
          </div>
        } 
        message={message} 
      />, 
      {
        autoClose: false,
        closeButton: false,
        ...options
      }
    );
  },
  
  // Updates existing toast by id
  update: (toastId, { render, type, ...options }) => {
    let icon;
    switch (type) {
      case 'success':
        icon = <IoCheckmarkCircle size={22} />;
        break;
      case 'info':
        icon = <IoInformationCircle size={22} />;
        break;
      case 'warning':
        icon = <IoWarning size={22} />;
        break;
      case 'error':
        icon = <IoAlertCircle size={22} />;
        break;
      case 'loading':
        icon = (
          <div className="meditation-toast-loading-icon">
            <div className="meditation-toast-spinner"></div>
          </div>
        );
        break;
      default:
        icon = null;
    }
    
    toast.update(toastId, {
      render: icon ? <ToastContent icon={icon} message={render} /> : render,
      type: type === 'loading' ? 'info' : type,
      ...options
    });
  },
  
  // Additional utility methods
  dismiss: toast.dismiss,
  isActive: toast.isActive,
};

export default CustomToastContainer;