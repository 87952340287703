import React, { useState, useEffect, createContext, useContext } from 'react';
import { AnimatePresence } from 'framer-motion';
import { Outlet } from 'react-router-dom';
import AppHeader from './AppHeader';
import Sidebar from './Sidebar';
import NotificationsPanel from './NotificationsPanel';
import { useTheme } from '../contexts/ThemeContext';
import './AppLayout.css';

// Create a context to share layout state with child components
export const LayoutContext = createContext({
  isSidebarOpen: false,
  setIsSidebarOpen: () => {},
  isNotificationsOpen: false,
  setIsNotificationsOpen: () => {},
  notifications: [],
  handleClearNotification: () => {},
  handleClearAllNotifications: () => {},
  handleMarkAsRead: () => {},
});

// Hook to use layout context
export const useLayout = () => useContext(LayoutContext);

const AppLayout = () => {
  // Always start with sidebar closed
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);
  const [notifications, setNotifications] = useState([
    { id: 1, type: 'info', title: 'Welcome!', message: 'Thank you for joining us.' },
  ]);

  // Notification handlers
  const handleClearNotification = (id) => {
    setNotifications((prev) => prev.filter((notification) => notification.id !== id));
  };

  const handleClearAllNotifications = () => {
    setNotifications([]);
  };

  const handleMarkAsRead = (id) => {
    setNotifications((prev) =>
      prev.map((notification) =>
        notification.id === id ? { ...notification, read: true } : notification
      )
    );
  };

  // REMOVED the auto-open effect for desktop
  // Instead, add a resize listener that DOESN'T change sidebar state
  useEffect(() => {
    const handleResize = () => {
      // Handle any other responsive UI adjustments here if needed
      // But DON'T change isSidebarOpen based on window size
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const contextValue = {
    isSidebarOpen,
    setIsSidebarOpen,
    isNotificationsOpen,
    setIsNotificationsOpen,
    notifications,
    handleClearNotification,
    handleClearAllNotifications,
    handleMarkAsRead,
  };

  return (
    <LayoutContext.Provider value={contextValue}>
      <div className="app-container">
        {/* Header always visible */}
        <AppHeader
          onToggleSidebar={() => {
            console.log('Toggling sidebar from AppHeader');
            setIsSidebarOpen((prev) => !prev);
          }}
          onToggleNotifications={() => setIsNotificationsOpen((prev) => !prev)}
          notificationsCount={notifications.length}
          isSidebarOpen={isSidebarOpen}
          isNotificationsOpen={isNotificationsOpen}
          onCloseNotifications={() => setIsNotificationsOpen(false)}
          notifications={notifications}
          onMarkAsRead={handleMarkAsRead}
          onClear={handleClearNotification}
          onClearAll={handleClearAllNotifications}
        />

        <div className="main-layout">
          <Sidebar
            isOpen={isSidebarOpen}
            onClose={() => setIsSidebarOpen(false)}
            notificationCount={notifications.length}
          />

          <main className={`content-area ${isSidebarOpen ? '' : 'collapsed'}`}>
            <Outlet />
            <AnimatePresence>
              {isNotificationsOpen && (
                <NotificationsPanel
                  notifications={notifications}
                  onMarkAsRead={handleMarkAsRead}
                  onClear={handleClearNotification}
                  onClearAll={handleClearAllNotifications}
                  onCloseNotifications={() => setIsNotificationsOpen(false)}
                />
              )}
            </AnimatePresence>
          </main>
        </div>
      </div>
    </LayoutContext.Provider>
  );
};

export default AppLayout;