import React, { useState, useRef, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

/**
 * UnifiedPromptToolbar Component for Meditation App
 * 
 * A streamlined toolbar for entering meditation prompts with voice
 * transcription and text input options.
 */
const UnifiedPromptToolbar = ({
  customPrompt,
  setCustomPrompt,
  onStartGuide,
  onRecordingComplete,
  onDirectGenerate,
  setParentTranscribing,
  disabled = false
}) => {
  // State
  const [isRecording, setIsRecording] = useState(false);
  const [isTranscribing, setIsTranscribing] = useState(false);
  const [isRecordingAnimation, setIsRecordingAnimation] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [transcriptionText, setTranscriptionText] = useState('');
  const [showTranscriptionPreview, setShowTranscriptionPreview] = useState(false);
  const [previewCountdown, setPreviewCountdown] = useState(0);
  
  // Refs
  const mediaRecorderRef = useRef(null);
  const audioChunksRef = useRef([]);
  const inputRef = useRef(null);
  const countdownTimerRef = useRef(null);
  
  // Determine if we are on mobile
  useEffect(() => {
    const checkMobile = () => setIsMobile(window.innerWidth <= 768);
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);
  
  // Handle expansion based on focus and content
  useEffect(() => {
    // Expand if there's content or the input is focused
    setIsExpanded(customPrompt.trim().length > 0 || isFocused || showTranscriptionPreview);
  }, [customPrompt, isFocused, showTranscriptionPreview]);
  
  // Cleanup on unmount
  useEffect(() => {
    return () => {
      if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
        mediaRecorderRef.current.stop();
      }
      if (mediaRecorderRef.current && mediaRecorderRef.current.stream) {
        mediaRecorderRef.current.stream.getTracks().forEach(track => track.stop());
      }
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
      }
    };
  }, []);
  
  // Start countdown timer for preview with fixed dependencies
  const startPreviewCountdown = useCallback((seconds) => {
    setPreviewCountdown(seconds);
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current);
    }
    
    countdownTimerRef.current = setInterval(() => {
      setPreviewCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownTimerRef.current);
          setShowTranscriptionPreview(false);
          
          // Automatically trigger generation when countdown reaches zero
          if (customPrompt.trim() && onDirectGenerate) {
            console.log("Countdown reached zero, triggering meditation generation...");
            
            // Show toast that we're generating
            toast.info("Generating meditation from your prompt...");
            
            // Call the direct generate function
            onDirectGenerate();
          } else {
            console.log("Cannot generate - customPrompt empty or onDirectGenerate not available", {
              promptLength: customPrompt?.trim()?.length,
              hasDirectGenerate: !!onDirectGenerate
            });
          }
          
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, [customPrompt, onDirectGenerate]);

  const startPreviewCountdownWithResult = useCallback((seconds, transcriptionResult) => {
    setPreviewCountdown(seconds);
    if (countdownTimerRef.current) {
      clearInterval(countdownTimerRef.current);
    }
    
    countdownTimerRef.current = setInterval(() => {
      setPreviewCountdown(prev => {
        if (prev <= 1) {
          clearInterval(countdownTimerRef.current);
          setShowTranscriptionPreview(false);
          
          // Automatically trigger generation when countdown reaches zero
          // Use the transcription result directly instead of reading from customPrompt state
          if (transcriptionResult && onDirectGenerate) {
            console.log("Countdown reached zero, triggering meditation generation with:", transcriptionResult);
            
            // Show toast that we're generating
            toast.info("Generating meditation from your prompt...");
            
            // Call the direct generate function - using the transcription result
            onDirectGenerate(transcriptionResult);
          } else {
            console.log("Cannot generate - transcription result missing or onDirectGenerate not available", {
              hasResult: !!transcriptionResult,
              hasDirectGenerate: !!onDirectGenerate
            });
          }
          
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  }, [onDirectGenerate]);
  
  const handleTranscription = useCallback(async (audioBlob) => {
    const formData = new FormData();
    formData.append('audio', audioBlob, 'audio_recording.wav');
    
    try {
      console.log('Starting transcription request...');
      
      // Show transcribing message to user
      toast.info("Transcribing your voice...", { autoClose: false, toastId: "transcribing" });
      
      // Update parent component's transcribing state if available
      if (setParentTranscribing) {
        setParentTranscribing(true);
      }
      
      const baseUrl = process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';
      const response = await fetch(`${baseUrl}/transcribe-whisper`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
      });
      
      // Remove the transcribing toast
      toast.dismiss("transcribing");
      
      if (!response.ok) {
        const errorData = await response.json();
        console.error('Whisper transcription error:', errorData);
        throw new Error(errorData.message || `HTTP error! status: ${response.status}`);
      }
      
      const data = await response.json();
      console.log('Whisper Transcription Response:', JSON.stringify(data, null, 2));
      
      // Extract transcription text - fixed to handle nested structure
      const result = data?.data?.text || '';
      
      if (result) {
        console.log('Transcription successful:', result);
        
        // Set the transcription text
        setTranscriptionText(result);
        
        // Update the prompt input 
        setCustomPrompt(result);
        
        // Show the preview
        setShowTranscriptionPreview(true);
        
        // Start a countdown to auto-hide the preview after 5 seconds
        // Pass the result directly to avoid closure issues
        startPreviewCountdownWithResult(5, result);
        
        // Show toast that we're ready to generate
        toast.success("Voice input received! Generate will start in 5 seconds.");
        
        // If the onRecordingComplete callback is provided, pass the transcription
        if (onRecordingComplete) {
          onRecordingComplete(result);
        }
        
      } else {
        console.error('No transcription in response:', data);
        throw new Error('No transcription available');
      }
    } catch (error) {
      console.error('Error handling transcription:', error);
      
      // Show error toast
      toast.error(`Transcription failed: ${error.message}. Please try again.`);
      
      // Reset states
      if (onRecordingComplete) {
        onRecordingComplete(null);
      }
    } finally {
      setIsRecordingAnimation(false);
      setIsTranscribing(false);
      
      // Update parent component's transcribing state if available
      if (setParentTranscribing) {
        setParentTranscribing(false);
      }
    }
  }, [setCustomPrompt, onRecordingComplete, setParentTranscribing, startPreviewCountdownWithResult]);
  
  const startRecording = useCallback(async () => {
    if (!isRecording && !disabled && !isTranscribing) {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ 
          audio: {
            echoCancellation: true,
            noiseSuppression: true,
            autoGainControl: true
          } 
        });
        
        // Use webm format with opus codec for better quality and compatibility
        mediaRecorderRef.current = new MediaRecorder(stream, {
          mimeType: 'audio/webm;codecs=opus',
        });
        
        mediaRecorderRef.current.ondataavailable = (event) => {
          if (event.data.size > 0) {
            audioChunksRef.current.push(event.data);
          }
        };
        
        mediaRecorderRef.current.start();
        setIsRecording(true);
      } catch (err) {
        console.error('Error starting the MediaRecorder:', err);
        toast.error(`Failed to start recording: ${err.message}. Please check your microphone settings and try again.`);
      }
    }
  }, [isRecording, disabled, isTranscribing]);
  
  const stopRecording = useCallback(() => {
    if (isRecording && mediaRecorderRef.current?.state !== 'inactive') {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      setIsRecordingAnimation(true);
      
      mediaRecorderRef.current.onstop = async () => {
        setIsTranscribing(true);
        
        // Update parent component's transcribing state if available
        if (setParentTranscribing) {
          setParentTranscribing(true);
        }
        
        // Convert audio chunks to webm format with opus codec
        const audioBlob = new Blob(audioChunksRef.current, { 
          type: 'audio/webm;codecs=opus' 
        });
        audioChunksRef.current = [];
        
        try {
          if (audioBlob.size > 0) {
            await handleTranscription(audioBlob);
          } else {
            throw new Error('No audio data recorded');
          }
        } catch (err) {
          console.error(err);
          toast.error(err.message);
        } finally {
          setIsTranscribing(false);
          
          // Update parent component's transcribing state if available
          if (setParentTranscribing) {
            setParentTranscribing(false);
          }
        }
      };
      
      mediaRecorderRef.current.onerror = (event) => {
        console.error('MediaRecorder error:', event.error);
        setIsTranscribing(false);
        
        // Update parent component's transcribing state if available
        if (setParentTranscribing) {
          setParentTranscribing(false);
        }
        
        setIsRecordingAnimation(false);
        toast.error('Recording error: ' + event.error.message);
      };
    }
  }, [isRecording, handleTranscription, setParentTranscribing]);
  
  const handleMouseDown = useCallback(() => {
    if (!isMobile) startRecording();
  }, [isMobile, startRecording]);
  
  const handleMouseUp = useCallback(() => {
    if (!isMobile) stopRecording();
  }, [isMobile, stopRecording]);
  
  const handleTouchStart = useCallback((e) => {
    e.preventDefault();
    if (isMobile) startRecording();
  }, [isMobile, startRecording]);
  
  const handleTouchEnd = useCallback((e) => {
    e.preventDefault();
    if (isMobile) stopRecording();
  }, [isMobile, stopRecording]);

  // Handle Enter key to trigger direct generation
  const handleKeyDown = useCallback((e) => {
    if (e.key === 'Enter' && !e.shiftKey && customPrompt.trim() && onDirectGenerate) {
      e.preventDefault();
      
      // Show toast that we're generating based on text input
      toast.info("Generating meditation from your prompt...");
      
      // Call the direct generate function passed from parent
      onDirectGenerate();
    }
  }, [customPrompt, onDirectGenerate]);

  // Handle generate button click
  const handleGenerateClick = useCallback(() => {
    if (customPrompt.trim() && onDirectGenerate) {
      // Hide the preview
      setShowTranscriptionPreview(false);
      if (countdownTimerRef.current) {
        clearInterval(countdownTimerRef.current);
      }
      
      // Show toast that we're generating
      toast.info("Generating meditation from your prompt...");
      
      // Call the direct generate function
      onDirectGenerate();
    }
  }, [customPrompt, onDirectGenerate]);

  // Handle input field focus and blur
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);
  
  return (
    <div className={`toolbar-container ${isExpanded ? 'expanded' : ''}`}>
      {/* Left Wave Button */}
      <button
        className="wave-button"
        onClick={onStartGuide}
        disabled={disabled}
        title="Guide"
      >
        <div className="wave-icon">
          <div className="wave-bar"></div>
          <div className="wave-bar tall"></div>
          <div className="wave-bar"></div>
        </div>
      </button>

      {/* Input Pill with Integrated Mic Button */}
      <div className={`input-pill ${isFocused ? 'focused' : ''}`}>
        {/* Plus sign and input field */}
        <div className="pill-content">
          <span className="plus-icon">+</span>
          <input
            ref={inputRef}
            type="text"
            className="prompt-input"
            placeholder={isExpanded ? "Type your prompt..." : "Create a meditation..."}
            value={customPrompt}
            onChange={(e) => setCustomPrompt(e.target.value)}
            disabled={disabled || isRecording}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
          />
        </div>
        
        {/* Generate button that appears when we have transcription */}
        {showTranscriptionPreview && customPrompt && (
          <button 
            className="generate-button"
            onClick={handleGenerateClick}
            disabled={disabled || !customPrompt.trim()}
            title="Generate meditation"
          >
            <span>Generate ({previewCountdown}s)</span>
          </button>
        )}
        
        {/* Integrated Mic Button */}
        <button
          className={`integrated-mic-button ${isRecording ? 'recording' : ''} ${isTranscribing ? 'transcribing' : ''}`}
          disabled={disabled || isTranscribing}
          onMouseDown={handleMouseDown}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseUp}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchCancel={stopRecording}
          onContextMenu={(e) => e.preventDefault()}
          aria-label={isRecording ? 'Stop recording' : 'Start recording'}
          aria-pressed={isRecording}
          title={isRecording ? 'Release to stop' : isTranscribing ? 'Transcribing...' : 'Hold to speak'}
        >
          {/* Minimalist mic icon */}
          <svg 
            className="mic-icon-svg" 
            width="20" 
            height="20" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="currentColor" 
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
            <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
            <line x1="12" y1="19" x2="12" y2="23"></line>
            <line x1="8" y1="23" x2="16" y2="23"></line>
          </svg>
          
          {/* Recording animation */}
          {isRecording && (
            <div className="recording-waves">
              <span></span>
              <span></span>
              <span></span>
            </div>
          )}
          
          {/* Transcribing indicator */}
          {isTranscribing && <div className="transcribing-dots"></div>}
        </button>
      </div>
      
      <style jsx>{`
        .toolbar-container {
          display: flex;
          align-items: center;
          width: 100%;
          transition: all 0.3s ease;
        }
        
        .wave-button {
          background-color: transparent;
          border: none;
          color: white;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 8px;
          border-radius: 8px;
          transition: background-color 0.2s ease;
        }
        
        .wave-button:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }
        
        .wave-icon {
          display: flex;
          align-items: flex-end;
          height: 24px;
          gap: 3px;
        }
        
        .wave-bar {
          width: 3px;
          height: 12px;
          background-color: #7d12ff;
          border-radius: 1.5px;
        }
        
        .wave-bar.tall {
          height: 18px;
        }
        
        .input-pill {
          display: flex;
          align-items: center;
          background-color: rgba(25, 25, 35, 0.6);
          border-radius: 20px;
          margin-left: 8px;
          flex: 1;
          padding: 8px 8px 8px 12px;
          transition: all 0.2s ease;
          border: 1px solid rgba(255, 255, 255, 0.08);
        }
        
        .input-pill.focused {
          border-color: rgba(125, 18, 255, 0.4);
          box-shadow: 0 0 0 2px rgba(125, 18, 255, 0.1);
        }
        
        .pill-content {
          display: flex;
          align-items: center;
          flex: 1;
        }
        
        .plus-icon {
          color: #7d12ff;
          font-size: 18px;
          font-weight: 500;
          margin-right: 8px;
        }
        
        .prompt-input {
          flex: 1;
          background: transparent;
          border: none;
          color: white;
          font-size: 14px;
          outline: none;
          padding: 0;
          width: 100%;
        }
        
        .prompt-input::placeholder {
          color: rgba(255, 255, 255, 0.5);
        }
        
        .generate-button {
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: #7d12ff;
          color: white;
          border: none;
          border-radius: 14px;
          padding: 6px 12px;
          font-size: 13px;
          font-weight: 500;
          margin-right: 6px;
          cursor: pointer;
          transition: all 0.2s ease;
        }
        
        .generate-button:hover {
          background-color: #9b4dff;
        }
        
        .integrated-mic-button {
          background-color: transparent;
          border: none;
          width: 36px;
          height: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          color: rgba(255, 255, 255, 0.7);
          cursor: pointer;
          position: relative;
          transition: all 0.2s ease;
        }
        
        .integrated-mic-button:hover {
          background-color: rgba(255, 255, 255, 0.1);
          color: white;
        }
        
        .integrated-mic-button.recording {
          color: #ff4d4d;
          background-color: rgba(255, 77, 77, 0.1);
        }
        
        .integrated-mic-button.transcribing {
          color: #7d12ff;
          background-color: rgba(125, 18, 255, 0.1);
        }
        
        .recording-waves {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 36px;
          height: 36px;
          border-radius: 50%;
        }
        
        .recording-waves span {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 50%;
          border: 1px solid rgba(255, 77, 77, 0.5);
          animation: recording-wave 2s infinite;
        }
        
        .recording-waves span:nth-child(2) {
          animation-delay: 0.5s;
        }
        
        .recording-waves span:nth-child(3) {
          animation-delay: 1s;
        }
        
        @keyframes recording-wave {
          0% {
            transform: scale(1);
            opacity: 1;
          }
          100% {
            transform: scale(1.8);
            opacity: 0;
          }
        }
        
        .transcribing-dots {
          position: absolute;
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 3px;
        }
        
        .transcribing-dots::before,
        .transcribing-dots::after {
          content: "";
          width: 4px;
          height: 4px;
          background-color: #7d12ff;
          border-radius: 50%;
          animation: transcribing-dots 1.5s infinite;
        }
        
        .transcribing-dots::after {
          animation-delay: 0.5s;
        }
        
        @keyframes transcribing-dots {
          0%, 100% {
            opacity: 0.2;
          }
          50% {
            opacity: 1;
          }
        }
      `}</style>
    </div>
  );
};

export default UnifiedPromptToolbar;