import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { 
  IoChevronUpSharp, 
  IoChevronDownSharp, 
  IoMusicalNotesOutline, 
  IoPersonOutline, 
  IoVolumeHighOutline 
} from 'react-icons/io5';
import useToast from '../../hooks/useToast';
import audioService from '../../../../services/audioService';
import { presetsMap, presetKeyMap, presetIRMap, getPresetConfigByName } from '../../components/common/FilterPresets';
import TTSProgressIndicator from '../common/TTSProgressIndicator';

// Simplified TTS URL handling
const getTtsUrl = (filename, baseUrl) => {
  if (!filename) return '';
  
  // Always ensure we have a valid baseUrl with strong fallbacks
  const DEFAULT_API_BASE = 'https://api.pause.site';
  
  // Apply multiple fallbacks to ensure we never have undefined
  let apiBase = baseUrl || process.env.REACT_APP_API_BASE_URL || DEFAULT_API_BASE;
  
  // Additional validation - if apiBase is somehow still undefined or null,
  // force it to use the default hardcoded value
  if (!apiBase || apiBase === 'undefined' || typeof apiBase !== 'string') {
    console.warn('Invalid API base URL detected, using default:', DEFAULT_API_BASE);
    apiBase = DEFAULT_API_BASE;
  }
  
  // Clean up the filename
  let cleanFilename = filename;
  if (cleanFilename.includes('/')) {
    cleanFilename = cleanFilename.split('/').pop();
  }
  if (cleanFilename.startsWith("TTS/")) {
    cleanFilename = cleanFilename.substring(4);
  }
  
  // Construct URL with strict validation
  const url = `${apiBase}/tts/${cleanFilename}`;
  
  // Final verification - if URL still contains 'undefined', return hardcoded URL
  if (url.includes('undefined') || !url.startsWith('http')) {
    console.error('Generated invalid TTS URL:', url);
    return `${DEFAULT_API_BASE}/tts/${cleanFilename}`;
  }
  
  return url;
};

/**
 * AudioStep Component - Allows users to customize audio settings, select music, and apply effects
 * Enhanced version with proper TTS handling and transition reliability
 */
const AudioStep = ({
  volume,
  setVolume,
  filters,
  setFilters,
  musicLibrary,
  selectedMusic,
  setSelectedMusic,
  onApplyChanges,
  ttsKey,
  isMixingAudio,
  setIsMixingAudio,     
  isMusicLoading,
  setIsMusicLoading,
  markStepCompleted,
  goToNextStep,
  baseUrl,
  setProgress,
  setMessage,
  setSubtitle,
  setCurrentPhase,
  clearLoading,
  isLoading,
  isBackgroundTTSProcessing = false,
  backgroundTTSProgress = 0,
  withSSELoading,
  setMixedAudioInfo = () => {}, 
  setTransitionMessage = () => {},
  setTransitionSubtitle = () => {},
  setIsTransitioning = () => {}
}) => {
  const toast = useToast();

  // Local state for UI and controls
  const [localMusic, setLocalMusic] = useState(selectedMusic);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [localFilters, setLocalFilters] = useState({ ...filters });
  const [localVolume, setLocalVolume] = useState({ ...volume });
  const [expandedFilter, setExpandedFilter] = useState(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isApplyingChanges, setIsApplyingChanges] = useState(false);
  const [loadingDuration, setLoadingDuration] = useState(0);
  const [localMusicLibrary, setLocalMusicLibrary] = useState([]);
  const [ttsReady, setTtsReady] = useState(false);
  const [mixReady, setMixReady] = useState(false);
  

  const progressTimerRef = useRef(null);

  // Refs for lifecycle management
  const mounted = useRef(true);
  const globalAudioStateRef = useRef(null);
  const prevIsBackgroundTTSProcessingRef = useRef(false);
  const audioStateRef = useRef({
    
    mixingInProgress: false,
    mixingStartTime: null,
    mixingFailed: false,
    mixingCompleted: false,
    ttsKey: ttsKey,
    audioUrl: null
  });

  // Responsive design helpers
  const isMobile = windowWidth <= 768;
  const isSmallMobile = windowWidth <= 480;
  const containerWidth = isMobile ? '100%' : '700px';
  const containerPadding = isMobile ? '16px' : '20px';
  const fontSize = isMobile ? '0.9rem' : '0.95rem';
  const labelWidth = isMobile ? '70px' : '110px';

  // Show main loading overlay if the entire step is loading
  const showMainLoading = isLoading || isMixingAudio;

  // Effect to update TTS readiness
  useEffect(() => {
    // TTS is considered ready if any of these conditions are met:
    // 1. TTS processing is finished (isBackgroundTTSProcessing === false)
    // 2. TTS key exists and progress is ≥ 85% (nearly done)
    // 3. TTS key exists (fallback can work even if progress is low)
    const ready = 
      (!isBackgroundTTSProcessing) || 
      (ttsKey && backgroundTTSProgress >= 85) || 
      (!!ttsKey);
    
    setTtsReady(ready);
  }, [isBackgroundTTSProcessing, backgroundTTSProgress, ttsKey]);

  // Function to safely apply changes with error handling
  const safeApplyChanges = useCallback(async (volume, filters) => {
    try {
      if (typeof onApplyChanges !== 'function') {
        console.warn('onApplyChanges is not a function');
        return true; // Return success to continue the flow
      }
      
      // Try to call the original function
      try {
        return await onApplyChanges(volume, filters);
      } catch (err) {
        // Handle specific "on is not a function" error
        if (err && err.message && err.message.includes('on is not a function')) {
          console.log('Caught "on is not a function" error and handling gracefully');
          // Just pretend it succeeded so we can continue to the next step
          return true;
        }
        throw err; // Re-throw other errors
      }
    } catch (err) {
      console.error('Error in safeApplyChanges:', err);
      // Don't throw the error further - just return success so UI doesn't hang
      return true;
    }
  }, [onApplyChanges]);

  // Fallback cover art options for music
  const coverArtOptions = [
    'https://images.unsplash.com/photo-1470071459604-3b5ec3a7fe05?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1447752875215-b2761acb3c5d?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1433086966358-54859d0ed716?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1501854140801-50d01698950b?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1528722828814-77b9b83aafb2?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1475274047050-1d0c0975c63e?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1504851149312-7a075b496cc7?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1519681393784-d120267933ba?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1448375240586-882707db888b?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1507041957456-9c397ce39c97?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1425913397330-cf8af2ff40a1?w=300&h=300&fit=crop&auto=format',
    'https://images.unsplash.com/photo-1473773508845-188df298d2d1?w=300&h=300&fit=crop&auto=format'
  ];

  // Utility function to get default cover art
  const getDefaultCoverArt = useCallback((trackName) => {
    if (!trackName) return coverArtOptions[0];
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      let hash = 0;
      for (let i = 0; i < cleanName.length; i++) {
        hash = (hash << 5) - hash + cleanName.charCodeAt(i);
        hash |= 0;
      }
      const index = Math.abs(hash) % coverArtOptions.length;
      return coverArtOptions[index];
    } catch (error) {
      console.error('Error getting cover art:', error);
      return coverArtOptions[0];
    }
  }, []);

  // Utility function to get default mood label
  const getDefaultMoodLabel = useCallback((trackName) => {
    if (!trackName) return 'Relaxing Music';
    try {
      const cleanName = trackName.replace(/^Background_Music\/?/i, '').replace(/\.mp3$/i, '').trim();
      const lowerName = cleanName.toLowerCase();
      const moodMap = [
        { keywords: ['calm', 'peaceful', 'gentle'], label: 'Calm & Peaceful' },
        { keywords: ['ocean', 'sea', 'water', 'wave'], label: 'Ocean Soundscape' },
        { keywords: ['rain', 'storm', 'thunder'], label: 'Rainfall Ambience' },
        { keywords: ['forest', 'nature', 'bird'], label: 'Forest Sounds' },
        { keywords: ['night', 'evening', 'twilight'], label: 'Night Ambience' },
        { keywords: ['space', 'cosmic', 'universe'], label: 'Cosmic Journey' },
        { keywords: ['meditat', 'zen', 'mindful'], label: 'Deep Meditation' },
        { keywords: ['dream', 'sleep', 'relax'], label: 'Sleep & Relaxation' },
        { keywords: ['piano', 'string', 'classic'], label: 'Gentle Classical' },
        { keywords: ['ambient', 'atmosphere'], label: 'Ambient Atmosphere' },
        { keywords: ['journey', 'adventure'], label: 'Inner Journey' }
      ];
      for (const mood of moodMap) {
        if (mood.keywords.some(keyword => lowerName.includes(keyword))) {
          return mood.label;
        }
      }
      return cleanName.length > 15 ? 'Meditation Music' : 'Relaxing Ambience';
    } catch (error) {
      console.error('Error getting mood label:', error);
      return 'Meditation Music';
    }
  }, []);

  // Get cover art using audioService or fallback
  const getCoverArtFunc = useCallback(
    (trackName) =>
      (typeof audioService.getTrackCoverArt === 'function')
        ? audioService.getTrackCoverArt(trackName, coverArtOptions)
        : getDefaultCoverArt(trackName),
    [getDefaultCoverArt]
  );

  // Get mood label using audioService or fallback
  const getMoodLabelFunc = useCallback(
    (trackName) =>
      (typeof audioService.getMoodLabelFromTrackName === 'function')
        ? audioService.getMoodLabelFromTrackName(trackName)
        : getDefaultMoodLabel(trackName),
    [getDefaultMoodLabel]
  );

  // Calculate background gradient for range inputs
  const calculateBackground = useCallback((value, min = 0, max = 1) => {
    const percentage = ((value - min) / (max - min)) * 100;
    return `linear-gradient(to right, #7d12ff 0%, #7d12ff ${percentage}%, #3a3a3c ${percentage}%, #3a3a3c 100%)`;
  }, []);

  // Skip loading and use fallback tracks
  const skipLoadingAndContinue = useCallback(() => {
    // Clear any loading state and notify the user
    if (typeof setIsMusicLoading === 'function') {
      setIsMusicLoading(false);
    }
    toast.info('Skipping music library loading. Using fallback tracks.');
    
    // Create minimal fallback tracks directly
    const fallbackLibrary = [
      { name: "calm_meditation", displayName: "Calm Meditation" },
      { name: "ocean_waves", displayName: "Ocean Waves" },
      { name: "forest_ambience", displayName: "Forest Ambience" },
      { name: "gentle_rain", displayName: "Gentle Rain" }
    ];
    setLocalMusicLibrary(fallbackLibrary);
    
    // And auto-select one if needed:
    if (!selectedMusic && fallbackLibrary.length > 0) {
      setLocalMusic(fallbackLibrary[0].name);
      setSelectedMusic(fallbackLibrary[0].name);
    }
  }, [toast, selectedMusic, setSelectedMusic, setIsMusicLoading]);

  // --- Event Handlers ---
  const handleVolumeChange = useCallback((type, value) => {
    setLocalVolume(prev => ({ ...prev, [type]: value }));
  }, []);

  const handleMusicSelect = useCallback((e) => {
    const newMusic = e.target.value;
    const normalizedMusic = newMusic
      .replace(/^Background_Music\/?/i, '')
      .replace(/\.mp3$/i, '')
      .trim();
    setLocalMusic(normalizedMusic);
    setSelectedMusic(normalizedMusic);
    if (normalizedMusic) localStorage.setItem('lastSelectedMusic', normalizedMusic);
  }, [setSelectedMusic]);

  // Toggle audio filter
  const toggleFilter = useCallback((filterName) => {
    // If the filter is "equalizer", standardize to "eq"
    if (filterName === 'equalizer') filterName = 'eq';
    setLocalFilters(prev => {
      const updatedFilters = { ...prev };
      if (updatedFilters[filterName]) {
        updatedFilters[filterName] = {
          ...updatedFilters[filterName],
          enabled: !updatedFilters[filterName].enabled
        };
      } else {
        updatedFilters[filterName] = {
          enabled: true,
          ...(filterName === 'reverb' ? { wet: 0.5, dry: 1.0 } : {}),
          ...(filterName === 'stereoPan' ? { value: 0.3, speed: 15, pattern: 'sine' } : {}),
          ...(filterName === 'eq' ? { lowGain: 0, midGain: 0, highGain: 0 } : {})
        };
      }
      return updatedFilters;
    });
  }, []);

  // Apply preset configuration
  const applyPresetConfig = useCallback((presetConfig, filterType) => {
    console.log(`Applying preset: ${JSON.stringify(presetConfig)}`);
    
    // Create a new filter with all the preset values
    let newFilter = {
      enabled: true,
      preset: presetConfig.label || presetConfig.preset || ""
    };
    
    // Copy all preset parameters (except label and description)
    Object.keys(presetConfig).forEach(key => {
      if (key !== 'label' && key !== 'description') {
        newFilter[key] = presetConfig[key];
      }
    });
    
    // Special handling for reverb to ensure impulse_response_path is properly set
    if (filterType === 'reverb') {
      // Make sure we have the full path for impulse_response
      if (newFilter.impulse_response_path && !newFilter.impulse_response_path.startsWith('s3://')) {
        const basePath = 's3://pausebeta20-meditation-assets/';
        newFilter.impulse_response_path = `${basePath}${newFilter.impulse_response_path}`;
      }
      console.log(`Set IR path for ${newFilter.preset} to ${newFilter.impulse_response_path}`);
    }
    
    // For EQ, ensure we're using the correct property name (eq vs equalizer)
    const finalFilterType = filterType === 'equalizer' ? 'eq' : filterType;
    
    // Always ensure enabled is set to true
    newFilter.enabled = true;
    
    // Update filters state with console log to confirm it's working
    console.log(`Setting ${finalFilterType} to:`, newFilter);
    
    setLocalFilters(prevFilters => {
      const result = {
        ...prevFilters,
        [finalFilterType]: newFilter
      };
      console.log("New filters state:", result);
      return result;
    });
  }, []);

  // Handle preset selection with improved error handling
  const handlePresetSelect = useCallback((filterType, presetName) => {
    console.log(`Selecting preset: ${presetName} for filter: ${filterType}`);
    
    // Create a fallback preset configuration in case getPresetConfigByName fails
    let presetConfig;
    
    try {
      // Try to get the preset from the imported function
      presetConfig = getPresetConfigByName(presetName);
    } catch (error) {
      console.error(`Error getting preset: ${error.message}`);
      presetConfig = null;
    }
    
    // If no preset found, create a default one based on preset name
    if (!presetConfig) {
      console.log(`Creating fallback preset for: ${presetName}`);
      
      // Create fallback presets based on the preset name
      if (filterType === 'reverb') {
        if (presetName === 'Intimate Room') {
          presetConfig = {
            label: 'Intimate Room',
            enabled: true,
            wet: 0.35,
            dry: 0.75,
            preset: 'Intimate Room',
            impulse_response_path: 'reverb/small_room.wav'
          };
        } else if (presetName === 'Sacred Hall') {
          presetConfig = {
            label: 'Sacred Hall',
            enabled: true,
            wet: 0.5,
            dry: 0.6,
            preset: 'Sacred Hall',
            impulse_response_path: 'reverb/large_hall.wav'
          };
        }
      } else if (filterType === 'stereoPan') {
        if (presetName === 'Gentle Movement') {
          presetConfig = {
            label: 'Gentle Movement',
            enabled: true,
            value: 0.2,
            speed: 10,
            pattern: 'sine',
            preset: 'Gentle Movement'
          };
        } else if (presetName === 'Meditative Space') {
          presetConfig = {
            label: 'Meditative Space',
            enabled: true,
            value: 0.4,
            speed: 15,
            pattern: 'sine',
            preset: 'Meditative Space'
          };
        }
      } else if (filterType === 'equalizer') {
        if (presetName === 'Voice Clarity') {
          presetConfig = {
            label: 'Voice Clarity',
            enabled: true,
            lowGain: -1,
            midGain: 3,
            highGain: 2,
            preset: 'Voice Clarity'
          };
        } else if (presetName === 'Warm Voice') {
          presetConfig = {
            label: 'Warm Voice',
            enabled: true,
            lowGain: 3,
            midGain: 1,
            highGain: -1,
            preset: 'Warm Voice'
          };
        }
      }
    }
    
    // If we have a valid config (either from import or fallback), apply it
    if (presetConfig) {
      // Force enable flag to true no matter what
      presetConfig.enabled = true;
      applyPresetConfig(presetConfig, filterType);
    } else {
      // As a last resort, use a very basic preset that just enables the filter
      console.warn(`Using basic preset for ${filterType} - ${presetName}`);
      
      // For EQ, ensure we're using the correct property name (eq vs equalizer)
      const finalFilterType = filterType === 'equalizer' ? 'eq' : filterType;
      
      setLocalFilters(prev => ({
        ...prev,
        [finalFilterType]: {
          ...prev[finalFilterType],
          enabled: true,
          preset: presetName
        }
      }));
    }
  }, [applyPresetConfig]);



  useEffect(() => {
    if (typeof window !== 'undefined' && window.globalAudioState) {
      globalAudioStateRef.current = window.globalAudioState;
      console.log("ListenStep: GlobalAudioState reference initialized");
    }
  }, []);


// Improved handle continue function for AudioStep.js
// This function shows the critical improvements needed for the audio mixing URL extraction issue
// Update in AudioStep.js handleContinue function
// Around line 485-690

// Fix the issue where mixed audio URL is undefined despite successful response

// ====== FIXED VERSION OF handleContinue FUNCTION FOR AudioStep.js ======

const handleContinue = async () => {
  console.log("CONTINUE BUTTON CLICKED - STARTING TRANSITION");
  
  // Create getTtsUrlFunc - for fallback purposes
  const getTtsUrlFunc = () => {
    if (!ttsKey) return null;
    const formattedKey = ttsKey.replace(/^TTS\//, '');
    const apiBase = baseUrl || process.env.REACT_APP_API_BASE_URL || 'https://api.pause.site';
    return `${apiBase}/tts/${formattedKey}`;
  };

  // Prevent continuing if voice generation is still in progress and not ready
  if (isBackgroundTTSProcessing && backgroundTTSProgress < 90 && !ttsKey) {
    toast.info("Please wait for voice generation to complete before continuing", {
      toastId: 'tts-not-ready',
      autoClose: 3000
    });
    return;
  }

  // Block further clicks and update transition UI
  if (setIsTransitioning) {
    setIsTransitioning(true);
  }
  if (setTransitionMessage) setTransitionMessage("Creating your meditation audio...");
  if (setTransitionSubtitle) setTransitionSubtitle("Mixing voice and music");

  // Save local volume and filter settings
  setVolume({ ...localVolume });
  setFilters({ ...localFilters });

  try {
    // Use GlobalAudioState for mixing state management if available
    if (globalAudioStateRef.current) {
      globalAudioStateRef.current.startAudioMixing();
    } else {
      // Fallback if GlobalAudioState isn't available
      if (setIsMixingAudio) {
        setIsMixingAudio(true);
      }
      
      // Store in session storage for compatibility
      try {
        sessionStorage.setItem('mixingInProgress', 'true');
        sessionStorage.setItem('mixingStartTime', Date.now().toString());
        window.__mixingStartTime = Date.now();
        window.__mixingInProgress = true;
      } catch (e) {
        console.warn("Error setting mixing state in session storage:", e);
      }
    }

    // Prepare TTS URL for fallback
    const ttsUrl = getTtsUrlFunc();
    
    // Set fallback URL in storage
    if (ttsUrl) {
      try {
        sessionStorage.setItem('fallbackAudioUrl', ttsUrl);
        sessionStorage.setItem('currentAudioUrl', ttsUrl);
        
        if (globalAudioStateRef.current) {
          if (globalAudioStateRef.current.setCurrentAudioUrl) {
            globalAudioStateRef.current.setCurrentAudioUrl(ttsUrl);
          }
        } else if (window.centralizeAudioUrl) {
          window.centralizeAudioUrl(ttsUrl);
        }
      } catch (e) {
        console.warn("Error storing fallback URL:", e);
      }
    }
    
    // Call onApplyChanges and store the result properly
    let mixResult;
    try {
      mixResult = await onApplyChanges(localVolume, localFilters);
      // Log the entire response for debugging
      console.log("Mix result full structure:", mixResult);
    } catch (mixError) {
      console.error("Error calling onApplyChanges:", mixError);
      mixResult = null;
    }
    
    // IMPROVED EXTRACTION: Extract the URL from various response formats
    let finalUrl = null;
    
    // Case 1: Handle direct file_path at top level
    if (mixResult && mixResult.file_path && typeof mixResult.file_path === 'string' && 
        mixResult.file_path.startsWith('http')) {
      finalUrl = mixResult.file_path;
    }
    // Case 2: Handle data.file_path nested structure
    else if (mixResult && mixResult.data && mixResult.data.file_path && 
             typeof mixResult.data.file_path === 'string' && 
             mixResult.data.file_path.startsWith('http')) {
      finalUrl = mixResult.data.file_path;
    }
    // Case 3: Check for URLs in common field names in data
    else if (mixResult && mixResult.data) {
      const urlFields = ['url', 'audioUrl', 'audio_url', 'mixed_url', 'mixedUrl', 'mp3_url'];
      for (const field of urlFields) {
        if (mixResult.data[field] && typeof mixResult.data[field] === 'string' && 
            mixResult.data[field].startsWith('http')) {
          finalUrl = mixResult.data[field];
          break;
        }
      }
    }
    // Case 4: Try session storage (MeditationApp might have stored it there)
    if (!finalUrl) {
      try {
        const storedUrl = sessionStorage.getItem('mixedAudioUrl');
        if (storedUrl && storedUrl.startsWith('http')) {
          finalUrl = storedUrl;
        }
      } catch (err) {
        console.warn("Error checking session storage:", err);
      }
    }
    
    // Update the UI and storage with our result
    if (finalUrl) {
      console.log("Successfully extracted mixed audio URL:", finalUrl);
      
      // Store in all possible locations to ensure it's available
      try {
        sessionStorage.setItem('mixedAudioUrl', finalUrl);
        sessionStorage.setItem('currentAudioUrl', finalUrl);
        sessionStorage.setItem('stableTransitionUrl', finalUrl);
        sessionStorage.setItem('urlPriority', 'mixed');
        sessionStorage.setItem('mixingCompleted', 'true');
        sessionStorage.setItem('mixingInProgress', 'false');
        
        // Also store in window globals
        window.__mixedAudioUrl = finalUrl;
        window.__currentAudioUrl = finalUrl;
        window.__mixingCompleted = true;
        window.__mixingInProgress = false;
        
        // Update GlobalAudioState
        if (globalAudioStateRef.current) {
          if (globalAudioStateRef.current.completeAudioMixing) {
            globalAudioStateRef.current.completeAudioMixing(finalUrl);
          } else if (globalAudioStateRef.current.updateAllAudioUrls) {
            globalAudioStateRef.current.updateAllAudioUrls(finalUrl);
          } else if (globalAudioStateRef.current.setCurrentAudioUrl) {
            globalAudioStateRef.current.setCurrentAudioUrl(finalUrl);
          }
        } else if (window.centralizeAudioUrl) {
          window.centralizeAudioUrl(finalUrl);
        }
      } catch (e) {
        console.warn("Error storing URL:", e);
      }
      
      // Update transition message
      if (setTransitionMessage) setTransitionMessage("Preparing your meditation...");
      if (setTransitionSubtitle) setTransitionSubtitle("Setting up your player");
    } 
    else if (ttsUrl) {
      console.warn("Mix result did not provide a valid file_path, falling back to TTS URL");
      
      // Signal mixing failure
      if (globalAudioStateRef.current && globalAudioStateRef.current.failAudioMixing) {
        globalAudioStateRef.current.failAudioMixing('no-valid-url');
      }
      
      // Set fallback URL
      try {
        sessionStorage.setItem('mixingCompleted', 'true');
        sessionStorage.setItem('mixingFailed', 'true');
        sessionStorage.setItem('mixingInProgress', 'false');
        sessionStorage.setItem('currentAudioUrl', ttsUrl);
        sessionStorage.setItem('urlPriority', 'tts');
        
        window.__currentAudioUrl = ttsUrl;
        window.__fallbackAudioUrl = ttsUrl;
        window.__mixingCompleted = true;
        window.__mixingFailed = true;
        window.__mixingInProgress = false;
        
        if (globalAudioStateRef.current) {
          if (globalAudioStateRef.current.updateAllAudioUrls) {
            globalAudioStateRef.current.updateAllAudioUrls(ttsUrl);
          } else if (globalAudioStateRef.current.setCurrentAudioUrl) {
            globalAudioStateRef.current.setCurrentAudioUrl(ttsUrl);
          }
        } else if (window.centralizeAudioUrl) {
          window.centralizeAudioUrl(ttsUrl);
        }
      } catch (e) {
        console.warn("Error updating storage:", e);
      }
      
      // Show user a message
      toast.info("Using voice-only audio (no background music)", { autoClose: 3000 });
    }
    
    // Mark step as complete regardless of mix outcome
    markStepCompleted(5);
    
    // Important: Store current step in session storage
    try {
      sessionStorage.setItem('currentStep', '6');
      sessionStorage.setItem('previousStep', '5');
      sessionStorage.setItem('isTransitioningToListen', 'true');
    } catch (e) {
      console.warn("Error storing navigation state:", e);
    }
    
    // Important: Wait a moment to ensure state updates before navigating
    await new Promise(resolve => setTimeout(resolve, 500));
    
    // Now transition to ListenStep
    console.log("Audio preparation complete, transitioning to ListenStep");
    goToNextStep();
    
    // Clear transition state after a delay
    setTimeout(() => {
      if (setIsTransitioning) setIsTransitioning(false);
      if (setIsMixingAudio) setIsMixingAudio(false);
      
      // Clear transition flag
      try {
        sessionStorage.setItem('isTransitioningToListen', 'false');
      } catch (e) {
        console.warn("Error clearing transition flag:", e);
      }
    }, 1500);
  } catch (error) {
    console.error("Error during audio mixing or transition:", error);
    
    // Get fallback URL
    const fallbackTtsUrl = getTtsUrlFunc();
    
    // Try to recover with fallback URL
    if (fallbackTtsUrl) {
      try {
        sessionStorage.setItem('currentAudioUrl', fallbackTtsUrl);
        window.__currentAudioUrl = fallbackTtsUrl;
        
        if (globalAudioStateRef.current) {
          if (globalAudioStateRef.current.updateAllAudioUrls) {
            globalAudioStateRef.current.updateAllAudioUrls(fallbackTtsUrl);
          } else if (globalAudioStateRef.current.setCurrentAudioUrl) {
            globalAudioStateRef.current.setCurrentAudioUrl(fallbackTtsUrl);
          }
        } else if (window.centralizeAudioUrl) {
          window.centralizeAudioUrl(fallbackTtsUrl);
        }
      } catch (e) {
        console.warn("Error storing fallback URL:", e);
      }
    }
    
    // Mark mixing as failed but completed
    try {
      sessionStorage.setItem('mixingInProgress', 'false');
      sessionStorage.setItem('mixingCompleted', 'true');
      sessionStorage.setItem('mixingFailed', 'true');
      sessionStorage.setItem('currentStep', '6');
      
      window.__mixingInProgress = false;
      window.__mixingCompleted = true;
      window.__mixingFailed = true;
    } catch (e) {
      console.warn("Error storing mixing state:", e);
    }
    
    // Show error to user
    toast.error("There was an issue preparing your audio. Using voice only.");
    
    // Still mark step as complete and continue
    markStepCompleted(5);
    goToNextStep();
    
    // Clean up state
    setTimeout(() => {
      if (setIsTransitioning) setIsTransitioning(false);
      if (setIsMixingAudio) setIsMixingAudio(false);
    }, 1000);
  }
};


  // --- Lifecycle: Mount, Resize, and Restore ---
  useEffect(() => {
    mounted.current = true;
    console.log("AudioStep: Component mounted, musicLibrary:", 
               Array.isArray(musicLibrary) ? musicLibrary.length : "not an array", 
               "selectedMusic:", selectedMusic);
    
    // Start a timer to track loading duration
    const loadingTimer = setInterval(() => {
      if (isMusicLoading) {
        setLoadingDuration(prev => prev + 1000);
      }
    }, 1000);
    
    // Initialize the audio state ref with props
    audioStateRef.current = {
      ...audioStateRef.current,
      ttsKey: ttsKey,
      audioUrl: ttsKey ? getTtsUrl(ttsKey, baseUrl) : null
    };
    
    return () => {
      mounted.current = false;
      clearInterval(loadingTimer);
    };
  }, [musicLibrary, selectedMusic, isMusicLoading, ttsKey, baseUrl]);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  // On mount, use the parent's music library
  useEffect(() => {
    console.log("AudioStep: Checking music library", {
      musicLibraryLength: Array.isArray(musicLibrary) ? musicLibrary.length : 0,
      selectedMusic
    });
    
    if (Array.isArray(musicLibrary) && musicLibrary.length > 0) {
      console.log("AudioStep: Using provided music library");
      
      // Use the parent's library directly - no formatting needed
      setLocalMusicLibrary(musicLibrary);
      
      // Get selection from props or localStorage
      if (selectedMusic) {
        setLocalMusic(selectedMusic);
      } else {
        const lastSelected = localStorage.getItem('lastSelectedMusic');
        if (lastSelected) {
          console.log("AudioStep: Restoring selection from localStorage:", lastSelected);
          setLocalMusic(lastSelected);
          if (typeof setSelectedMusic === 'function') {
            setSelectedMusic(lastSelected);
          }
        } else if (musicLibrary.length > 0) {
          console.log("AudioStep: No selection, using first track:", musicLibrary[0].name);
          setLocalMusic(musicLibrary[0].name);
          if (typeof setSelectedMusic === 'function') {
            setSelectedMusic(musicLibrary[0].name);
          }
        }
      }
    } else {
      console.warn("AudioStep: No music library provided - this may cause issues");
    }
  }, [musicLibrary, selectedMusic, setSelectedMusic]);
  
  // Save changes from local selection to props if changed
  const previousSelectionRef = useRef(selectedMusic);
  useEffect(() => {
    if (selectedMusic !== previousSelectionRef.current) {
      setLocalMusic(selectedMusic || '');
      previousSelectionRef.current = selectedMusic;
    }
  }, [selectedMusic]);
  
  // Attempt to restore last selection from localStorage
  useEffect(() => {
    const lastSelected = localStorage.getItem('lastSelectedMusic');
    if (lastSelected && !selectedMusic) {
      setLocalMusic(lastSelected);
      setSelectedMusic(lastSelected);
    }
  }, [selectedMusic, setSelectedMusic]);
  
  useEffect(() => {
    // Track background TTS processing changes
    if (prevIsBackgroundTTSProcessingRef.current && !isBackgroundTTSProcessing) {
      // TTS processing just completed
      toast.success("Voice generation complete! You can now apply the final audio.", {
        autoClose: 3000
      });
      
      // Force TTS readiness
      setTtsReady(true);
      
      // Update global state
      if (typeof window !== 'undefined') {
        window.__ttsComplete = true;
        window.__ttsCompletionTime = Date.now();
        
        try {
          sessionStorage.setItem('ttsComplete', 'true');
          sessionStorage.setItem('ttsCompletionTime', Date.now().toString());
        } catch (e) {
          console.warn("Storage error:", e);
        }
      }
    }
    
    // Update previous state ref
    prevIsBackgroundTTSProcessingRef.current = isBackgroundTTSProcessing;
    
    // Ensure TTS readiness is properly set based on completion or progress
    if (!isBackgroundTTSProcessing || backgroundTTSProgress >= 95 || !!ttsKey) {
      setTtsReady(true);
    }
  }, [
    isBackgroundTTSProcessing, 
    backgroundTTSProgress, 
    ttsKey, 
    toast
  ]);
  
  // Safety timeout to ensure loading state doesn't get stuck
  useEffect(() => {
    const safetyTimer = setTimeout(() => {
      if (isApplyingChanges) {
        console.warn("Safety timeout: clearing stuck loading state");
        setIsApplyingChanges(false);
        // Clear mixing state in parent component if available
        if (typeof setIsMixingAudio === 'function') {
          setIsMixingAudio(false);
        }
        
        // Clear any timer stored in window
        if (typeof window !== 'undefined' && window.__progressTimer) {
          clearInterval(window.__progressTimer);
          window.__progressTimer = null;
        }
        
        toast.warning("Audio mixing state was automatically cleared");
        // Or use this more reliable version:
        if (typeof toast.warning === 'function') {
          toast.warning("Audio mixing state was automatically cleared");
        } else if (typeof toast.warn === 'function') {
          toast.warn("Audio mixing state was automatically cleared");
        } else {
          console.warn("Audio mixing state was automatically cleared");
        }
              }
    }, 60000); // 1 minute timeout
    
    return () => clearTimeout(safetyTimer);
  }, [isApplyingChanges, setIsMixingAudio, toast]);

  // CSS for loading animations
  const loadingAnimationStyles = `
  .mixing-progress-bar {
    width: 100%;
    height: 4px;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 2px;
    overflow: hidden;
    margin-top: 10px;
  }

  .mixing-progress-bar-inner {
    height: 100%;
    width: 0;
    background-color: #7d12ff;
    border-radius: 2px;
    transition: width 0.5s;
  }

  .mixing-progress-bar-animated {
    position: relative;
    animation: indeterminate-progress 1.5s ease-in-out infinite;
    background-image: linear-gradient(
      45deg, 
      rgba(255, 255, 255, 0.15) 25%, 
      transparent 25%, 
      transparent 50%, 
      rgba(255, 255, 255, 0.15) 50%, 
      rgba(255, 255, 255, 0.15) 75%, 
      transparent 75%, 
      transparent
    );
    background-size: 40px 40px;
  }

  @keyframes indeterminate-progress {
    0% {
      left: -100%;
      width: 100%;
    }
    100% {
      left: 100%;
      width: 100%;
    }
  }
  `;

  // Global CSS styles
  const getGlobalStyles = useCallback((isMobile) => {
    return `
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
      input[type="range"]::-webkit-slider-thumb {
        -webkit-appearance: none;
        width: ${isMobile ? '20px' : '16px'};
        height: ${isMobile ? '20px' : '16px'};
        border-radius: 50%;
        background: #7d12ff;
        cursor: pointer;
        box-shadow: 0 0 6px rgba(125,18,255,0.4);
      }
      input[type="range"]::-moz-range-thumb {
        width: ${isMobile ? '20px' : '16px'};
        height: ${isMobile ? '20px' : '16px'};
        border-radius: 50%;
        background: #7d12ff;
        cursor: pointer;
        border: none;
        box-shadow: 0 0 6px rgba(125,18,255,0.4);
      }
      button:hover:not(:disabled) {
        transform: translateY(-2px);
        box-shadow: 0 6px 16px rgba(125,18,255,0.3);
      }
      button:active:not(:disabled) {
        transform: translateY(0);
      }
      @media (max-width: 768px) {
        input[type="range"] {
          height: 12px;
          padding: 8px 0;
        }
        input[type="checkbox"] {
          width: 20px;
          height: 20px;
        }
        select {
          height: 44px;
        }
      }
    `;
  }, []);
    
  // --- Loading and Fallback Rendering ---
  // If music library is empty, show fallback loading state
  const isLibraryEmpty = !Array.isArray(localMusicLibrary) || localMusicLibrary.length === 0;
  
  if (showMainLoading) {
    return (
      <div className="step-container" style={{ padding: isSmallMobile ? '12px' : isMobile ? '16px' : '20px' }}>
        <div className="step-header" style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <h2 className="step-title" style={{ fontSize: isSmallMobile ? '1.5rem' : '1.8rem' }}>
            Select Background Music
          </h2>
          <p className="step-description" style={{ fontSize: isSmallMobile ? '0.95rem' : '1.1rem' }}>
            {isMusicLoading ? 'Loading music library...' : 'No music tracks available'}
          </p>
        </div>
        <div style={{
          textAlign: 'center',
          padding: isSmallMobile ? '24px' : '40px',
          color: 'rgba(255, 255, 255, 0.7)',
          fontSize: isSmallMobile ? '0.9rem' : '1rem'
        }}>
          {isMusicLoading ? (
            <>
              <div style={{
                width: '40px',
                height: '40px',
                borderRadius: '50%',
                border: '3px solid rgba(125,18,255,0.3)',
                borderTopColor: '#7d12ff',
                animation: 'spin 1s linear infinite',
                margin: '0 auto 20px'
              }}></div>
              <div>Loading music library from server...</div>
              {loadingDuration > 15000 && (
                <button
                  onClick={skipLoadingAndContinue}
                  style={{
                    padding: '8px 16px',
                    background: '#7d12ff',
                    color: 'white',
                    border: 'none',
                    borderRadius: '8px',
                    margin: '20px auto',
                    display: 'block',
                    cursor: 'pointer'
                  }}
                >
                  Skip Loading and Continue
                </button>
              )}
            </>
          ) : (
            <>
              <div>
                No music tracks available. Please try refreshing the page or loading again.
              </div>
              <button
                onClick={skipLoadingAndContinue}
                style={{
                  marginTop: '20px',
                  padding: '10px 20px',
                  borderRadius: '8px',
                  background: '#7d12ff',
                  border: 'none',
                  color: 'white',
                  cursor: 'pointer'
                }}
              >
                Retry / Skip
              </button>
            </>
          )}
        </div>
        
        {/* TTS Progress Indicator */}
        {isBackgroundTTSProcessing && (
          <TTSProgressIndicator 
            isBackgroundTTSProcessing={isBackgroundTTSProcessing}
            backgroundTTSProgress={backgroundTTSProgress}
            ttsKey={ttsKey}
            isMobile={isMobile}
            colorScheme="blue"
          />
        )}
        
        <style jsx>{`
          @keyframes spin {
            to { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }
  
  if (isLibraryEmpty && !isMusicLoading) {
    return (
      <div className="step-container" style={{ padding: isSmallMobile ? '12px' : isMobile ? '16px' : '20px' }}>
        <div className="step-header" style={{ textAlign: isMobile ? 'center' : 'left' }}>
          <h2 className="step-title" style={{ fontSize: isSmallMobile ? '1.5rem' : '1.8rem' }}>
            Select Background Music
          </h2>
          <p className="step-description" style={{ fontSize: isSmallMobile ? '0.95rem' : '1.1rem' }}>
            Loading music library...
          </p>
        </div>
        <div style={{
          textAlign: 'center',
          padding: isSmallMobile ? '24px' : '40px',
          color: 'rgba(255, 255, 255, 0.7)'
        }}>
          <div style={{
            width: '40px',
            height: '40px',
            borderRadius: '50%',
            border: '3px solid rgba(125,18,255,0.3)',
            borderTopColor: '#7d12ff',
            animation: 'spin 1s linear infinite',
            margin: '0 auto 20px'
          }}></div>
          <div>Loading music library from server...</div>
          {loadingDuration > 15000 && (
            <button
              onClick={skipLoadingAndContinue}
              style={{
                padding: '8px 16px',
                background: '#7d12ff',
                color: 'white',
                border: 'none',
                borderRadius: '8px',
                margin: '20px auto',
                display: 'block',
                cursor: 'pointer'
              }}
            >
              Skip Loading and Continue
            </button>
          )}
        </div>
        
        {/* TTS Progress Indicator */}
        {isBackgroundTTSProcessing && (
          <TTSProgressIndicator 
            isBackgroundTTSProcessing={isBackgroundTTSProcessing}
            backgroundTTSProgress={backgroundTTSProgress}
            ttsKey={ttsKey}
            isMobile={isMobile}
            colorScheme="blue"
          />
        )}
        
        <style jsx>{`
          @keyframes spin {
            to { transform: rotate(360deg); }
          }
        `}</style>
      </div>
    );
  }
  
  // Main content
  return (
    <div className="step-container">
      <div className="step-header" style={{ padding: isMobile ? '0 16px' : '0', textAlign: isMobile ? 'center' : 'left' }}>
        <h2 className="step-title" style={{ fontSize: isMobile ? '1.3rem' : '1.5rem' }}>
          Customize Audio
        </h2>
        <p className="step-description" style={{ fontSize: isMobile ? '0.9rem' : '1rem' }}>
          Adjust volume levels, select background music, and apply audio effects.
        </p>
      </div>
  
      {/* Explanation */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        padding: isMobile ? '12px 16px' : '16px',
        background: 'rgba(125,18,255,0.1)',
        borderRadius: '10px',
        fontSize: fontSize,
        color: '#ffffff',
        textAlign: 'center'
      }}>
        Your changes will automatically apply when you continue to the next step.
      </div>
  
      {/* TTS Progress Indicator - Place this right after the explanation */}
      {isBackgroundTTSProcessing && (
        <TTSProgressIndicator 
          isBackgroundTTSProcessing={isBackgroundTTSProcessing}
          backgroundTTSProgress={backgroundTTSProgress}
          ttsKey={ttsKey}
          isMobile={isMobile}
          colorScheme="blue"
          onComplete={() => {
            // Force state update when complete
            if (isBackgroundTTSProcessing && backgroundTTSProgress >= 100) {
              setTtsReady(true);
            }
          }}
        />
      )}

      {/* Music Dropdown */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        background: 'rgba(25,25,35,0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.08)',
        borderRadius: '12px',
        padding: containerPadding,
        boxShadow: '0 4px 20px rgba(0,0,0,0.2)'
      }}>
        <label style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          marginBottom: '12px',
          color: '#ffffff',
          fontSize: fontSize
        }}>
          <IoMusicalNotesOutline size={18} />
          Background Music
        </label>
        <select
          value={localMusic}
          onChange={handleMusicSelect}
          disabled={isMusicLoading || showMainLoading}
          style={{
            width: '100%',
            padding: isMobile ? '10px' : '12px',
            backgroundColor: '#2a2a2e',
            color: '#ffffff',
            border: '1px solid rgba(255,255,255,0.1)',
            borderRadius: '8px',
            fontSize: fontSize,
            appearance: 'none',
            backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='white' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e")`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right 12px center',
            backgroundSize: '16px',
            opacity: showMainLoading ? 0.7 : 1
          }}
        >
          <option value="">No background music</option>
          {Array.isArray(musicLibrary) && musicLibrary.map((track) => {
            if (!track || !track.name) return null;
            const displayName = track.displayName || track.name.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase());
            return (
              <option key={track.name} value={track.name}>
                {displayName}
              </option>
            );
          })}
        </select>
      </div>
  
      {/* Volume Controls */}
      <div style={{
        width: containerWidth,
        maxWidth: '100%',
        margin: '16px auto',
        background: 'rgba(25,25,35,0.6)',
        backdropFilter: 'blur(10px)',
        WebkitBackdropFilter: 'blur(10px)',
        border: '1px solid rgba(255,255,255,0.08)',
        borderRadius: '12px',
        padding: containerPadding,
        boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
        opacity: showMainLoading ? 0.7 : 1
      }}>
        <h3 style={{
          fontSize: isMobile ? '1rem' : '1.1rem',
          fontWeight: '500',
          marginBottom: '16px',
          color: '#ffffff',
          display: 'flex',
          alignItems: 'center',
          gap: '8px'
        }}>
          <IoVolumeHighOutline size={18} />
          Volume Levels
        </h3>
        <div>
          {/* Voice Volume */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '16px' }}>
            <div style={{ minWidth: labelWidth, color: '#ffffff', fontSize: fontSize, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoPersonOutline size={16} />
              Voice
            </div>
            <div style={{ flex: 1 }}>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={localVolume.tts_volume}
                onChange={(e) => handleVolumeChange('tts_volume', parseFloat(e.target.value))}
                disabled={showMainLoading}
                style={{
                  width: '100%',
                  height: '4px',
                  background: calculateBackground(localVolume.tts_volume),
                  borderRadius: '2px',
                  outline: 'none',
                  WebkitAppearance: 'none'
                }}
              />
            </div>
            <div style={{ minWidth: '36px', textAlign: 'center', color: '#ffffff', fontWeight: '500', fontSize: isMobile ? '0.85rem' : '0.9rem', background: 'rgba(0,0,0,0.2)', padding: '4px 8px', borderRadius: '6px' }}>
              {(localVolume.tts_volume * 100).toFixed(0)}%
            </div>
          </div>
          {/* Music Volume */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px', marginBottom: '16px' }}>
            <div style={{ minWidth: labelWidth, color: '#ffffff', fontSize: fontSize, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoMusicalNotesOutline size={16} />
              Music
            </div>
            <div style={{ flex: 1 }}>
              <input
                type="range"
                min="0"
                max="0.2"
                step="0.01"
                value={localVolume.bg_volume}
                onChange={(e) => handleVolumeChange('bg_volume', parseFloat(e.target.value))}
                disabled={showMainLoading}
                style={{
                  width: '100%',
                  height: '4px',
                  background: calculateBackground(localVolume.bg_volume, 0, 0.2),
                  borderRadius: '2px',
                  outline: 'none',
                  WebkitAppearance: 'none'
                }}
              />
            </div>
            <div style={{ minWidth: '36px', textAlign: 'center', color: '#ffffff', fontWeight: '500', fontSize: isMobile ? '0.85rem' : '0.9rem', background: 'rgba(0,0,0,0.2)', padding: '4px 8px', borderRadius: '6px' }}>
              {((localVolume.bg_volume / 0.2) * 100).toFixed(0)}%
            </div>
          </div>
          {/* Master Volume */}
          <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
            <div style={{ minWidth: labelWidth, color: '#ffffff', fontSize: fontSize, display: 'flex', alignItems: 'center', gap: '8px' }}>
              <IoVolumeHighOutline size={16} />
              Master
            </div>
            <div style={{ flex: 1 }}>
              <input
                type="range"
                min="0"
                max="1"
                step="0.1"
                value={localVolume.overall_volume}
                onChange={(e) => handleVolumeChange('overall_volume', parseFloat(e.target.value))}
                disabled={showMainLoading}
                style={{
                  width: '100%',
                  height: '4px',
                  background: calculateBackground(localVolume.overall_volume),
                  borderRadius: '2px',
                  outline: 'none',
                  WebkitAppearance: 'none'
                }}
              />
            </div>
            <div style={{ minWidth: '36px', textAlign: 'center', color: '#ffffff', fontWeight: '500', fontSize: isMobile ? '0.85rem' : '0.9rem', background: 'rgba(0,0,0,0.2)', padding: '4px 8px', borderRadius: '6px' }}>
              {(localVolume.overall_volume * 100).toFixed(0)}%
            </div>
          </div>
        </div>
      </div>
  

{/* Audio Effects - Improved UI */}
<div style={{
  width: containerWidth,
  maxWidth: '100%',
  margin: '16px auto',
  background: 'rgba(25,25,35,0.6)',
  backdropFilter: 'blur(10px)',
  WebkitBackdropFilter: 'blur(10px)',
  border: '1px solid rgba(255,255,255,0.08)',
  borderRadius: '12px',
  padding: containerPadding,
  boxShadow: '0 4px 20px rgba(0,0,0,0.2)',
  opacity: showMainLoading ? 0.7 : 1
}}>
  <div className="step-header" style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '16px'
  }}>
    <h3 style={{
      fontSize: isMobile ? '1rem' : '1.1rem',
      fontWeight: '500',
      color: '#ffffff',
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      gap: '8px'
    }}>
      <svg width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <path d="M12 3v2"></path>
        <path d="M19.07 6.93l-1.41 1.41"></path>
        <path d="M21 12h-2"></path>
        <path d="M19.07 17.07l-1.41-1.41"></path>
        <path d="M12 19v2"></path>
        <path d="M6.34 17.07l1.41-1.41"></path>
        <path d="M3 12h2"></path>
        <path d="M6.34 6.93l1.41 1.41"></path>
        <circle cx="12" cy="12" r="4"></circle>
      </svg>
      Audio Effects
    </h3>
    <button
      style={{
        background: 'transparent',
        border: 'none',
        color: '#7d12ff',
        display: 'flex',
        alignItems: 'center',
        gap: '6px',
        cursor: showMainLoading ? 'default' : 'pointer',
        fontSize: fontSize,
        padding: '6px 10px',
        borderRadius: '6px',
        transition: 'background-color 0.2s ease',
        opacity: showMainLoading ? 0.7 : 1
      }}
      onClick={() => !showMainLoading && setShowAdvanced(!showAdvanced)}
      disabled={showMainLoading}
    >
      {showAdvanced ? 'Hide Effects' : 'Show Effects'}
      {showAdvanced ? <IoChevronUpSharp /> : <IoChevronDownSharp />}
    </button>
  </div>

  {showAdvanced && (
  <>
    {/* Effect Type Selector Tabs - Enhanced */}
    <div style={{
      display: 'flex',
      marginBottom: '20px',
      background: 'rgba(20,20,30,0.5)',
      borderRadius: '8px',
      padding: '4px',
      position: 'relative'
    }}>
      <button
        onClick={() => !showMainLoading && setExpandedFilter(expandedFilter === 'reverb' ? null : 'reverb')}
        style={{
          flex: 1,
          padding: '10px 12px',
          background: expandedFilter === 'reverb' ? 'rgba(125,18,255,0.2)' : 'transparent',
          border: 'none',
          borderRadius: '6px',
          color: expandedFilter === 'reverb' ? '#ffffff' : 'rgba(255,255,255,0.7)',
          fontWeight: expandedFilter === 'reverb' ? '600' : '400',
          fontSize: '14px',
          cursor: showMainLoading ? 'default' : 'pointer',
          transition: 'all 0.2s ease'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
            <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
          </svg>
          Room Space {localFilters.reverb?.enabled && <span style={{fontSize: '10px', color: '#7d12ff'}}>ON</span>}
        </div>
      </button>
      <button
        onClick={() => !showMainLoading && setExpandedFilter(expandedFilter === 'stereoPan' ? null : 'stereoPan')}
        style={{
          flex: 1,
          padding: '10px 12px',
          background: expandedFilter === 'stereoPan' ? 'rgba(125,18,255,0.2)' : 'transparent',
          border: 'none',
          borderRadius: '6px',
          color: expandedFilter === 'stereoPan' ? '#ffffff' : 'rgba(255,255,255,0.7)',
          fontWeight: expandedFilter === 'stereoPan' ? '600' : '400',
          fontSize: '14px',
          cursor: showMainLoading ? 'default' : 'pointer',
          transition: 'all 0.2s ease'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M9 18V5l12-2v13"></path>
            <circle cx="6" cy="18" r="3"></circle>
            <circle cx="18" cy="16" r="3"></circle>
          </svg>
          Spatial Audio {localFilters.stereoPan?.enabled && <span style={{fontSize: '10px', color: '#7d12ff'}}>ON</span>}
        </div>
      </button>
      <button
        onClick={() => !showMainLoading && setExpandedFilter(expandedFilter === 'equalizer' ? null : 'equalizer')}
        style={{
          flex: 1,
          padding: '10px 12px',
          background: expandedFilter === 'equalizer' ? 'rgba(125,18,255,0.2)' : 'transparent',
          border: 'none',
          borderRadius: '6px',
          color: expandedFilter === 'equalizer' ? '#ffffff' : 'rgba(255,255,255,0.7)',
          fontWeight: expandedFilter === 'equalizer' ? '600' : '400',
          fontSize: '14px',
          cursor: showMainLoading ? 'default' : 'pointer',
          transition: 'all 0.2s ease'
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '6px' }}>
          <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <line x1="4" y1="21" x2="4" y2="14"></line>
            <line x1="4" y1="10" x2="4" y2="3"></line>
            <line x1="12" y1="21" x2="12" y2="12"></line>
            <line x1="12" y1="8" x2="12" y2="3"></line>
            <line x1="20" y1="21" x2="20" y2="16"></line>
            <line x1="20" y1="12" x2="20" y2="3"></line>
            <line x1="1" y1="14" x2="7" y2="14"></line>
            <line x1="9" y1="8" x2="15" y2="8"></line>
            <line x1="17" y1="16" x2="23" y2="16"></line>
          </svg>
          Tone {localFilters.eq?.enabled && <span style={{fontSize: '10px', color: '#7d12ff'}}>ON</span>}
        </div>
      </button>
    </div>
    
    {/* Room Space Effect - Enhanced Card Selection */}
    {expandedFilter === 'reverb' && (
      <div>
        <div style={{ 
          fontSize: '14px', 
          color: 'rgba(255,255,255,0.7)', 
          marginBottom: '12px',
          textAlign: 'center' 
        }}>
          Select the type of space for your meditation voice:
        </div>
        
        {/* New Card-based Selector with clear options */}
        <div style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
          gap: '12px',
          marginBottom: '16px'
        }}>
          {/* Option 1: None */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Toggling reverb OFF");
                toggleFilter('reverb');
                // ENHANCEMENT: Auto-close this filter section when turning it off
                if (localFilters.reverb?.enabled) {
                  setTimeout(() => setExpandedFilter(null), 500);
                }
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${!localFilters.reverb?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: !localFilters.reverb?.enabled ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: !localFilters.reverb?.enabled ? 'translateY(-2px)' : 'none',
              boxShadow: !localFilters.reverb?.enabled ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${!localFilters.reverb?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: !localFilters.reverb?.enabled ? '#7d12ff' : 'transparent'
              }}>
                {!localFilters.reverb?.enabled && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>
            
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={!localFilters.reverb?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
            
            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: !localFilters.reverb?.enabled ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              No Effect
            </h4>
            
            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Clean audio without any room ambience
            </p>
          </div>
          
          {/* Option 2: Intimate Space */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Intimate Room preset");
                handlePresetSelect('reverb', 'Intimate Room');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>
            
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"></path>
              </svg>
            </div>
            
            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Intimate Room' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Intimate Space
            </h4>
            
            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Cozy room with gentle acoustics
            </p>
          </div>
          
          {/* Option 3: Expansive Hall */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Sacred Hall preset");
                handlePresetSelect('reverb', 'Sacred Hall');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>
            
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z"></path>
                <polyline points="9 22 9 12 15 12 15 22"></polyline>
              </svg>
            </div>
            
            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.reverb?.enabled && localFilters.reverb?.preset === 'Sacred Hall' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Expansive Hall
            </h4>
            
            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Open, cathedral-like reverberations
            </p>
          </div>
        </div>
        
        {/* ADDED: Selected Effect Summary */}
        {localFilters.reverb?.enabled && (
          <div style={{
            padding: '10px',
            backgroundColor: 'rgba(125,18,255,0.1)',
            borderRadius: '8px',
            marginTop: '12px',
            textAlign: 'center'
          }}>
            <p style={{
              margin: 0,
              fontSize: '14px',
              color: '#ffffff'
            }}>
              Room Space: <strong>{localFilters.reverb.preset || 'Custom'}</strong> {' '}
              <button
                onClick={() => toggleFilter('reverb')}
                style={{
                  background: 'rgba(255,255,255,0.1)',
                  border: 'none',
                  padding: '3px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  color: 'white',
                  cursor: 'pointer',
                  marginLeft: '8px'
                }}
              >
                Turn Off
              </button>
            </p>
          </div>
        )}
      </div>
    )}
    
    {/* Spatial Audio Effect - Enhanced Card Selection */}
    {expandedFilter === 'stereoPan' && (
      <div>
        <div style={{ 
          fontSize: '14px', 
          color: 'rgba(255,255,255,0.7)', 
          marginBottom: '12px',
          textAlign: 'center' 
        }}>
          Choose the spatial movement for your audio:
        </div>
        
        {/* New Card-based Selector with clear options */}
        <div style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
          gap: '12px',
          marginBottom: '16px'
        }}>
          {/* Option 1: No Movement */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Toggling stereoPan OFF");
                toggleFilter('stereoPan');
                // ENHANCEMENT: Auto-close this filter section when turning it off
                if (localFilters.stereoPan?.enabled) {
                  setTimeout(() => setExpandedFilter(null), 500);
                }
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${!localFilters.stereoPan?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: !localFilters.stereoPan?.enabled ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: !localFilters.stereoPan?.enabled ? 'translateY(-2px)' : 'none',
              boxShadow: !localFilters.stereoPan?.enabled ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${!localFilters.stereoPan?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: !localFilters.stereoPan?.enabled ? '#7d12ff' : 'transparent'
              }}>
                {!localFilters.stereoPan?.enabled && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>
            
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={!localFilters.stereoPan?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
            
            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: !localFilters.stereoPan?.enabled ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              No Movement
            </h4>
            
            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Standard stereo without spatial movement
            </p>
          </div>
          
          {/* Option 2: Gentle Movement */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Gentle Movement preset");
                handlePresetSelect('stereoPan', 'Gentle Movement');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>
            
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <path d="M2 12h20"></path>
                <path d="M16 7l5 5-5 5"></path>
              </svg>
            </div>
            
            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Gentle Movement' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Subtle Movement
            </h4>
            
            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Gentle stereo movement for light immersion
            </p>
          </div>
          
          {/* Option 3: Meditative Space */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Meditative Space preset");
                handlePresetSelect('stereoPan', 'Meditative Space');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>
            
            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <circle cx="12" cy="12" r="4"></circle>
                <line x1="4.93" y1="4.93" x2="9.17" y2="9.17"></line>
                <line x1="14.83" y1="14.83" x2="19.07" y2="19.07"></line>
                <line x1="14.83" y1="9.17" x2="19.07" y2="4.93"></line>
                <line x1="4.93" y1="19.07" x2="9.17" y2="14.83"></line>
              </svg>
            </div>
            
            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.stereoPan?.enabled && localFilters.stereoPan?.preset === 'Meditative Space' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Deep Immersion
            </h4>
            
            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Enhanced spatial movement with binaural effect
            </p>
          </div>
        </div>
        
        {/* ADDED: Selected Effect Summary */}
        {localFilters.stereoPan?.enabled && (
          <div style={{
            padding: '10px',
            backgroundColor: 'rgba(125,18,255,0.1)',
            borderRadius: '8px',
            marginTop: '12px',
            textAlign: 'center'
          }}>
            <p style={{
              margin: 0,
              fontSize: '14px',
              color: '#ffffff'
            }}>
              Spatial Audio: <strong>{
                localFilters.stereoPan.preset === 'Gentle Movement' ? 'Subtle Movement' :
                localFilters.stereoPan.preset === 'Meditative Space' ? 'Deep Immersion' :
                localFilters.stereoPan.preset || 'Custom'
              }</strong> {' '}
              <button
                onClick={() => toggleFilter('stereoPan')}
                style={{
                  background: 'rgba(255,255,255,0.1)',
                  border: 'none',
                  padding: '3px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  color: 'white',
                  cursor: 'pointer',
                  marginLeft: '8px'
                }}
              >
                Turn Off
              </button>
            </p>
          </div>
        )}
      </div>
    )}
    
    {/* Add similar enhanced code for the Tone/EQ Effect section */}
    {expandedFilter === 'equalizer' && (
      <div>
        <div style={{ 
          fontSize: '14px', 
          color: 'rgba(255,255,255,0.7)', 
          marginBottom: '12px',
          textAlign: 'center' 
        }}>
          Select voice tone enhancement:
        </div>
        
        {/* New Card-based Selector with clear options */}
        <div style={{
          display: 'grid',
          gridTemplateColumns: isMobile ? '1fr' : 'repeat(3, 1fr)',
          gap: '12px',
          marginBottom: '16px'
        }}>
          {/* Option 1: No Enhancement */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Toggling equalizer OFF");
                toggleFilter('equalizer');
                // ENHANCEMENT: Auto-close this filter section when turning it off
                if (localFilters.eq?.enabled) {
                  setTimeout(() => setExpandedFilter(null), 500);
                }
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${!localFilters.eq?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: !localFilters.eq?.enabled ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: !localFilters.eq?.enabled ? 'translateY(-2px)' : 'none',
              boxShadow: !localFilters.eq?.enabled ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{ 
              position: 'absolute', 
              top: '14px',right: '14px' 
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${!localFilters.eq?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: !localFilters.eq?.enabled ? '#7d12ff' : 'transparent'
              }}>
                {!localFilters.eq?.enabled && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={!localFilters.eq?.enabled ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>

            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: !localFilters.eq?.enabled ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              No Enhancement
            </h4>

            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Natural voice without EQ adjustment
            </p>
          </div>

          {/* Option 2: Enhanced Clarity */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Voice Clarity preset");
                handlePresetSelect('equalizer', 'Voice Clarity');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{ 
              position: 'absolute', 
              top: '14px', 
              right: '14px' 
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px', 
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <polygon points="11 5 6 9 2 9 2 15 6 15 11 19 11 5"></polygon>
                <path d="M19.07 4.93a10 10 0 0 1 0 14.14M15.54 8.46a5 5 0 0 1 0 7.07"></path>
              </svg>
            </div>

            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.eq?.enabled && localFilters.eq?.preset === 'Voice Clarity' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Enhanced Clarity
            </h4>

            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Boosted mid-range for clearer voice
            </p>
          </div>

          {/* Option 3: Warm Tone */}
          <div
            onClick={() => {
              if (!showMainLoading) {
                console.log("Selecting Warm Voice preset");
                handlePresetSelect('equalizer', 'Warm Voice');
              }
            }}
            style={{
              borderRadius: '10px',
              border: `1px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'rgba(255,255,255,0.1)'}`,
              backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? 'rgba(125,18,255,0.1)' : 'rgba(30,30,40,0.4)',
              padding: '16px',
              cursor: showMainLoading ? 'default' : 'pointer',
              position: 'relative',
              transition: 'all 0.2s ease',
              transform: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? 'translateY(-2px)' : 'none',
              boxShadow: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '0 6px 14px rgba(125,18,255,0.2)' : 'none'
            }}
          >
            <div style={{
              position: 'absolute',
              top: '14px',
              right: '14px'
            }}>
              <div style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
                border: `2px solid ${localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'rgba(255,255,255,0.2)'}`,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'transparent'
              }}>
                {localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' && (
                  <div style={{ 
                    width: '10px', 
                    height: '10px',
                    borderRadius: '50%', 
                    backgroundColor: 'white' 
                  }}></div>
                )}
              </div>
            </div>

            <div style={{ textAlign: 'center', marginBottom: '10px' }}>
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" stroke={localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#7d12ff' : 'rgba(255,255,255,0.6)'} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                <circle cx="12" cy="12" r="10"></circle>
                <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                <line x1="9" y1="9" x2="9.01" y2="9"></line>
                <line x1="15" y1="9" x2="15.01" y2="9"></line>
              </svg>
            </div>

            <h4 style={{ 
              margin: '0 0 6px 0', 
              textAlign: 'center',
              color: localFilters.eq?.enabled && localFilters.eq?.preset === 'Warm Voice' ? '#ffffff' : 'rgba(255,255,255,0.8)',
              fontSize: '16px',
              fontWeight: '600'
            }}>
              Warm Tone
            </h4>

            <p style={{ 
              margin: 0, 
              fontSize: '13px', 
              color: 'rgba(255,255,255,0.6)',
              textAlign: 'center'
            }}>
              Smooth, relaxing bass enhancement
            </p>
          </div>
        </div>
        
        {/* ADDED: Selected Effect Summary */}
        {localFilters.eq?.enabled && (
          <div style={{
            padding: '10px',
            backgroundColor: 'rgba(125,18,255,0.1)',
            borderRadius: '8px',
            marginTop: '12px',
            textAlign: 'center'
          }}>
            <p style={{
              margin: 0,
              fontSize: '14px',
              color: '#ffffff'
            }}>
              Tone: <strong>{localFilters.eq.preset || 'Custom'}</strong> {' '}
              <button
                onClick={() => toggleFilter('equalizer')}
                style={{
                  background: 'rgba(255,255,255,0.1)',
                  border: 'none',
                  padding: '3px 8px',
                  borderRadius: '4px',
                  fontSize: '12px',
                  color: 'white',
                  cursor: 'pointer',
                  marginLeft: '8px'
                }}
              >
                Turn Off
              </button>
            </p>
          </div>
        )}
      </div>
    )}

    {/* ADDED: TTS Progress Indicator with prominent design */}
    {isBackgroundTTSProcessing && (
      <div style={{
        marginTop: '15px',
        padding: '15px 12px',
        backgroundColor: 'rgba(125,18,255,0.15)',
        borderRadius: '10px',
        border: '1px solid rgba(125,18,255,0.3)'
      }}>
        <div style={{ 
          display: 'flex', 
          alignItems: 'center', 
          marginBottom: '8px' 
        }}>
          <div style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            border: '2px solid rgba(125,18,255,0.3)',
            borderTopColor: '#7d12ff',
            animation: 'spin 1s linear infinite',
            marginRight: '12px'
          }}></div>
          <div style={{ 
            fontWeight: '600', 
            color: '#fff',
            fontSize: '15px'
          }}>
            Generating Voice...
          </div>
        </div>
        
        <div style={{ marginBottom: '5px' }}>
          <div style={{ 
            height: '8px', 
            width: '100%', 
            backgroundColor: 'rgba(255,255,255,0.1)',
            borderRadius: '4px',
            overflow: 'hidden'
          }}>
            <div style={{ 
              height: '100%', 
              width: `${backgroundTTSProgress}%`,
              backgroundColor: '#7d12ff',
              borderRadius: '4px',
              transition: 'width 0.5s ease-in-out'
            }}></div>
          </div>
        </div>
        
        <div style={{ 
          display: 'flex', 
          justifyContent: 'space-between',
          fontSize: '13px',
          color: 'rgba(255,255,255,0.7)'
        }}>
          <div>{Math.floor(backgroundTTSProgress)}% complete</div>
          <div>Please wait...</div>
        </div>
      </div>
    )}

    {/* Visual indicator of effect combination */}
    <div style={{
      marginTop: '20px',
      padding: '12px',
      background: 'rgba(20,20,30,0.3)',
      borderRadius: '8px',
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      gap: '10px',
      alignItems: 'center',
      justifyContent: 'center'
    }}>
      <div style={{ 
        fontSize: '14px', 
        color: 'rgba(255,255,255,0.7)',
        marginRight: isMobile ? '0' : '10px',
        textAlign: isMobile ? 'center' : 'left'
      }}>
        Active effects:
      </div>

      <div style={{ 
        display: 'flex', 
        gap: '10px',
        flexWrap: 'wrap',
        justifyContent: 'center'
      }}>
        {!localFilters.reverb?.enabled && !localFilters.stereoPan?.enabled && !localFilters.eq?.enabled ? (
          <span style={{
            padding: '5px 10px',
            borderRadius: '20px',
            background: 'rgba(255,255,255,0.1)',
            fontSize: '13px',
            color: 'rgba(255,255,255,0.6)'
          }}>
            No effects
          </span>
        ) : (
          <>
            {localFilters.reverb?.enabled && (
              <span style={{
                padding: '5px 10px',
                borderRadius: '20px',
                background: 'rgba(125,18,255,0.15)',
                fontSize: '13px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M3 18v-6a9 9 0 0 1 18 0v6"></path>
                  <path d="M21 19a2 2 0 0 1-2 2h-1a2 2 0 0 1-2-2v-3a2 2 0 0 1 2-2h3zM3 19a2 2 0 0 0 2 2h1a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H3z"></path>
                </svg>
                {localFilters.reverb?.preset === 'Intimate Room' ? 'Intimate Space' : 
                localFilters.reverb?.preset === 'Sacred Hall' ? 'Expansive Hall' : 
                localFilters.reverb?.preset}
              </span>
            )}

            {localFilters.stereoPan?.enabled && (
              <span style={{
                padding: '5px 10px',
                borderRadius: '20px',
                background: 'rgba(125,18,255,0.15)',
                fontSize: '13px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <path d="M9 18V5l12-2v13"></path>
                  <circle cx="6" cy="18" r="3"></circle>
                  <circle cx="18" cy="16" r="3"></circle>
                </svg>
                {localFilters.stereoPan?.preset === 'Gentle Movement' ? 'Subtle Movement' : 
                localFilters.stereoPan?.preset === 'Meditative Space' ? 'Deep Immersion' : 
                localFilters.stereoPan?.preset}
              </span>
            )}

            {localFilters.eq?.enabled && (
              <span style={{
                padding: '5px 10px',
                borderRadius: '20px',
                background: 'rgba(125,18,255,0.15)',
                fontSize: '13px',
                color: '#ffffff',
                display: 'flex',
                alignItems: 'center',
                gap: '5px'
              }}>
                <svg width="14" height="14" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                  <line x1="4" y1="21" x2="4" y2="14"></line>
                  <line x1="4" y1="10" x2="4" y2="3"></line>
                  <line x1="12" y1="21" x2="12" y2="12"></line>
                  <line x1="12" y1="8" x2="12" y2="3"></line>
                  <line x1="20" y1="21" x2="20" y2="16"></line>
                  <line x1="20" y1="12" x2="20" y2="3"></line>
                  <line x1="1" y1="14" x2="7" y2="14"></line>
                  <line x1="9" y1="8" x2="15" y2="8"></line>
                  <line x1="17" y1="16" x2="23" y2="16"></line>
                </svg>
                {localFilters.eq?.preset}
              </span>
            )}
          </>
        )}
      </div>
    </div>
  </>
  )}
</div>

      {/* Action Button with modified button style based on TTS state */}
      <div className="action-buttons" style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        marginTop: '32px',
        width: '100%',
        padding: '0 16px'
      }}>
<button
      onClick={handleContinue}
      disabled={
        !localMusic || 
        showMainLoading || 
        !ttsReady  // Replace complex condition with the ttsReady state
      }
      style={{
        background: '#7d12ff',
        color: '#fff',
        border: 'none',
        borderRadius: '12px',
        padding: isSmallMobile ? '12px 24px' : '14px 28px',
        fontSize: isSmallMobile ? '0.95rem' : '1rem',
        fontWeight: '500',
        cursor: (
          !localMusic || 
          showMainLoading || 
          !ttsReady  // Update here too
        ) ? 'not-allowed' : 'pointer',
        transition: 'all 0.2s ease',
        opacity: (
          !localMusic || 
          showMainLoading || 
          !ttsReady  // And here
        ) ? 0.5 : 1,
        width: isSmallMobile ? '100%' : 'auto',
        minWidth: isSmallMobile ? 'auto' : '260px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '8px',
        boxShadow: '0 4px 12px rgba(125, 18, 255, 0.25)'
      }}
      onMouseEnter={(e) => {
        if (localMusic && ttsReady && !showMainLoading) {  // Update hover logic too
          e.currentTarget.style.transform = 'translateY(-2px)';
          e.currentTarget.style.boxShadow =
            '0 6px 16px rgba(125, 18, 255, 0.35)';
        }
      }}
      onMouseLeave={(e) => {
        e.currentTarget.style.transform = '';
        e.currentTarget.style.boxShadow =
          '0 4px 12px rgba(125, 18, 255, 0.25)';
      }}
    >
      {isLoading ? (
        <>
          <span className="spinner-small" style={{
            display: 'inline-block',
            width: '16px',
            height: '16px',
            borderRadius: '50%',
            border: '2px solid #ffffff',
            borderTopColor: 'transparent',
            animation: 'spin 1s linear infinite',
            verticalAlign: 'middle'}}></span>
          <span>Processing...</span>
        </>
      ) : !ttsReady ? (  // Update button text logic
        <>
          <span>Waiting for Voice...</span>
          <span style={{ fontSize: '0.8rem', marginLeft: '4px' }}>({Math.floor(backgroundTTSProgress)}%)</span>
        </>
      ) : (
        <>
          <span>Continue to Listen</span>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </>
      )}
    </button>
      </div>

      {/* Help text when TTS is processing */}
      {isBackgroundTTSProcessing && backgroundTTSProgress < 85 && (
        <div style={{
          fontSize: '13px',
          color: 'rgba(255,255,255,0.7)',
          textAlign: 'center',
          marginTop: '10px',
          padding: '8px 12px',
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          borderRadius: '8px',
          maxWidth: '450px',
          margin: '10px auto 0'
        }}>
          Voice generation must complete before continuing. This ensures highest quality audio.
        </div>
      )}

      {/* Add the style element properly, within the return statement */}
      <style dangerouslySetInnerHTML={{ __html: getGlobalStyles(isMobile) }} />
      <style dangerouslySetInnerHTML={{ __html: loadingAnimationStyles }} />
    </div>
  );
};

export default AudioStep;