// useLoading.js
import { useState, useCallback, useRef, useEffect } from 'react';
import sseManager from '../../../utils/sseManager';

const useLoading = () => {
  // Core loading state
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [progress, setProgress] = useState(0);
  const [currentJobId, setCurrentJobId] = useState(null);
  const [currentPhase, setCurrentPhase] = useState(null);
  

  // Refs for timers and tracking
  const timeoutRef = useRef(null);
  const simulationRef = useRef(null);
  const operationStartTimeRef = useRef(null);
  const requestCanceledRef = useRef(false);
  const processTypeRef = useRef('default');

  // -------------------------------
  // Cleanup all timers and simulation intervals
  // -------------------------------
  const cleanupResources = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }
    if (simulationRef.current) {
      clearInterval(simulationRef.current);
      simulationRef.current = null;
    }
  }, []);

  useEffect(() => cleanupResources, [cleanupResources]);

  // -------------------------------
  // Clear loading state
  // -------------------------------
  const clearLoading = useCallback(() => {
    cleanupResources();
    setIsLoading(false);
    setMessage('');
    setSubtitle('');
    setProgress(0);
    setCurrentPhase(null);
    processTypeRef.current = 'default';
    operationStartTimeRef.current = null;
    requestCanceledRef.current = false;
  }, [cleanupResources]);

  // -------------------------------
  // Initialize loading state with a message, subtitle, and initial progress
  // -------------------------------
  const initLoadingState = useCallback((loadingMessage, loadingSubtitle, initialProgress) => {
    cleanupResources();
    setIsLoading(true);
    setMessage(loadingMessage);
    setSubtitle(loadingSubtitle);
    setProgress(initialProgress);
    requestCanceledRef.current = false;
    operationStartTimeRef.current = Date.now();
  }, [cleanupResources]);

  // -------------------------------
  // Update current job ID (and update state if it changes)
  // -------------------------------
  const updateJobId = useCallback((jobId) => {
    console.log(`Setting job ID: ${jobId || 'null'}`);
    if (jobId !== currentJobId) {
      setCurrentJobId(jobId);
    }
  }, [currentJobId]);

  // -------------------------------
  // Handle progress updates from server (via SSE or direct callbacks)
  // -------------------------------
  const handleServerUpdate = useCallback((data) => {
    if (!data) return;
    try {
      if (typeof data.percentage === 'number') {
        // IMPORTANT FIX: Ensure progress never decreases
        setProgress(prev => Math.max(prev, data.percentage));
      }
      if (data.message) setMessage(data.message);
      if (data.phase && data.phase !== currentPhase) {
        console.log(`Phase updated to: ${data.phase}`);
        setCurrentPhase(data.phase);
      }
      // When complete, wait briefly before clearing the loading state
      if (data.complete || (data.percentage && data.percentage >= 100)) {
        setProgress(100);
        timeoutRef.current = setTimeout(clearLoading, 500);
      }
    } catch (err) {
      console.error('Error handling server update:', err);
    }
  }, [clearLoading, currentPhase]);

  // -------------------------------
  // Simulation timer: simulate progress updates over a set duration
  // -------------------------------
  const simulateProgress = useCallback((options = {}) => {
    const { processType = 'default', targetValue = 100, duration = 3000, stepFunction = null } = options;
    if (simulationRef.current) clearInterval(simulationRef.current);
    processTypeRef.current = processType;
    if (!operationStartTimeRef.current) operationStartTimeRef.current = Date.now();
    const startTime = Date.now();
    const endTime = startTime + duration;
    
    // Track the last progress value to prevent decreasing
    let lastReportedProgress = progress;
    
    simulationRef.current = setInterval(() => {
      if (requestCanceledRef.current) {
        clearInterval(simulationRef.current);
        simulationRef.current = null;
        return;
      }
      setProgress(prev => {
        const now = Date.now();
        let newProgress;
        if (stepFunction && typeof stepFunction === 'function') {
          newProgress = stepFunction(prev, now - startTime, duration);
        } else {
          const remainingTime = Math.max(0, endTime - now);
          const progressPercent = Math.min(100, 100 - (remainingTime / duration) * 100);
          newProgress = Math.max(prev, (progressPercent / 100) * targetValue);
        }
        
        // IMPORTANT FIX: Ensure we never decrease the progress value
        newProgress = Math.max(newProgress, lastReportedProgress);
        lastReportedProgress = newProgress;
        
        if (newProgress >= targetValue || now >= endTime) {
          clearInterval(simulationRef.current);
          simulationRef.current = null;
        }
        return Math.round(newProgress);
      });
    }, 50);
    return () => {
      if (simulationRef.current) {
        clearInterval(simulationRef.current);
        simulationRef.current = null;
      }
    };
  }, [progress]);

  // -------------------------------
  // withSSELoading: Wrap a function call to allow SSE progress updates
  // -------------------------------
  const withSSELoading = useCallback(async (fn, loadingMsg = 'Loading...', loadingSub = '', initialProg = 0, options = {}) => {
    try {
      initLoadingState(loadingMsg, loadingSub, initialProg);
      const { baseUrl, simulationOptions = {} } = options;
      const { processType = 'default' } = simulationOptions;
      processTypeRef.current = processType;
      
      // Start a simulation to give immediate feedback
      simulateProgress({ processType, targetValue: 80, duration: simulationOptions.duration || 10000 });
      
      // Build an updateProgress callback to pass into the wrapped function
      const updateProgress = (newProg, newMsg, newPhase) => {
        if (typeof newProg === 'number') setProgress(newProg);
        if (newMsg) setMessage(newMsg);
        if (newPhase && newPhase !== currentPhase) setCurrentPhase(newPhase);
      };
      
      // Run the provided function
      const result = await fn(updateProgress);
      
      // If the result returns a job ID, set up SSE to track progress
      const resultJobId = result?.jobId || result?.job_id;
      if (resultJobId && resultJobId !== currentJobId) {
        console.log(`Function returned job ID: ${resultJobId}, setting up SSE`);
        updateJobId(resultJobId);
        // Use the centralized SSE manager to monitor this job
        sseManager.createConnection(resultJobId, baseUrl, {
          onMessage: handleServerUpdate,
          onComplete: () => {
            setProgress(100);
            timeoutRef.current = setTimeout(clearLoading, 500);
          },
          onError: (error) => {
            console.error(`SSE error for job ${resultJobId}:`, error);
            // Fallback simulation in case SSE fails
            simulateProgress({ processType, targetValue: 100, duration: 5000 });
          }
        });
      } else {
        // If no job ID is returned, just complete the progress
        setProgress(100);
        timeoutRef.current = setTimeout(clearLoading, 500);
      }
      return result;
    } catch (error) {
      console.error('Error in withSSELoading:', error);
      setProgress(100);
      setMessage(`Error: ${error.message}`);
      timeoutRef.current = setTimeout(clearLoading, 1500);
      throw error;
    }
  }, [initLoadingState, simulateProgress, currentPhase, currentJobId, updateJobId, clearLoading, handleServerUpdate]);

  // -------------------------------
  // withLoading: Wrap a function call with basic progress updates (no SSE)
  // -------------------------------
  const withLoading = useCallback(async (fn, loadingMsg = 'Loading...', loadingSub = '', initialProg = 0, options = {}) => {
    try {
      initLoadingState(loadingMsg, loadingSub, initialProg);
      const { processType = 'default' } = options;
      processTypeRef.current = processType;
      
      const updateProgress = (newProg, newMsg, newPhase) => {
        if (typeof newProg === 'number') setProgress(newProg);
        if (newMsg) setMessage(newMsg);
        if (newPhase && newPhase !== currentPhase) setCurrentPhase(newPhase);
      };
      
      const result = await fn(updateProgress);
      setProgress(100);
      timeoutRef.current = setTimeout(clearLoading, 500);
      return result;
    } catch (error) {
      console.error('Error in withLoading:', error);
      setProgress(100);
      setMessage(`Error: ${error.message}`);
      timeoutRef.current = setTimeout(clearLoading, 1500);
      throw error;
    }
  }, [initLoadingState, currentPhase, clearLoading]);

  // Cleanup SSE and timers on unmount
  useEffect(() => {
    return () => {
      cleanupResources();
      // SSE connections are managed globally by sseManager
    };
  }, [cleanupResources]);

  useEffect(() => {
    if (isLoading) {
      // Set a safety timeout to clear loading state after 60 seconds
      const safetyTimeout = setTimeout(() => {
        console.log("Safety timeout triggered - clearing loading state");
        clearLoading();
      }, 60000);
      
      return () => clearTimeout(safetyTimeout);
    }
  }, [isLoading, clearLoading]);

  return {
    isLoading,
    message,
    subtitle,
    progress,
    currentJobId,
    currentPhase,
    withLoading,
    withSSELoading,
    clearLoading,
    updateJobId,
    handleServerUpdate,
    setProgress, // <-- add this line

  };
};

export default useLoading;
