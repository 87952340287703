import React, { useState, useEffect, useRef } from 'react';

/**
 * A more resilient fallback visualizer with better error handling and display
 */
const EnhancedFallback = ({ isPlaying, primaryColor = '#7d12ff', secondaryColor = '#5636f3' }) => (
  <div 
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#02073c',
      borderRadius: '50%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      position: 'relative'
    }}
  >
    <div 
      style={{
        width: '60%',
        height: '60%',
        borderRadius: '50%',
        background: `radial-gradient(circle, ${primaryColor} 0%, ${secondaryColor} 70%, #02073c 100%)`,
        opacity: isPlaying ? 0.8 : 0.5,
        transition: 'all 0.3s ease',
        animation: isPlaying ? 'pulse 3s infinite alternate' : 'none',
        boxShadow: isPlaying ? `0 0 30px ${primaryColor}40` : 'none'
      }}
    />
    {isPlaying && (
      <div
        style={{
          position: 'absolute',
          width: '70%',
          height: '70%',
          borderRadius: '50%',
          border: `1px solid ${primaryColor}30`,
          animation: 'ripple 3s infinite',
          opacity: 0.6
        }}
      />
    )}
    {isPlaying && (
      <div
        style={{
          position: 'absolute',
          width: '70%',
          height: '70%',
          borderRadius: '50%',
          border: `1px solid ${primaryColor}20`,
          animation: 'ripple 3s infinite 1.5s',
          opacity: 0.4
        }}
      />
    )}
    {isPlaying && (
      <div style={{
        position: 'absolute',
        width: '80%',
        height: '80%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '2px'
      }}>
        {Array.from({ length: 9 }).map((_, i) => (
          <div
            key={i}
            style={{
              width: '4px',
              height: '20%',
              backgroundColor: primaryColor,
              opacity: 0.7,
              animation: `bar${i % 5 + 1} ${1.2 + (i * 0.1)}s ease-in-out infinite alternate`,
              borderRadius: '2px'
            }}
          />
        ))}
      </div>
    )}
    <style>{`
      @keyframes pulse {
        0% { transform: scale(0.92); opacity: 0.7; }
        100% { transform: scale(1.08); opacity: 0.9; }
      }
      @keyframes ripple {
        0% { transform: scale(0.8); opacity: 0.6; }
        50% { transform: scale(1.5); opacity: 0.3; }
        100% { transform: scale(1.8); opacity: 0; }
      }
      @keyframes bar1 {
        0% { height: 10%; }
        100% { height: 40%; }
      }
      @keyframes bar2 {
        0% { height: 30%; }
        100% { height: 70%; }
      }
      @keyframes bar3 {
        0% { height: 20%; }
        100% { height: 60%; }
      }
      @keyframes bar4 {
        0% { height: 40%; }
        100% { height: 30%; }
      }
      @keyframes bar5 {
        0% { height: 15%; }
        100% { height: 50%; }
      }
    `}</style>
  </div>
);

/**
 * A more resilient VisualizerLoader with better error handling
 */
const VisualizerLoader = ({ 
  analyzerNode, 
  isPlaying = false, 
  size = 300,
  audioUrl = null,
  primaryColor = '#7d12ff',
  secondaryColor = '#5636f3',
  tertiaryColor = '#9e65ff',
  sensitivity = 1.5,
  voiceFocus = true,
  fallbackOnly = false,
  id = 'visualizer'
}) => {
  // Component state
  const [Visualizer, setVisualizer] = useState(null);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [analyzerValid, setAnalyzerValid] = useState(false);
  const [retryCount, setRetryCount] = useState(0);
  const [renderKey, setRenderKey] = useState(Date.now());
  
  // Component refs
  const importAttemptedRef = useRef(false);
  const isMountedRef = useRef(true);
  const analyzerRef = useRef(analyzerNode);
  const errorTimeoutRef = useRef(null);
  
  // Check if analyzer node is valid
  useEffect(() => {
    analyzerRef.current = analyzerNode;
    
    if (!analyzerNode) {
      setAnalyzerValid(false);
      return;
    }
    
    try {
      // Test basic analyzer properties
      const hasGetByteFrequency = typeof analyzerNode.getByteFrequencyData === 'function';
      const hasGetByteTimeDomain = typeof analyzerNode.getByteTimeDomainData === 'function';
      const hasValidFFTSize = typeof analyzerNode.fftSize === 'number' && analyzerNode.fftSize > 0;
      
      // Create frequency array if it doesn't exist
      if (!analyzerNode.frequencyDataArray && hasValidFFTSize) {
        try {
          analyzerNode.frequencyDataArray = new Uint8Array(analyzerNode.frequencyBinCount);
        } catch (err) {
          console.warn("Could not create frequency data array:", err);
        }
      }
      
      // Create time data array if it doesn't exist
      if (!analyzerNode.timeDataArray && hasValidFFTSize) {
        try {
          analyzerNode.timeDataArray = new Uint8Array(analyzerNode.frequencyBinCount);
        } catch (err) {
          console.warn("Could not create time data array:", err);
        }
      }
      
      // Test if we can get data to verify the analyzer is truly working
      let dataValid = false;
      if (hasGetByteFrequency && analyzerNode.frequencyDataArray) {
        try {
          analyzerNode.getByteFrequencyData(analyzerNode.frequencyDataArray);
          dataValid = true;
        } catch (dataErr) {
          console.warn("Error testing analyzer data access:", dataErr);
        }
      }
      
      // Set validity based on tests
      const isValid = hasGetByteFrequency && hasValidFFTSize;
      setAnalyzerValid(isValid);
      
      // Cache working analyzer for future use
      if (isValid && typeof window !== 'undefined') {
        window._lastValidAnalyzer = analyzerNode;
      }
      
      // Try to use previously valid analyzer if current one is invalid
      if (!isValid && window?._lastValidAnalyzer) {
        analyzerRef.current = window._lastValidAnalyzer;
        setAnalyzerValid(true);
      }
    } catch (err) {
      console.warn("Error validating analyzer node:", err);
      setAnalyzerValid(false);
    }
  }, [analyzerNode]);
  
  // Dynamic import with multiple fallback strategies
  useEffect(() => {
    if (fallbackOnly) {
      setLoading(false);
      return;
    }
    
    isMountedRef.current = true;
    
    const loadVisualizer = () => {
      // Only try import once
      if (importAttemptedRef.current) {
        return;
      }
      
      importAttemptedRef.current = true;
      setLoading(true);
      
      const attemptLoad = async () => {
        try {
          // First try the default path
          let VisualizerComponent;
          try {
            // Try relative to current component
            const module = await import('../audio/ThirdEyeVisualizer');
            VisualizerComponent = module.default;
            console.log("Loaded ThirdEyeVisualizer from primary path");
          } catch (primaryError) {
            try {
              // Try alternate paths
              const module = await import('./ThirdEyeVisualizer');
              VisualizerComponent = module.default;
              console.log("Loaded ThirdEyeVisualizer from secondary path");
            } catch (secondaryError) {
              try {
                // Try global path
                const module = await import('../../components/audio/ThirdEyeVisualizer');
                VisualizerComponent = module.default;
                console.log("Loaded ThirdEyeVisualizer from tertiary path");
              } catch (tertiaryError) {
                throw new Error("All import paths failed");
              }
            }
          }
          
          if (!isMountedRef.current) return;
          
          if (VisualizerComponent) {
            setVisualizer(() => VisualizerComponent);
            setLoading(false);
            setError(false);
          } else {
            console.error("Visualizer component is undefined");
            setError(true);
            setLoading(false);
          }
        } catch (err) {
          if (!isMountedRef.current) return;
          
          console.error("Error importing visualizer:", err);
          setError(true);
          setLoading(false);
          
          // Retry logic (max 2 retries)
          if (retryCount < 2) {
            setRetryCount(prev => prev + 1);
            errorTimeoutRef.current = setTimeout(() => {
              if (isMountedRef.current) {
                importAttemptedRef.current = false;
                loadVisualizer();
              }
            }, 1000); // Wait before retry
          }
        }
      };
      
      attemptLoad();
    };
    
    // Start loading process
    loadVisualizer();
    
    // Cleanup
    return () => {
      isMountedRef.current = false;
      if (errorTimeoutRef.current) {
        clearTimeout(errorTimeoutRef.current);
      }
    };
  }, [fallbackOnly, retryCount]);
  
  // Update rendering when play state changes
  useEffect(() => {
    if (!error && Visualizer && analyzerValid) {
      setRenderKey(Date.now());
    }
  }, [isPlaying, error, Visualizer, analyzerValid]);
  
  if (loading) {
    return <EnhancedFallback isPlaying={isPlaying} primaryColor={primaryColor} secondaryColor={secondaryColor} />;
  }
  
  if (!Visualizer || error || !analyzerValid || fallbackOnly) {
    return <EnhancedFallback isPlaying={isPlaying} primaryColor={primaryColor} secondaryColor={secondaryColor} />;
  }
  
  // Render actual visualizer with error boundary
  try {
    return (
      <div style={{ width: '100%', height: '100%', position: 'relative' }} data-visualizer-id={id}>
        <Visualizer
          key={renderKey}
          analyzerNode={analyzerNode || analyzerRef.current || window?._lastValidAnalyzer}
          isPlaying={isPlaying}
          size={size}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          tertiaryColor={tertiaryColor}
          sensitivity={sensitivity}
          voiceFocus={voiceFocus}
          audioUrl={audioUrl}
          id={id}
        />
      </div>
    );
  } catch (err) {
    console.error("Error rendering visualizer:", err);
    return <EnhancedFallback isPlaying={isPlaying} primaryColor={primaryColor} secondaryColor={secondaryColor} />;
  }
};

// Export with React.memo for better performance
export default React.memo(VisualizerLoader);