import React, { useState, useEffect, useMemo } from 'react';

/**
 * Enhanced Progress Bar Component for Meditation App
 * 
 * A responsive progress bar for tracking multi-step meditation processes.
 * Automatically adapts to mobile screens with intelligent step visibility.
 * Optimized for performance and consistency with LoadingOverlay.
 */
const ProgressBar = ({ steps, currentStep }) => {
  // Track window dimensions for responsive behavior
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  // Responsive breakpoints
  const isMobile = windowDimensions.width <= 768;
  const isSmallMobile = windowDimensions.width <= 480;

  // Debounced resize handler to update dimensions
  useEffect(() => {
    let timeoutId = null;
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }, 100);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  // Determine whether to show all steps or filter for small screens
  const showAllSteps = !isSmallMobile;

  // On small screens show: first step, current step, adjacent steps, and last step
  const visibleSteps = useMemo(() => {
    if (showAllSteps) return steps;
    return steps.filter((step) => {
      if (step.id === 1 || step.id === steps.length) return true;
      if (step.id === currentStep) return true;
      if (step.id === currentStep - 1 || step.id === currentStep + 1) return true;
      return false;
    });
  }, [steps, currentStep, showAllSteps]);

  // Calculate the fill percentage using the total number of steps
  const fillPercentage = Math.max(
    0,
    Math.min(100, ((currentStep - 1) / (steps.length - 1)) * 100)
  );

  return (
    <div className="progress-bar-container">
      <div className="progress-bar">
        {/* Progress line container */}
        <div className="progress-line">
          <div
            className="progress-line-fill"
            style={{ width: `${fillPercentage}%` }}
          />
        </div>

        {/* Step indicators */}
        <div className="progress-steps">
          {visibleSteps.map((step, index) => {
            const isActive = currentStep === step.id;
            const isCompleted = step.completed;
            // If steps are skipped on small screens, show ellipsis
            if (
              !showAllSteps &&
              index > 0 &&
              visibleSteps[index - 1].id < step.id - 1
            ) {
              return (
                <React.Fragment key={`ellipsis-${step.id}`}>
                  <div className="ellipsis">…</div>
                  <StepIndicator
                    step={step}
                    isActive={isActive}
                    isCompleted={isCompleted}
                    isSmallMobile={isSmallMobile}
                    showLabel={showAllSteps || isActive}
                  />
                </React.Fragment>
              );
            }
            return (
              <StepIndicator
                key={step.id}
                step={step}
                isActive={isActive}
                isCompleted={isCompleted}
                isSmallMobile={isSmallMobile}
                showLabel={showAllSteps || isActive}
              />
            );
          })}
        </div>
      </div>

      <style jsx>{`
        .progress-bar-container {
          width: 100%;
          box-sizing: border-box;
          padding: ${isSmallMobile ? '1.2rem 8px 0.75rem' : '1rem 16px'};
        }
        .progress-bar {
          position: relative;
          width: 100%;
          /* Increased height to avoid top clipping */
          height: ${isSmallMobile ? '48px' : '54px'};
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .progress-line {
          position: absolute;
          /* Adjust top offset to shift the line slightly downward on small screens */
          top: ${isSmallMobile ? '58%' : '55%'};
          left: ${isSmallMobile ? '28px' : '36px'};
          right: ${isSmallMobile ? '28px' : '36px'};
          height: 2px;
          background-color: rgba(255, 255, 255, 0.1);
          transform: translateY(-50%);
          z-index: 0;
        }
        .progress-line-fill {
          height: 100%;
          background-color: #7d12ff;
          transition: width 0.3s ease;
          will-change: width;
        }
        .progress-steps {
          position: relative;
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          z-index: 1;
        }
        .ellipsis {
          color: rgba(255, 255, 255, 0.4);
          font-size: 1rem;
          font-weight: bold;
          z-index: 1;
          margin: 0 -5px;
        }
        @media (prefers-reduced-motion: reduce) {
          .progress-line-fill {
            transition: none;
          }
        }
      `}</style>
    </div>
  );
};

// Extracted StepIndicator component for cleaner code
const StepIndicator = ({ step, isActive, isCompleted, isSmallMobile, showLabel }) => {
  return (
    <div className="progress-step">
      <div
        className={`step-indicator ${isActive ? 'active' : ''} ${
          isCompleted ? 'completed' : ''
        }`}
      >
        {isCompleted ? (
          <svg
            width="12"
            height="12"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="3"
            strokeLinecap="round"
            strokeLinejoin="round"
            aria-hidden="true"
          >
            <path d="M5 13l4 4L19 7" />
          </svg>
        ) : (
          step.id
        )}
      </div>
      {showLabel && (
        <span className={`step-name ${isActive ? 'active' : ''}`}>
          {step.name}
        </span>
      )}
      <style jsx>{`
        .progress-step {
          display: flex;
          flex-direction: column;
          align-items: center;
          gap: 0.4rem;
          position: relative;
        }
        .step-indicator {
          width: ${isSmallMobile ? '24px' : '28px'};
          height: ${isSmallMobile ? '24px' : '28px'};
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: ${isCompleted
            ? '#7d12ff'
            : isActive
            ? 'rgba(125, 18, 255, 0.15)'
            : 'rgba(255, 255, 255, 0.1)'};
          border: ${isActive ? '2px solid #7d12ff' : 'none'};
          color: ${isCompleted
            ? '#fff'
            : isActive
            ? '#fff'
            : 'rgba(255, 255, 255, 0.6)'};
          font-size: ${isSmallMobile ? '0.75rem' : '0.85rem'};
          font-weight: 600;
          box-shadow: ${isActive
            ? isSmallMobile
              ? '0 0 3px rgba(125, 18, 255, 0.3)'
              : '0 0 5px rgba(125, 18, 255, 0.3)'
            : 'none'};
          transition: all 0.2s ease;
          will-change: transform, background-color, box-shadow;
        }
        .step-name {
          font-size: ${isSmallMobile ? '0.65rem' : '0.75rem'};
          color: ${isActive ? '#fff' : 'rgba(255, 255, 255, 0.6)'};
          font-weight: ${isActive ? '500' : '400'};
          text-align: center;
          max-width: ${isSmallMobile ? '50px' : '70px'};
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          transition: color 0.2s ease;
        }
        @media (prefers-reduced-motion: reduce) {
          .step-indicator,
          .step-name {
            transition: none;
          }
        }
      `}</style>
    </div>
  );
};

export default ProgressBar;
