import React, { useState, useEffect, useCallback, useRef } from 'react';

/**
 * Enhanced Step Navigation Component for Meditation App
 * 
 * Improved navigation bar with better handling of TTS states,
 * more resilient transitions, and enhanced user feedback.
 */
const StepNavigation = ({
  currentStep,
  totalSteps,
  onNext,
  onPrevious,
  canAdvance = true,
  isLastStep = false,
  isLoading = false,
  useSpecialTransition = false,
  // TTS state props
  ttsKey = null,
  isBackgroundTTSProcessing = false,
  backgroundTTSProgress = 0
}) => {
  // Refs
  const containerRef = useRef(null);
  const touchStartTimeRef = useRef(0);
  const navigationAttemptRef = useRef(0);
  
  // State
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);
  const [clickedButton, setClickedButton] = useState(null);
  const [prefersReducedMotion, setPrefersReducedMotion] = useState(false);
  const [waitingIndicator, setWaitingIndicator] = useState(false);
  const [transitionAttempted, setTransitionAttempted] = useState(false);
  
  // Derived values
  const isMobile = windowDimensions.width <= 768;
  const isSmallMobile = windowDimensions.width <= 480;
  
  // Check user's motion preference for accessibility
  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)');
    setPrefersReducedMotion(mediaQuery.matches);
    
    const handleMotionPreferenceChange = (e) => {
      setPrefersReducedMotion(e.matches);
    };
    
    if (mediaQuery.addEventListener) {
      mediaQuery.addEventListener('change', handleMotionPreferenceChange);
    } else {
      // Fallback for older browsers
      mediaQuery.addListener(handleMotionPreferenceChange);
    }
    
    return () => {
      if (mediaQuery.removeEventListener) {
        mediaQuery.removeEventListener('change', handleMotionPreferenceChange);
      } else {
        mediaQuery.removeListener(handleMotionPreferenceChange);
      }
    };
  }, []);
  
  // Debounced window resize handler
  useEffect(() => {
    let timeoutId = null;
    
    const handleResize = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight
        });
      }, 100);
    };
    
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);
    
    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);
  
  /**
   * Enhanced next step handler with better transitions
   */
  const handleNextStep = useCallback(() => {
    // Increase the navigation attempt counter
    navigationAttemptRef.current += 1;
    const currentAttempt = navigationAttemptRef.current;
    
    // Special handling for transitioning from Audio to Listen step
    if (currentStep === 5 && useSpecialTransition) {
      // Start a visual waiting indicator
      setWaitingIndicator(true);
      setTransitionAttempted(true);
      
      // Check for TTS readiness, with critical transition state
      if (isBackgroundTTSProcessing && backgroundTTSProgress < 85 && !ttsKey) {
        console.log("Step Navigation: TTS not ready yet, showing waiting indicator");
        
        // Set a timeout to clear waiting indicator if transition doesn't happen
        setTimeout(() => {
          if (navigationAttemptRef.current === currentAttempt) {
            setWaitingIndicator(false);
            setTransitionAttempted(false);
          }
        }, 3000);
        
        return;
      }
      
      // Ready for transition, set global state
      if (typeof window !== 'undefined') {
        window.__navTransitionInitiated = true;
        window.__navTransitionTime = Date.now();
      }
    }
    
    // Call the provided onNext function
    onNext();
    
    // Button animation
    setClickedButton('next');
    
    // Clear waiting indicators after a delay for any step
    setTimeout(() => {
      if (navigationAttemptRef.current === currentAttempt) {
        setWaitingIndicator(false);
        setTransitionAttempted(false);
      }
    }, 2000);
  }, [currentStep, useSpecialTransition, isBackgroundTTSProcessing, backgroundTTSProgress, ttsKey, onNext]);
  
  // Handle touch-based navigation
  const handleSwipeNavigation = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    
    const horizontalDistance = touchStart - touchEnd;
    const timeTaken = Date.now() - touchStartTimeRef.current;
    const MIN_SWIPE_DISTANCE = 50;
    const MAX_SWIPE_TIME = 300;
    
    if (Math.abs(horizontalDistance) > MIN_SWIPE_DISTANCE && timeTaken < MAX_SWIPE_TIME) {
      if (horizontalDistance > 0 && currentStep < totalSteps && canAdvance) {
        // Swipe left to go next
        handleNextStep();
        
        // Haptic feedback
        if ('vibrate' in navigator) {
          try {
            navigator.vibrate(10);
          } catch (e) {
            // Ignore errors with vibration API
          }
        }
      } else if (horizontalDistance < 0 && currentStep > 1) {
        // Swipe right to go back
        onPrevious();
        setClickedButton('back');
        
        // Haptic feedback
        if ('vibrate' in navigator) {
          try {
            navigator.vibrate(10);
          } catch (e) {
            // Ignore errors with vibration API
          }
        }
      }
    }
    
    // Reset touch coordinates
    setTouchStart(null);
    setTouchEnd(null);
  }, [touchStart, touchEnd, currentStep, totalSteps, canAdvance, handleNextStep, onPrevious]);
  
  // Process swipe when touch values update
  useEffect(() => {
    handleSwipeNavigation();
  }, [touchStart, touchEnd, handleSwipeNavigation]);
  
  // Reset button click animation
  useEffect(() => {
    if (clickedButton) {
      const timer = setTimeout(() => {
        setClickedButton(null);
      }, 250);
      return () => clearTimeout(timer);
    }
  }, [clickedButton]);
  
  // Keyboard shortcuts for accessibility
  useEffect(() => {
    const handleKeyDown = (e) => {
      // Skip if focus is on input or textarea
      if (e.target.tagName === 'INPUT' || e.target.tagName === 'TEXTAREA') {
        return;
      }
      
      // Arrow keys for navigation
      if (e.key === 'ArrowRight' && currentStep < totalSteps && canAdvance) {
        handleNextStep();
        setClickedButton('next');
      } else if (e.key === 'ArrowLeft' && currentStep > 1) {
        onPrevious();
        setClickedButton('back');
      }
    };
    
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [currentStep, totalSteps, canAdvance, handleNextStep, onPrevious]);
  
  // Clear transition flags when step changes
  useEffect(() => {
    setWaitingIndicator(false);
    setTransitionAttempted(false);
    
    // Clear global state
    if (typeof window !== 'undefined') {
      window.__navTransitionInitiated = false;
    }
  }, [currentStep]);
  
  // Touch handlers
  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
    touchStartTimeRef.current = Date.now();
  };
  
  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
    
    // Prevent vertical scrolling if this looks like a horizontal swipe
    if (touchStart) {
      const xDiff = touchStart - e.touches[0].clientX;
      const yDiff = touchStart - e.touches[0].clientY;
      
      if (Math.abs(xDiff) > Math.abs(yDiff) && Math.abs(xDiff) > 30) {
        e.preventDefault();
      }
    }
  };
  
  const handleTouchEnd = () => {
    if (!touchEnd) {
      setTouchStart(null);
    }
  };
  
  // Button styles
  const backButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: isSmallMobile ? '10px 16px' : '12px 20px',
    borderRadius: '10px',
    border: '1px solid rgba(255, 255, 255, 0.1)',
    background: clickedButton === 'back' ? 'rgba(255, 255, 255, 0.15)' : 'rgba(255, 255, 255, 0.05)',
    color: 'rgba(255, 255, 255, 0.9)',
    fontSize: isSmallMobile ? '0.9rem' : '1rem',
    fontWeight: '500',
    cursor: 'pointer',
    transition: prefersReducedMotion ? 'none' : 'all 0.2s ease',
    position: 'relative',
    overflow: 'hidden',
    minHeight: isMobile ? '44px' : '40px',
    minWidth: isMobile ? '88px' : 'auto',
  };
  
  const nextButtonStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '8px',
    padding: isSmallMobile ? '10px 16px' : '12px 20px',
    borderRadius: '10px',
    border: 'none',
    background: canAdvance 
      ? (clickedButton === 'next' ? '#946aff' : '#7d12ff') 
      : 'rgba(125, 18, 255, 0.3)',
    color: '#ffffff',
    fontSize: isSmallMobile ? '0.9rem' : '1rem',
    fontWeight: '500',
    cursor: canAdvance ? 'pointer' : 'not-allowed',
    transition: prefersReducedMotion ? 'none' : 'all 0.2s ease',
    boxShadow: canAdvance ? '0 4px 12px rgba(125, 18, 255, 0.25)' : 'none',
    opacity: canAdvance ? 1 : 0.7,
    position: 'relative',
    overflow: 'hidden',
    minHeight: isMobile ? '44px' : '40px',
    minWidth: isMobile ? '88px' : 'auto',
  };

  // Enhanced logic for TTS state - make this more strict
  const shouldDisableForTTS = currentStep === 5 && 
    (isBackgroundTTSProcessing || 
    backgroundTTSProgress < 100 || 
    !ttsKey);

  const effectiveCanAdvance = shouldDisableForTTS ? false : canAdvance;

  // Determine if we should hide the next button
  // The special case where we want to hide the bottom navigation's continue button
  // is when we're on step 5 (AudioStep) and useSpecialTransition is true
  const shouldHideNextButton = useSpecialTransition && currentStep === 5;
  
  // Loading state with more user feedback
  const isButtonLoading = isLoading || waitingIndicator;
  
  // Dynamic progress indicator text
  const getProgressText = () => {
    if (shouldDisableForTTS) {
      return `Voice Processing (${Math.floor(backgroundTTSProgress)}%)`;
    }
    if (transitionAttempted) {
      return "Preparing Audio...";
    }
    return "Processing...";
  };

  return (
    <nav 
      ref={containerRef}
      className="step-navigation" 
      role="navigation" 
      aria-label="Step Navigation"
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
      style={{
        display: 'flex',
        justifyContent: currentStep > 1 ? 'space-between' : 'flex-end',
        alignItems: 'center',
        width: '100%',
        maxWidth: '800px',
        margin: '0 auto',
        padding: isSmallMobile ? '16px 12px' : '20px 16px',
        paddingBottom: `calc(${isSmallMobile ? '16px' : '20px'} + env(safe-area-inset-bottom, 0px))`,
        position: 'relative',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        backdropFilter: 'blur(12px)',
        WebkitBackdropFilter: 'blur(12px)',
        borderTop: '1px solid rgba(255, 255, 255, 0.08)',
        zIndex: 10
      }}
    >
      {/* Back button */}
      {currentStep > 1 && (
        <button 
          className={`nav-button back-button ${clickedButton === 'back' ? 'animate-click' : ''}`}
          onClick={() => {
            onPrevious();
            setClickedButton('back');
          }}
          aria-label="Go to previous step"
          style={backButtonStyle}
          disabled={isButtonLoading}
        >
          {/* Ripple animation for button click */}
          {!prefersReducedMotion && clickedButton === 'back' && (
            <span 
              className="ripple-animation"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 70%)',
                transform: 'scale(0)',
                animation: 'ripple 0.5s linear',
                borderRadius: '10px'
              }}
            />
          )}
          
          <svg 
            width="16" 
            height="16" 
            fill="none" 
            stroke="currentColor" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M15 19l-7-7 7-7" 
            />
          </svg>
          <span>Back</span>
        </button>
      )}
      
      {/* Continue button with enhanced transition states */}
      {currentStep < totalSteps && !isLastStep && !shouldHideNextButton && (
        <button 
          className={`nav-button next-button ${clickedButton === 'next' ? 'animate-click' : ''}`}
          onClick={() => {
            if (effectiveCanAdvance && !isButtonLoading) {
              handleNextStep();
            }
          }}
          disabled={!effectiveCanAdvance || isButtonLoading}
          aria-label="Continue to next step"
          style={{
            ...nextButtonStyle,
            opacity: isButtonLoading ? 0.5 : (shouldDisableForTTS ? 0.4 : nextButtonStyle.opacity)
          }}
        >
          {/* Ripple animation for button click */}
          {!prefersReducedMotion && effectiveCanAdvance && clickedButton === 'next' && (
            <span 
              className="ripple-animation"
              style={{
                position: 'absolute',
                width: '100%',
                height: '100%',
                top: 0,
                left: 0,
                background: 'radial-gradient(circle, rgba(255,255,255,0.4) 0%, rgba(255,255,255,0) 70%)',
                transform: 'scale(0)',
                animation: 'ripple 0.5s linear',
                borderRadius: '10px'
              }}
            />
          )}
          
          {/* Dynamic button content based on state */}
          {isButtonLoading ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <span 
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  border: '2px solid rgba(255, 255, 255, 0.3)',
                  borderTopColor: 'white',
                  animation: 'spin 1s linear infinite'
                }}
              />
              {getProgressText()}
            </div>
          ) : shouldDisableForTTS ? (
            <div style={{
              display: 'flex',
              alignItems: 'center',
              gap: '8px'
            }}>
              <span 
                style={{
                  width: '16px',
                  height: '16px',
                  borderRadius: '50%',
                  border: '2px solid rgba(255, 255, 255, 0.3)',
                  borderTopColor: 'white',
                  animation: 'spin 1s linear infinite'
                }}
              />
              Voice Processing ({Math.floor(backgroundTTSProgress)}%)
            </div>
          ) : (
            <>
              <span>Continue</span>
              <svg 
                width="16" 
                height="16" 
                fill="none" 
                stroke="currentColor" 
                viewBox="0 0 24 24" 
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
              >
                <path 
                  strokeLinecap="round" 
                  strokeLinejoin="round" 
                  strokeWidth={2} 
                  d="M9 5l7 7-7 7" 
                />
              </svg>
            </>
          )}
        </button>
      )}      
      
      {/* Completion message */}
      {isLastStep && (
        <div 
          className="completion-message"
          aria-live="polite"
          style={{
            margin: '0 auto',
            padding: isSmallMobile ? '12px 16px' : '14px 24px',
            background: 'rgba(125, 18, 255, 0.1)',
            borderRadius: '10px',
            border: '1px solid rgba(125, 18, 255, 0.3)',
            color: '#ffffff',
            fontSize: isSmallMobile ? '0.9rem' : '1rem',
            fontWeight: '500',
            textAlign: 'center',
            display: 'flex',
            alignItems: 'center',
            gap: '8px',
            animation: prefersReducedMotion ? 'none' : 'fadeIn 0.5s ease-in-out'
          }}
        >
          <svg 
            width="20" 
            height="20" 
            fill="none" 
            stroke="#7d12ff" 
            viewBox="0 0 24 24" 
            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
          >
            <path 
              strokeLinecap="round" 
              strokeLinejoin="round" 
              strokeWidth={2} 
              d="M5 13l4 4L19 7" 
            />
          </svg>
          <span>Your meditation is complete!</span>
        </div>
      )}
      
      {/* Step indicator for mobile */}
      {isMobile && (
        <div 
          className="step-indicator-mobile"
          aria-hidden="true"
          style={{
            position: 'absolute',
            bottom: isSmallMobile ? '-20px' : '-24px',
            left: '50%',
            transform: 'translateX(-50%)',
            fontSize: '0.8rem',
            color: 'rgba(255, 255, 255, 0.6)',
            whiteSpace: 'nowrap',
            padding: '4px 8px',
            background: 'rgba(0, 0, 0, 0.5)',
            borderRadius: '12px',
            zIndex: -1
          }}
        >
          Step {currentStep} of {totalSteps}
        </div>
      )}
      
      {/* Animations */}
      <style jsx>{`
        @keyframes ripple {
          0% {
            transform: scale(0);
            opacity: 1;
          }
          80% {
            transform: scale(2.5);
            opacity: 0.3;
          }
          100% {
            transform: scale(3);
            opacity: 0;
          }
        }
        
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        
        @keyframes spin {
          to { transform: rotate(360deg); }
        }
        
        .animate-click {
          animation: ${prefersReducedMotion ? 'none' : 'pulse 0.25s ease-out'};
        }
        
        @keyframes pulse {
          0% { transform: scale(1); }
          50% { transform: scale(0.97); }
          100% { transform: scale(1); }
        }
        
        /* Accessibility focus styles */
        .nav-button:focus {
          outline: none;
          box-shadow: 0 0 0 2px #7d12ff, 0 0 0 4px rgba(125, 18, 255, 0.3);
        }
        
        /* Reduced motion preferences */
        @media (prefers-reduced-motion: reduce) {
          .ripple-animation, .animate-click {
            animation: none !important;
            transition: none !important;
          }
        }
        
        /* Touch feedback */
        .nav-button:active:not(:disabled) {
          transform: scale(0.97);
          transition: transform 0.1s ease-out;
        }
        
        /* Mobile optimizations */
        @media (max-width: 768px) {
          .nav-button {
            min-height: 44px;
            min-width: 88px;
            touch-action: manipulation;
            -webkit-tap-highlight-color: transparent;
          }
          
          /* iOS safe area handling */
          .step-navigation {
            padding-bottom: calc(16px + env(safe-area-inset-bottom, 0px));
          }
        }
      `}</style>
    </nav>
  );
};

export default StepNavigation;