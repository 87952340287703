// SafeListenStepWrapper.js
import React, { useState, useEffect, useRef } from 'react';

/**
 * SafeListenStepWrapper - Error boundary for ListenStep component
 * This component catches errors in the ListenStep component and provides a fallback
 * audio player if the main player fails to render.
 */
const SafeListenStepWrapper = ({ 
  children, 
  audioUrl, 
  onReset, 
  onExportAudio,
  toast 
}) => {
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const isMountedRef = useRef(true);
  
  // Setup error handling on mount
  useEffect(() => {
    // Mark component as mounted
    isMountedRef.current = true;
    
    // Error event handler for global errors
    const handleError = (event) => {
      // Only handle errors related to ListenStep or visualization
      if (event.message && (
          event.message.includes('ListenStep') || 
          event.message.includes('ThirdEyeVisualizer') ||
          event.message.includes('Cannot access') ||
          event.message.includes('before initialization')
        )) {
        console.error('SafeListenStepWrapper caught error:', event);
        
        if (isMountedRef.current) {
          setHasError(true);
          setErrorMessage(event.message || 'Unknown error in audio player');
        }
        
        // Try to clear any loading states in the parent component
        if (window.__safetyTimeout) {
          clearTimeout(window.__safetyTimeout);
        }
        
        if (typeof window !== 'undefined') {
          window.__loadingStillActive = false;
          
          // Dispatch an event that parent components can listen for
          try {
            window.dispatchEvent(new CustomEvent('listen-step-error', {
              detail: { message: event.message, timestamp: Date.now() }
            }));
          } catch (e) {
            console.warn('Error dispatching error event:', e);
          }
        }
        
        // Show a toast notification if available
        if (toast && typeof toast.warning === 'function') {
          toast.warning('Using simplified player due to technical issue');
        }
        
        // Prevent error from propagating
        event.preventDefault();
      }
    };
    
    // Add global error listener
    window.addEventListener('error', handleError);
    
    // Clean up on unmount
    return () => {
      isMountedRef.current = false;
      window.removeEventListener('error', handleError);
    };
  }, [toast]);
  
  // If there's an error, show a simple fallback player
  if (hasError) {
    return (
      <div className="fallback-player-container" style={{ padding: '20px' }}>
        <h2 style={{ color: 'white', fontSize: '24px', marginBottom: '20px' }}>Your Meditation is Ready</h2>
        <p style={{ color: 'rgba(255,255,255,0.7)', fontSize: '14px', marginBottom: '16px' }}>
          Using simplified player due to a technical issue.
        </p>
        
        <audio
          src={audioUrl}
          controls
          style={{ 
            width: '100%', 
            borderRadius: '8px', 
            backgroundColor: '#1C1C2E',
            color: 'white',
            marginTop: '16px',
            marginBottom: '20px'
          }}
        />
        
        <div style={{ display: 'flex', justifyContent: 'center', gap: '20px', marginTop: '20px' }}>
          {typeof onExportAudio === 'function' && (
            <button
              onClick={onExportAudio}
              style={{
                padding: '12px 24px',
                background: 'linear-gradient(135deg, #7d12ff 0%, #5636f3 100%)',
                border: 'none',
                borderRadius: '8px',
                color: 'white',
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: '500'
              }}
            >
              Download
            </button>
          )}
          
          {typeof onReset === 'function' && (
            <button
              onClick={onReset}
              style={{
                padding: '12px 24px',
                backgroundColor: 'rgba(255,255,255,0.1)',
                border: '1px solid rgba(255,255,255,0.2)',
                borderRadius: '8px',
                color: 'white',
                cursor: 'pointer',
                fontSize: '16px',
                fontWeight: '500'
              }}
            >
              New Meditation
            </button>
          )}
        </div>
        
        {errorMessage && (
          <div style={{ 
            marginTop: '20px', 
            padding: '10px', 
            backgroundColor: 'rgba(255,0,0,0.1)',
            borderRadius: '4px',
            color: 'rgba(255,255,255,0.7)',
            fontSize: '12px'
          }}>
            Error details: {errorMessage}
          </div>
        )}
      </div>
    );
  }
  
  // Render the children components with try/catch for immediate errors
  try {
    return children;
  } catch (err) {
    console.error("Error rendering ListenStep component:", err);
    
    // Update state on next tick to avoid React rendering issues
    setTimeout(() => {
      if (isMountedRef.current) {
        setHasError(true);
        setErrorMessage(err.message || 'Unknown error');
      }
    }, 0);
    
    // Return placeholder during the transition
    return (
      <div style={{ padding: '20px', color: 'white' }}>
        Loading player...
      </div>
    );
  }
};

export default SafeListenStepWrapper;