// src/pages/MeditationApp/components/FilterPresets.js

// UI display name to backend preset key mapping
export const presetKeyMap = {
  // Reverb presets
  'Intimate Room': 'intimate_room',
  'Intimate Space': 'intimate_room',     // Legacy support
  'Sacred Hall': 'sacred_hall',
  'Expansive Hall': 'sacred_hall',        // Legacy support
  'Warm Hall': 'warm_hall',
  'Chamber': 'intimate_room',             // Map Chamber to intimate_room
  'Concert Hall': 'warm_hall',            // Map Concert Hall to warm_hall
  'Cathedral': 'sacred_hall',             // Map Cathedral to sacred_hall
  'Plate': 'plate',
  'Mountain Echo': 'mountain_echo',

  // StereoPan presets
  'Gentle Movement': 'gentle_space',
  'Subtle Movement': 'gentle_space',      // Legacy support
  'Slow Waves': 'dreamy_motion',
  'Deep Immersion': 'organic_flow',
  'Meditative Space': 'organic_flow',     // Legacy support

  // EQ presets
  'Voice Clarity': 'enhanced_clarity',
  'Enhanced Clarity': 'enhanced_clarity', // Legacy support 
  'Warm Voice': 'warm_tone',
  'Warm Tone': 'warm_tone',               // Legacy support
  'No Enhancement': 'no_enhancement'
};

// IR path mapping for reverb presets
export const presetIRMap = {
  'Intimate Room': 'IRs/small_room.wav',
  'Intimate Space': 'IRs/small_room.wav',  // Legacy support
  'Sacred Hall': 'IRs/large_hall.wav',
  'Expansive Hall': 'IRs/large_hall.wav',   // Legacy support
  'Warm Hall': 'IRs/medium_hall.wav',
  'Mountain Echo': 'IRs/canyon.wav',
  'Chamber': 'IRs/M7/CHAMBER_A1.wav',
  'Concert Hall': 'IRs/M7/CONHALL_A1.wav',
  'Cathedral': 'IRs/M7/CATHDRL_A1.wav',
  'Plate': 'IRs/M7/PLATE_A1.wav'
};

export const presetsMap = {
  stereoPan: [
    {
      label: "Gentle Movement",         // UI display name
      preset: "gentle_space",            // Backend preset key
      value: 0.3,
      speed: 15,
      pattern: "sine",
      binaural_shift: 4.0,
      beat_freq: 4.0,
      enable_binaural: true,
      hold_time: 2.0,
      description: "Subtle spatial enhancement for immersion"
    },
    {
      label: "Slow Waves",
      preset: "dreamy_motion",
      value: 0.4,
      speed: 8,
      pattern: "triangle",
      binaural_shift: 4.0,
      beat_freq: 3.5,
      enable_binaural: true,
      hold_time: 3.0,
      description: "Slow, dream-like spatial movement"
    },
    {
      label: "Deep Immersion",
      preset: "organic_flow",
      value: 0.5,
      speed: 30,
      pattern: "sine",
      binaural_shift: 5.5,
      beat_freq: 4.5,
      enable_binaural: true,
      hold_time: 2.0,
      description: "Natural, unpredictable spatial movement"
    }
  ],
  
  reverb: [
    {
      label: "Sacred Hall",
      preset: "sacred_hall",
      impulse_response_path: "IRs/M7/HALL_B1.wav",
      wet: 0.8,
      dry: 0.7,
      description: "Large, ethereal space perfect for deep meditation"
    },
    {
      label: "Intimate Room",           // Changed from "Chamber" to align naming
      preset: "intimate_room",
      impulse_response_path: "IRs/M7/CHAMBER_A1.wav",
      wet: 0.6,
      dry: 0.75,
      description: "Intimate space with warm, natural reflections"
    },
    {
      label: "Warm Hall",               // Changed from "Concert Hall" to align naming
      preset: "warm_hall",
      impulse_response_path: "IRs/M7/CONHALL_A1.wav",
      wet: 0.5,
      dry: 0.8,
      description: "Clear, balanced space with smooth decay"
    },
    {
      label: "Plate",
      preset: "plate",
      impulse_response_path: "IRs/M7/PLATE_A1.wav",
      wet: 0.7,
      dry: 0.75,
      description: "Smooth, dense reverb perfect for vocals"
    },
    {
      label: "Cathedral",
      preset: "cathedral",
      impulse_response_path: "IRs/M7/CATHDRL_A1.wav",
      wet: 0.9,
      dry: 0.7,
      description: "Expansive, spiritual space with crystalline reflections"
    }
  ],
  
  // EQ presets using snake_case keys
  eq: [
    {
      label: "Voice Clarity",
      preset: "enhanced_clarity",
      low_cut: 120,
      mid_gain: 3.0,
      mid_freq: 1800,
      mid_Q: 1.0,
      high_cut: 16000,
      description: "Boosted mid-range for clearer voice"
    },
    {
      label: "Warm Tone",
      preset: "warm_tone",
      low_cut: 60,
      mid_gain: -1.0,
      mid_freq: 3000,
      mid_Q: 0.7,
      high_cut: 10000,
      description: "Smooth, relaxing bass enhancement"
    },
    {
      label: "No Enhancement",
      preset: "no_enhancement",
      enabled: false,
      description: "Natural voice without EQ adjustment"
    }
  ]
};

export const effectControls = {
  stereoPan: {
    value: {
      min: 0,
      max: 0.5,
      default: 0.3,
      step: 0.01,
    },
    speed: {
      min: 4,
      max: 30,
      default: 15,
      step: 0.5,
    },
    hold_time: {
      min: 0.5,
      max: 5.0,
      default: 2.0,
      step: 0.1,
    },
    pattern: {
      options: ["sine", "triangle", "square", "random", "hold_center"],
      default: "sine",
    },
    binaural_shift: {
      min: 0,
      max: 10,
      default: 4.0,
      step: 0.1,
      label: "Binaural Shift (Hz)",
      tooltip: "Frequency difference between left and right channels to induce binaural beats",
    },
    beat_freq: {
      min: 2.0,
      max: 10.0,
      default: 4.0,
      step: 0.1,
      label: "Binaural Beat Frequency",
      tooltip: "Frequency of binaural beats effect",
    },
    enable_binaural: {
      default: true,
      label: "Enable Binaural Processing",
      tooltip: "Apply phase shifting for binaural effects",
    },
  },
  
  reverb: {
    wet: {
      min: 0,
      max: 1.0,
      default: 0.6,
      step: 0.05,
    },
    dry: {
      min: 0.5,
      max: 1.0,
      default: 0.7,
      step: 0.05,
    },
  },
  
  // EQ controls with snake_case keys
  eq: {
    low_cut: {
      min: 40,
      max: 200,
      default: 80,
      step: 10,
      label: "Low Cut (Hz)",
      tooltip: "Frequency below which bass is reduced"
    },
    mid_gain: {
      min: -6,
      max: 6,
      default: 3.0,
      step: 0.5,
      label: "Mid Gain (dB)",
      tooltip: "Boost or cut the mid frequencies"
    },
    mid_freq: {
      min: 500,
      max: 5000,
      default: 1800,
      step: 100,
      label: "Mid Frequency (Hz)",
      tooltip: "Center frequency of mid-range adjustment"
    },
    mid_Q: {
      min: 0.5,
      max: 2.0,
      default: 1.0,
      step: 0.1,
      label: "Mid Q",
      tooltip: "Width of mid-range adjustment (higher = narrower)"
    },
    high_cut: {
      min: 5000,
      max: 20000,
      default: 12000,
      step: 500,
      label: "High Cut (Hz)",
      tooltip: "Frequency above which treble is reduced"
    }
  }
};

// Helper function to get backend preset key from UI display name
export const getBackendPresetKey = (displayName, filterType) => {
  // First check the mapping
  if (presetKeyMap[displayName]) {
    return presetKeyMap[displayName];
  }
  // Fallback: convert display name to snake_case
  return displayName.toLowerCase().replace(/\s+/g, '_');
};

// Helper function to get preset config by display name
export const getPresetConfigByName = (displayName, filterType) => {
  if (!filterType || !displayName) return null;
  const presets = presetsMap[filterType] || [];
  return presets.find(preset => preset.label === displayName);
};

export const frequencyMappings = {
  rootChakra: 194.18,   // G3
  sacralChakra: 210.42,  // G#3
  solarPlexus: 126.22,   // B2
  heartChakra: 341.3,    // F4
  throatChakra: 384.0,   // G4
  thirdEye: 417.0,       // G#4
  crownChakra: 432.0,    // A4
  earthTone: 126.22,     // B2
  om: 432.0,             // A4
  healing: 285.0,        // D4
  harmony: 396.0         // G4
};
