/**
 * Enhanced audio-sync-fix.js with improved error handling and compatibility
 */

// Track global audio state across components
if (typeof window !== 'undefined' && !window.globalAudioState) {
    window.globalAudioState = {
      _audioContext: null,
      _analyzer: null,
      _audioElement: null,
      _connectedElements: new WeakMap(),
      _sourceNodes: new WeakMap(),
      _currentUrl: null,
      _isPlaying: false,
      _lastErrorTime: null,
      _errorCount: 0
    };
  }
  
  /**
   * Initializes audio with better error handling and compatibility
   */
  export const initializeAudio = (options = {}) => {
    const {
      audioUrl,
      globalAudioState = window.globalAudioState,
      audioElementRef,
      waveSurferRef,
      setAudioElement,
      createFallbackElement,
      onAudioReady
    } = options;
  
    try {
      // First check for a globally shared audio element
      if (globalAudioState?._audioElement) {
        console.log("Audio Sync: Using global audio element");
        
        const existingElement = globalAudioState._audioElement;
        
        // Update URL if needed
        if (audioUrl && existingElement.src !== audioUrl) {
          try {
            existingElement.src = audioUrl;
            existingElement.load();
            globalAudioState._currentUrl = audioUrl;
          } catch (e) {
            console.warn("Audio Sync: Error updating global element URL", e);
          }
        }
        
        // Register with ref system
        if (audioElementRef) {
          audioElementRef.current = existingElement;
        }
        if (setAudioElement) {
          setAudioElement(existingElement);
        }
        
        // If ready, trigger callback
        if (existingElement.readyState >= 2) {
          setTimeout(() => {
            if (onAudioReady) onAudioReady({ 
              url: existingElement.src, 
              duration: existingElement.duration || 0 
            });
          }, 50);
        }
        
        return {
          audioElement: existingElement,
          isNewElementCreated: false
        };
      }
      
      // Create a new audio element
      console.log("Audio Sync: Creating new audio element");
      
      const audioElement = new Audio();
      audioElement.crossOrigin = "anonymous";
      audioElement.preload = "auto";
      
      // Add error handling
      audioElement.onerror = (e) => {
        console.warn("Audio element encountered error:", e);
        if (globalAudioState) {
          globalAudioState._lastErrorTime = Date.now();
          globalAudioState._errorCount++;
        }
      };
      
      // Add ready state change handler
      audioElement.onloadedmetadata = () => {
        if (onAudioReady) {
          onAudioReady({ 
            url: audioElement.src, 
            duration: audioElement.duration || 0 
          });
        }
      };
      
      // Register with global state
      if (globalAudioState) {
        globalAudioState._audioElement = audioElement;
      }
      
      // Set source
      if (audioUrl) {
        // Handle CORS for AWS S3 URLs
        if (audioUrl.includes('amazonaws.com') || audioUrl.includes('pausebeta20')) {
          // Ensure the URL has CORS headers
          const url = new URL(audioUrl);
          if (!url.searchParams.has('response-content-type')) {
            url.searchParams.append('response-content-type', 'audio/mpeg');
          }
          audioElement.src = url.toString();
        } else {
          audioElement.src = audioUrl;
        }
        
        if (globalAudioState) {
          globalAudioState._currentUrl = audioUrl;
        }
        audioElement.load();
      }
      
      // Register with ref system
      if (audioElementRef) {
        audioElementRef.current = audioElement;
      }
      if (setAudioElement) {
        setAudioElement(audioElement);
      }
      
      // Configure WaveSurfer to use this element
      if (waveSurferRef?.current?.params?.backend === 'MediaElement') {
        try {
          waveSurferRef.current.setMediaElement(audioElement);
        } catch (e) {
          console.warn("Audio Sync: Error configuring WaveSurfer", e);
        }
      }
      
      return {
        audioElement,
        isNewElementCreated: true
      };
    } catch (error) {
      console.error("Audio Sync: Error creating audio element", error);
      
      // Try to create a fallback element
      if (typeof createFallbackElement === 'function') {
        try {
          const fallbackElement = createFallbackElement();
          return {
            audioElement: fallbackElement,
            isNewElementCreated: true,
            usedFallback: true
          };
        } catch (fallbackError) {
          console.error("Audio Sync: Fallback creation also failed", fallbackError);
        }
      }
      
      // Last resort: a plain audio element with no source
      try {
        const lastResortElement = new Audio();
        
        if (audioElementRef) {
          audioElementRef.current = lastResortElement;
        }
        if (setAudioElement) {
          setAudioElement(lastResortElement);
        }
        
        return {
          audioElement: lastResortElement,
          isNewElementCreated: true,
          isLastResort: true
        };
      } catch (e) {
        // Nothing else we can do
        return {
          audioElement: null,
          isNewElementCreated: false,
          error: error.message
        };
      }
    }
  };
  
  /**
   * Initializes audio analyzer with better error recovery
   */
  export const initializeAudioAnalysis = (options = {}) => {
    const {
      audioElement,
      audioContext,
      audioContextRef,
      analyzerRef,
      mounted,
      isUsingGlobalAudio,
      globalAudioRef = window.globalAudioState,
      setAnalyzerNode
    } = options;
    
    // Early return if not mounted
    if (mounted && !mounted.current) return null;
    
    try {
      // Return existing analyzer if available
      if (analyzerRef?.current) {
        if (setAnalyzerNode) {
          setAnalyzerNode(analyzerRef.current);
        }
        return analyzerRef.current;
      }
      
      // Use global analyzer if available
      if (globalAudioRef?._analyzer && globalAudioRef?._audioContext) {
        if (analyzerRef) {
          analyzerRef.current = globalAudioRef._analyzer;
        }
        if (audioContextRef) {
          audioContextRef.current = globalAudioRef._audioContext;
        }
        if (setAnalyzerNode) {
          setAnalyzerNode(globalAudioRef._analyzer);
        }
        
        // Ensure the audio element is connected
        if (audioElement && globalAudioRef._audioContext && globalAudioRef._analyzer) {
          try {
            // Check if already connected to this analyzer
            const isConnected = globalAudioRef._connectedElements?.has?.(audioElement);
            
            if (!isConnected) {
              // Create source node if needed
              let source;
              try {
                source = globalAudioRef._audioContext.createMediaElementSource(audioElement);
              } catch (sourceErr) {
                // Likely already connected elsewhere
                console.log("Element already connected, using oscillator for analysis");
                
                // Create silent oscillator as fallback
                const osc = globalAudioRef._audioContext.createOscillator();
                osc.frequency.value = 0;
                osc.connect(globalAudioRef._analyzer);
                osc.start();
                return globalAudioRef._analyzer;
              }
              
              // Connect to analyzer
              if (source) {
                source.connect(globalAudioRef._analyzer);
                globalAudioRef._analyzer.connect(globalAudioRef._audioContext.destination);
                
                // Track connection
                if (!globalAudioRef._connectedElements) {
                  globalAudioRef._connectedElements = new WeakMap();
                }
                globalAudioRef._connectedElements.set(audioElement, source);
              }
            }
          } catch (err) {
            console.warn("Error connecting global analyzer:", err);
          }
        }
        
        return globalAudioRef._analyzer;
      }
      
      // Get or create audio context
      let context = audioContext || audioContextRef?.current;
      
      if (!context) {
        const AudioContext = window.AudioContext || window.webkitAudioContext;
        if (!AudioContext) {
          console.warn("AudioContext not supported in this browser");
          return null;
        }
        
        context = new AudioContext();
        if (audioContextRef) {
          audioContextRef.current = context;
        }
      }
      
      // Get audio element
      const element = audioElement || 
                     (isUsingGlobalAudio ? globalAudioRef?._audioElement : null);
      
      if (!element) {
        console.warn("No audio element available for analysis");
        return null;
      }
      
      // Resume context if suspended
      if (context.state === 'suspended') {
        try {
          context.resume();
        } catch (err) {
          console.warn("Error resuming AudioContext:", err);
        }
      }
      
      // Create analyzer node with error handling
      let analyzer, source;
      
      try {
        analyzer = context.createAnalyser();
        analyzer.fftSize = 1024;
        analyzer.smoothingTimeConstant = 0.85;
        
        // Initialize data arrays
        analyzer.frequencyDataArray = new Uint8Array(analyzer.frequencyBinCount);
        analyzer.timeDataArray = new Uint8Array(analyzer.frequencyBinCount);
        
        // Create source node with error handling
        try {
          // Check if already connected
          const alreadyConnected = 
            (context._sources && context._sources[element]) || 
            (context._connectedElements && context._connectedElements.has(element));
          
          if (alreadyConnected) {
            console.log("Element already connected, using existing source");
            
            if (context._sources && context._sources[element]) {
              source = context._sources[element];
            }
            else if (context._connectedElements && context._connectedElements.has(element)) {
              source = context._connectedElements.get(element);
            }
            
            // Ensure connected to this analyzer
            source.connect(analyzer);
          } else {
            // Create new source
            source = context.createMediaElementSource(element);
            source.connect(analyzer);
            analyzer.connect(context.destination);
          }
        } catch (sourceErr) {
          // Handle "already connected" error
          if (sourceErr.message && sourceErr.message.includes('already connected')) {
            console.log("Element already connected elsewhere, using oscillator");
            
            // Use silent oscillator as fallback
            const oscillator = context.createOscillator();
            oscillator.frequency.value = 0;
            oscillator.connect(analyzer);
            analyzer.connect(context.destination);
            oscillator.start();
          } else {
            console.error("Error creating media source:", sourceErr);
            throw sourceErr;
          }
        }
        
        // Store references
        if (!context._sources) context._sources = {};
        context._sources[element] = source;
        
        if (!context._connectedElements) {
          context._connectedElements = new WeakMap();
        }
        if (source) {
          context._connectedElements.set(element, source);
        }
        
        if (analyzerRef) {
          analyzerRef.current = analyzer;
        }
        if (setAnalyzerNode) {
          setAnalyzerNode(analyzer);
        }
        
        // Share with global state
        if (globalAudioRef) {
          globalAudioRef._analyzer = analyzer;
          globalAudioRef._audioContext = context;
        }
        
        return analyzer;
      } catch (err) {
        console.error("Error setting up analyzer:", err);
        
        // Last resort: create analyzer without connecting to audio element
        try {
          const fallbackAnalyzer = context.createAnalyser();
          fallbackAnalyzer.fftSize = 1024;
          
          // Set up data arrays
          fallbackAnalyzer.frequencyDataArray = new Uint8Array(fallbackAnalyzer.frequencyBinCount);
          fallbackAnalyzer.timeDataArray = new Uint8Array(fallbackAnalyzer.frequencyBinCount);
          
          // Connect silent oscillator
          const osc = context.createOscillator();
          osc.frequency.value = 0;
          osc.connect(fallbackAnalyzer);
          fallbackAnalyzer.connect(context.destination);
          osc.start();
          
          if (analyzerRef) {
            analyzerRef.current = fallbackAnalyzer;
          }
          if (setAnalyzerNode) {
            setAnalyzerNode(fallbackAnalyzer);
          }
          
          return fallbackAnalyzer;
        } catch (fallbackErr) {
          console.error("Fallback analyzer creation failed:", fallbackErr);
          return null;
        }
      }
    } catch (error) {
      console.error("General error in initializeAudioAnalysis:", error);
      return null;
    }
  };
  
  /**
   * Synchronizes playback state between components
   */
  export const syncPlayback = (options = {}) => {
    const {
      isPlaying,
      audioElement,
      waveSurfer,
      globalAudioState = window.globalAudioState
    } = options;
    
    try {
      // Update global state
      if (globalAudioState) {
        globalAudioState._isPlaying = isPlaying;
      }
      
      // Sync wavesurfer
      if (waveSurfer) {
        try {
          isPlaying ? waveSurfer.play() : waveSurfer.pause();
        } catch (err) {
          console.warn("WaveSurfer playback sync error:", err);
        }
      }
      
      // Sync audio element
      if (audioElement) {
        if (isPlaying) {
          // Handle autoplay restrictions
          const playPromise = audioElement.play();
          
          if (playPromise !== undefined) {
            playPromise.catch(error => {
              if (error.name === 'NotAllowedError') {
                console.warn("Playback blocked by browser, needs user interaction");
                
                // Set up handlers for user interaction
                const handleUserInteraction = () => {
                  audioElement.play().catch(e => {
                    console.warn("Play still failed after interaction:", e);
                  });
                  
                  document.removeEventListener('click', handleUserInteraction);
                  document.removeEventListener('touchstart', handleUserInteraction);
                  document.removeEventListener('keydown', handleUserInteraction);
                };
                
                document.addEventListener('click', handleUserInteraction, { once: true });
                document.addEventListener('touchstart', handleUserInteraction, { once: true });
                document.addEventListener('keydown', handleUserInteraction, { once: true });
              } else {
                console.error("Error starting playback:", error);
              }
            });
          }
        } else {
          audioElement.pause();
        }
        
        return true;
      }
      
      return waveSurfer ? true : false;
    } catch (error) {
      console.error("General playback sync error:", error);
      return false;
    }
  };
  
  /**
   * Synchronizes time position
   */
  export const syncTimePosition = (options = {}) => {
    const {
      currentTime,
      audioElement,
      waveSurfer
    } = options;
    
    try {
      // Sync audio element
      if (audioElement) {
        try {
          audioElement.currentTime = currentTime;
        } catch (err) {
          console.warn("Error setting audio element time:", err);
        }
      }
      
      // Sync wavesurfer
      if (waveSurfer) {
        try {
          const duration = waveSurfer.getDuration() || 1;
          waveSurfer.seekTo(currentTime / duration);
        } catch (err) {
          console.warn("Error setting WaveSurfer time:", err);
        }
      }
      
      return !!(audioElement || waveSurfer);
    } catch (error) {
      console.error("General time sync error:", error);
      return false;
    }
  };