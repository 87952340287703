import React, { useState, useEffect } from 'react';

const ScriptStep = ({
  meditationScript,
  setMeditationScript,
  onScriptReady,
  globalPauseFactor = 1.2,
  setGlobalPauseFactor
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  
  const handleScriptChange = (e) => {
    setMeditationScript(e.target.value);
  };

  const handlePauseFactorChange = (e) => {
    setGlobalPauseFactor(parseFloat(e.target.value));
  };
  
  const handleFocus = () => setIsFocused(true);
  const handleBlur = () => setIsFocused(false);

  return (
    <div className="step-container">
      <div className="step-header">
        <h2 className="step-title">Review Your Meditation Script</h2>
        <p className="step-description">
          You can edit the script below to personalize your meditation experience.
        </p>
      </div>

      {/* Script textarea with styling that matches the app's sleek design */}
      <div className="editor-container" style={{
        width: '100%',
        maxWidth: '900px',
        margin: '24px auto',
        padding: '0 16px'
      }}>
        <div className={`script-editor ${isFocused ? 'focused' : ''}`} style={{
          width: '100%',
          background: 'rgba(25, 25, 35, 0.6)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          borderRadius: '16px',
          border: isFocused ? '1px solid rgba(255, 255, 255, 0.15)' : '1px solid rgba(255, 255, 255, 0.05)',
          transition: 'all 0.3s cubic-bezier(0.22, 1, 0.36, 1)',
          boxShadow: isFocused ? 
            '0 8px 16px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.2)' : 
            '3px 3px 8px rgba(0, 0, 0, 0.3), -1px -1px 2px rgba(255, 255, 255, 0.05)',
          overflow: 'hidden'
        }}>
          <textarea
            className="script-textarea"
            value={meditationScript}
            onChange={handleScriptChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            placeholder="Your meditation script will appear here..."
            style={{
              width: '100%',
              minHeight: '350px',
              background: 'transparent',
              color: 'rgba(255, 255, 255, 0.9)',
              border: 'none',
              borderRadius: '16px',
              padding: '20px',
              resize: 'vertical',
              fontFamily: 'inherit',
              fontSize: '0.95rem',
              lineHeight: '1.5',
              outline: 'none'
            }}
          ></textarea>
        </div>
      </div>

      {/* Pause factor control with styling that matches the toolbar */}
      <div className="pause-factor-container" style={{
        width: '100%',
        maxWidth: '700px',
        margin: '24px auto',
        padding: '0 16px'
      }}>
        <div style={{
          width: '100%',
          background: 'rgba(25, 25, 35, 0.6)',
          backdropFilter: 'blur(10px)',
          WebkitBackdropFilter: 'blur(10px)',
          borderRadius: '16px',
          border: '1px solid rgba(255, 255, 255, 0.05)',
          boxShadow: '3px 3px 8px rgba(0, 0, 0, 0.3), -1px -1px 2px rgba(255, 255, 255, 0.05)',
          padding: '20px',
          transition: 'all 0.3s cubic-bezier(0.22, 1, 0.36, 1)'
        }}>
          <div className="pause-factor-row" style={{
            display: 'flex',
            alignItems: 'center',
            gap: '10px',
            marginBottom: '8px'
          }}>
            <div className="pause-factor-label" style={{
              minWidth: '110px',
              color: 'rgba(255, 255, 255, 0.9)',
              display: 'flex',
              alignItems: 'center',
              gap: '5px',
              fontSize: '0.95rem'
            }}>
              Pause Pacing
              <div className="pause-factor-info" style={{
                cursor: 'help',
                border: '1px solid rgba(255, 255, 255, 0.2)',
                borderRadius: '50%',
                width: '18px',
                height: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                fontSize: '0.8rem',
                color: 'rgba(255, 255, 255, 0.7)'
              }}>?</div>
            </div>
            
            <input
              type="range"
              className="pause-factor-slider"
              min="0.8"
              max="2.0"
              step="0.1"
              value={globalPauseFactor}
              onChange={handlePauseFactorChange}
              style={{
                flex: '1',
                height: '4px',
                background: 'rgba(58, 58, 60, 0.7)',
                borderRadius: '2px',
                outline: 'none',
                WebkitAppearance: 'none',
                position: 'relative'
              }}
            />
            
            <div className="pause-factor-value" style={{
              minWidth: '36px',
              textAlign: 'center',
              color: '#8a56ff',
              fontWeight: '500',
              background: 'rgba(138, 86, 255, 0.15)',
              padding: '4px 10px',
              borderRadius: '10px',
              fontSize: '0.95rem'
            }}>
              {globalPauseFactor.toFixed(1)}
            </div>
          </div>
          
          <div className="pause-factor-labels" style={{
            display: 'flex',
            justifyContent: 'space-between',
            color: 'rgba(255, 255, 255, 0.6)',
            fontSize: '0.85rem',
            padding: '0 10px'
          }}>
            <span>Fast</span>
            <span>Moderate</span>
            <span>Slow</span>
          </div>
          
          {/* Custom CSS for slider thumb to match app style */}
          <style jsx>{`
            .pause-factor-slider::-webkit-slider-thumb {
              -webkit-appearance: none;
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: #8a56ff;
              cursor: pointer;
              box-shadow: 0 0 6px rgba(138, 86, 255, 0.4);
            }
            
            .pause-factor-slider::-moz-range-thumb {
              width: 16px;
              height: 16px;
              border-radius: 50%;
              background: #8a56ff;
              cursor: pointer;
              border: none;
              box-shadow: 0 0 6px rgba(138, 86, 255, 0.4);
            }
          `}</style>
        </div>
      </div>

      {/* Action button that matches the app's design system */}
      <div className="action-buttons" style={{
        display: 'flex',
        justifyContent: 'center',
        gap: '16px',
        marginTop: '32px',
        marginBottom: '16px',
        padding: '0 16px',
        width: '100%'
      }}>
        <button
          className="primary-btn"
          onClick={onScriptReady}
          disabled={!meditationScript.trim()}
          style={{
            background: '#7d12ff',
            color: '#fff',
            border: 'none',
            borderRadius: '12px',
            padding: '14px 28px',
            fontSize: '1rem',
            fontWeight: '500',
            cursor: 'pointer',
            transition: 'all 0.2s ease',
            boxShadow: '0 4px 12px rgba(125, 18, 255, 0.25)',
            width: windowWidth < 480 ? '100%' : 'auto',
            minWidth: windowWidth < 480 ? 'auto' : '260px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px'
          }}
          onMouseEnter={(e) => {
            e.currentTarget.style.transform = 'translateY(-2px)';
            e.currentTarget.style.boxShadow = '0 6px 16px rgba(125, 18, 255, 0.35)';
          }}
          onMouseLeave={(e) => {
            e.currentTarget.style.transform = '';
            e.currentTarget.style.boxShadow = '0 4px 12px rgba(125, 18, 255, 0.25)';
          }}
        >
          Continue to Voice Selection
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M5 12h14M12 5l7 7-7 7"/>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default ScriptStep; 