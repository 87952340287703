import React, { useState, useEffect, useRef } from 'react';

/**
 * Simplified TTS Progress Indicator Component
 * Displays the current state of text-to-speech generation
 * Fixed to work reliably across components with global state synchronization
 */
const TTSProgressIndicator = ({
  isBackgroundTTSProcessing = false,
  backgroundTTSProgress = 0,
  ttsKey = null,
  isMobile = false,
  colorScheme = "purple",
  onComplete = null
}) => {
  // Internal state for smooth progress animation
  const [localProgress, setLocalProgress] = useState(backgroundTTSProgress);
  const prevProgressRef = useRef(backgroundTTSProgress);
  const isMountedRef = useRef(true);

  // Initialize global state for cross-component communication
  useEffect(() => {
    isMountedRef.current = true;
    
    // Set up global state if it doesn't exist
    if (typeof window !== 'undefined') {
      window.__tts_state = window.__tts_state || {
        isProcessing: isBackgroundTTSProcessing,
        progress: backgroundTTSProgress,
        ttsKey: ttsKey
      };
      
      // Create global event listener for progress updates
      const handleGlobalProgressUpdate = (event) => {
        if (isMountedRef.current && event.detail && typeof event.detail.progress === 'number') {
          setLocalProgress(prev => {
            // Smooth transition logic
            return prev + (event.detail.progress - prev) * 0.3;
          });
        }
      };
      
      // Listen for custom events from other components
      window.addEventListener('tts-progress-update', handleGlobalProgressUpdate);
      
      // Create a global function to update progress if needed
      if (!window.__updateTTSProgress) {
        window.__updateTTSProgress = (newProgress) => {
          if (typeof window.__tts_state !== 'undefined') {
            window.__tts_state.progress = newProgress;
            
            // Broadcast event for components to listen to
            const event = new CustomEvent('tts-progress-update', { 
              detail: { progress: newProgress } 
            });
            window.dispatchEvent(event);
          }
        };
      }
      
      return () => {
        isMountedRef.current = false;
        window.removeEventListener('tts-progress-update', handleGlobalProgressUpdate);
      };
    }
  }, [isBackgroundTTSProcessing, backgroundTTSProgress, ttsKey]);

  // Update local progress when props change (with smoothing)
  useEffect(() => {
    if (isMountedRef.current) {
      const isSignificantChange = Math.abs(backgroundTTSProgress - prevProgressRef.current) > 1;
      
      if (isSignificantChange) {
        // Update with smooth animation
        setLocalProgress(prev => prev + (backgroundTTSProgress - prev) * 0.3);
        prevProgressRef.current = backgroundTTSProgress;
        
        // Call onComplete when reaching 100%
        if (backgroundTTSProgress >= 100 && onComplete) {
          onComplete();
        }
        
        // Also update global state
        if (typeof window !== 'undefined') {
          window.__tts_state = {
            ...window.__tts_state,
            progress: backgroundTTSProgress,
            isProcessing: isBackgroundTTSProcessing
          };
        }
      }
    }
  }, [backgroundTTSProgress, isBackgroundTTSProcessing, onComplete]);

  // Effect to update ttsKey in global state when it changes
  useEffect(() => {
    if (ttsKey && typeof window !== 'undefined') {
      window.__tts_state = {
        ...window.__tts_state,
        ttsKey: ttsKey
      };
    }
  }, [ttsKey]);

  // Hide component if TTS is complete (100%) AND we have a ttsKey
  if (!isBackgroundTTSProcessing && localProgress >= 100 && ttsKey) {
    return null;
  }

  // Calculate safe progress value
  const progress = Math.min(Math.max(0, Math.round(localProgress)), 99.9);
  
  // Color variants
  const colors = {
    purple: {
      primary: '#7d12ff',
      secondary: '#5636f3',
      background: 'rgba(125, 18, 255, 0.1)',
      border: 'rgba(125, 18, 255, 0.2)',
      spinner: 'rgba(125, 18, 255, 0.3)',
      spinnerActive: '#7d12ff',
    },
    blue: {
      primary: '#2193b0',
      secondary: '#6dd5ed',
      background: 'rgba(33, 147, 176, 0.1)',
      border: 'rgba(33, 147, 176, 0.2)',
      spinner: 'rgba(33, 147, 176, 0.3)',
      spinnerActive: '#2193b0',
    }
  };
  
  const color = colors[colorScheme] || colors.purple;

  // Status text based on progress
  let statusText = "Generating your meditation voice...";
  let statusDetail = "Creating your personalized meditation voice";
  
  if (progress >= 95) {
    statusText = "Voice generation complete!";
    statusDetail = "Your meditation voice is ready to use";
  } else if (progress >= 80) {
    statusText = "Almost done...";
    statusDetail = "Finalizing your meditation voice";
  } else if (progress >= 60) {
    statusText = "Processing voice audio...";
    statusDetail = "We're processing your personalized voice";
  } else if (progress >= 40) {
    statusText = "Voice processing...";
    statusDetail = "We're crafting your perfect meditation guide";
  }

  // Main component render
  return (
    <div style={{
      width: '100%',
      maxWidth: '100%',
      margin: '16px 0',
      padding: '16px',
      backgroundColor: 'rgba(0, 0, 0, 0.7)',
      borderRadius: '12px',
      color: 'white',
      boxShadow: '0 4px 20px rgba(0, 0, 0, 0.3)',
      backdropFilter: 'blur(4px)',
      WebkitBackdropFilter: 'blur(4px)',
      border: `1px solid ${color.border}`,
      display: 'flex',
      flexDirection: 'column',
      gap: '12px',
      position: 'relative',
      overflow: 'hidden',
      animation: 'fadeIn 0.3s ease-in-out'
    }}>
      {/* Animated background pulse for better visual feedback */}
      <div style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        background: `linear-gradient(90deg, transparent, ${color.background}, transparent)`,
        backgroundSize: '200% 100%',
        animation: 'pulse-bg 2s infinite',
        opacity: 0.3,
        zIndex: 0
      }}></div>
      
      <div style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'space-between',
        position: 'relative',
        zIndex: 1
      }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
          {/* Spinner animation */}
          <div style={{
            width: '24px',
            height: '24px',
            borderRadius: '50%',
            border: `3px solid ${color.spinner}`,
            borderTopColor: color.spinnerActive,
            animation: 'tts-spin 1s linear infinite'
          }}></div>
          
          {/* Main message */}
          <div style={{ fontWeight: '600', fontSize: isMobile ? '14px' : '16px' }}>
            {statusText}
          </div>
        </div>
        
        {/* Progress percentage */}
        <div style={{ fontWeight: '600' }}>
          {progress}%
        </div>
      </div>
      
      {/* Progress bar */}
      <div style={{ 
        width: '100%', 
        height: '6px', 
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
        borderRadius: '3px',
        overflow: 'hidden',
        position: 'relative',
        zIndex: 1
      }}>
        <div style={{
          height: '100%',
          width: `${progress}%`,
          background: `linear-gradient(90deg, ${color.secondary}, ${color.primary})`,
          borderRadius: '3px',
          transition: 'width 0.3s ease'
        }}></div>
      </div>
      
      {/* Detail message */}
      <p style={{ 
        margin: '0', 
        fontSize: isMobile ? '13px' : '14px', 
        opacity: '0.8',
        lineHeight: '1.4',
        position: 'relative',
        zIndex: 1
      }}>
        {statusDetail}
      </p>
      
      {/* Ready indicator when ttsKey is available */}
      {ttsKey && (
        <div style={{
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          padding: '8px 12px',
          backgroundColor: 'rgba(0, 255, 0, 0.1)',
          borderRadius: '6px',
          border: '1px solid rgba(0, 255, 0, 0.2)',
          marginTop: '4px',
          position: 'relative',
          zIndex: 1
        }}>
          <svg width="16" height="16" viewBox="0 0 24 24" fill="none" stroke="#4CAF50" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
            <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
            <polyline points="22 4 12 14.01 9 11.01"></polyline>
          </svg>
          <span style={{ fontSize: '13px', color: '#4CAF50', fontWeight: '500' }}>
            Voice audio is available for playback
          </span>
        </div>
      )}
      
      {/* Add keyframe animations */}
      <style jsx>{`
        @keyframes tts-spin {
          to { transform: rotate(360deg); }
        }
        @keyframes pulse-bg {
          0%, 100% { background-position: 100% 0%; }
          50% { background-position: 0% 0%; }
        }
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
      `}</style>
    </div>
  );
};

export default TTSProgressIndicator;